import React, { useState, useEffect } from "react";
import "./UserDetails.css";
import Swal from "sweetalert2";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ROLE } from "../../../utils/constant";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { useUserContext } from "../../../context/user.context";
import { createUser } from "../../../utils/services/authService";
import { deleteUser } from "../../../utils/services/authService";

const UserDetails = ({ show, onClose, user, userList, setUserList }) => {
  const [formData, setFormData] = useState({ fullname: "", email: "", password: "", mobile: "", company: "", role: "", isActive: "" });
  const [loading, setLoading] = useState(false)
  const { userState } = useUserContext();
  const token = sessionStorage.getItem('token')

  // setting user details in a state
  useEffect(() => {
    if (user) {
      setFormData({
        fullname: user.fullname || "",
        email: user.email || "",
        password: "",
        mobile: user.mobile || "",
        company: user.company || "",
        role: user.role || "",
        isActive: user.isActive || "",
      });
    }
  }, [user]);

  // Handle input fields change logic
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Password validation logic
  const feedback = [
    "Password must contain at least 8 characters",
    "Password must contain at least one lowercase letter",
    "Password must contain at least one uppercase letter",
    "Password must contain at least one digit",
    "Password must contain at least one special character (!@#$%^&*())",
  ];

  // Handle submit logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.fullname || !formData.email || !formData.mobile || !formData.company || !emailRegex.test(formData.email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required details before submitting",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      return;
    }
    if (formData.password) {
      const passwordCriteria = [/^(?=.{8,})/, /^(?=.*[a-z])/, /^(?=.*[A-Z])/, /^(?=.*\d)/, /^(?=.*[!@#$%^&*()])/,];
      let passwordFeedback = "";
      passwordCriteria.forEach((criteria, index) => {
        if (!criteria.test(formData.password)) {
          passwordFeedback += feedback[index] + "\n";
        }
      });
      if (passwordFeedback !== "") {
        Swal.fire({
          icon: "error",
          title: "Password Validation Error",
          text: passwordFeedback,
          confirmButtonColor: "#100c08",
        });
        setLoading(false);
        return;
      }
    }
    try {
      const userResponse = await createUser(formData, userState);
      if (userResponse.status) {
        let localUserList = [...userList];
        for (let i = 0; i < localUserList.length; i++) {
          if (localUserList[i]._id === userResponse.user._id) {
            localUserList[i] = userResponse.user;
          }
        }
        setUserList([...localUserList])
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Submitted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Something went wrong while creating a user.",
          confirmButtonColor: "#100c08",
        });
      }
    } catch (err) {
      console.log("Error", err);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error submitting data. Please try again.",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  }

  // Delete user logic
  const handleDeleteUser = async (e) => {
    e.preventDefault();
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete the user. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#100c08",
        cancelButtonColor: "#D1D1D1",
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel",
        customClass: {
          container: 'swal-container',
        }
      });
      if (result.isConfirmed) {
        const deleteResponse = await deleteUser(token, user._id);
        if (deleteResponse.status === 200) {
          let localUserList = [...userList];
          localUserList = localUserList.filter(userItem => userItem._id !== user._id);
          setUserList(localUserList)
          Swal.fire({
            title: "Deleted!",
            text: `${user.fullname} have been deleted.`,
            icon: "success",
            confirmButtonColor: "#100c08",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: "Cancelled",
            text: "Selected user are safe :)",
            icon: "error",
            customClass: {
              confirmButton: "cancel-button-class",
            },
          });
        }
        onClose();
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting user.",
        icon: "error",
        confirmButtonColor: "#100c08",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div style={{ zIndex: 13 }}>
      <div className="modal-contents2">
        <form className="update-user-form" onSubmit={handleSubmit}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#3b3535', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px', marginBottom: '15px' }}>
            <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>Update User</h2>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
            </IconButton>
          </Box>
          <TextField required label="Full Name" name="fullname" value={formData.fullname} onChange={handleChange} fullWidth margin="normal" size='small' />
          <TextField required label="Email" name="email" type="email" value={formData.email} onChange={handleChange} disabled fullWidth margin="normal" size='small' />
          <TextField label="Password" name="password" type="password" value={formData.password} onChange={handleChange} fullWidth margin="normal" size='small' />
          <TextField required label="Mobile" name="mobile" value={formData.mobile} onChange={handleChange} fullWidth margin="normal" size='small' />
          <FormControl required fullWidth margin="normal" size='small'>
            <InputLabel id="company-label">Company</InputLabel>
            <Select labelId="company-label" id="company" label="Company" value={formData.company} onChange={(e) => setFormData({ ...formData, company: e.target.value })}>
              <MenuItem value="AMC">All Melbourne Constructions</MenuItem>
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal" size='small'>
            <InputLabel id="roles-label">Roles</InputLabel>
            <Select labelId="roles-label" id="roles" label="Roles" value={formData.role} onChange={(e) => setFormData({ ...formData, roles: e.target.value })}>
              <MenuItem value={ROLE.SUPER_ADMIN}>Super Admin</MenuItem>
              <MenuItem value={ROLE.ADMIN}>Admin</MenuItem>
              <MenuItem value={ROLE.USER}>User</MenuItem>
            </Select>
          </FormControl>
          <div className="checkbox-container" style={{ marginLeft: '5px' }}>
            <FormControlLabel
              control={
                <Checkbox id="isActive" name="isActive" checked={formData.isActive} onChange={handleChange} />
              }
              label="Active"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
            <Button variant="outlined" sx={{ color: '#808080', border: '1px solid #3b3535' }} onClick={handleDeleteUser}>Delete User</Button>
            <Button variant="contained" color="primary" type="submit" disabled={loading} style={{ background: '#3b3535', color: 'c0c0c0' }}>Update User</Button>
          </div>
        </form>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default UserDetails;
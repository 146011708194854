import React, { useEffect, useMemo, useState } from 'react'
import "./RightTable.css";
import { nanoid } from "nanoid";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import { MaterialReactTable } from 'material-react-table';
import { Resizable } from "re-resizable";
import { Select, MenuItem, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPaste, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/helper";
import { ROLE } from '../../utils/constant';
import LoadingSpinner from "../Loader/LoadingSpinner";
import ModalForm from "../PrebuildOneOffModal/PrebuildOneOffModal";
import { projectActionTypes, useProjectContext } from "../../context/project.context";
import { useUserContext } from '../../context/user.context';
import { calculateData, checkIfCalculationExist } from "../../utils/services/calculationService";
import { createRateInputCopy } from "../../utils/services/rateinputService";
import { saveCubitItemMaterial, getCubitIdMaterialData, deleteCubitItemMaterialById } from "../../utils/services/cubititemmaterialService"

function RightTable({ selectedRowData, wallCeilingType, Notes, billRef, combinedString, setCubitItemMaterial, cubitItemMaterial, isCalculationAvailable, prebuildData, oneoffItemData, setOneoffItemData }) {
  const [rowData, setRowData] = useState([{ prebuildOneOffSet: "", primarySecondary: "", costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "", parentId: selectedRowData ? selectedRowData.id : null, combinedString: combinedString, id: nanoid(10), },]);
  const [loading, setLoading] = useState(false);
  const [calculationLoading, setCalculationLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [costCenterNameOptions, setCostCenterNameOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [subGroup1Options, setSubGroup1Options] = useState([]);
  const [subGroup2Options, setSubGroup2Options] = useState([]);
  const [commonNameOptions, setCommonNameOptions] = useState([]);
  const [isRightTableDataChanged, setIsRightTableDataChanged] = useState(false);
  const [copiedRowData, setCopiedRowData] = useState(null);
  const token = sessionStorage.getItem('token')
  const { projectState } = useProjectContext();
  const { projectDispatch } = useProjectContext();
  const { cubitId } = useParams();
  const { userState } = useUserContext();
  const navigate = useNavigate();

  // Prebuild | OneOffSet Dropdown Options
  const dropdownOptions = [
    { label: "Select an option", value: "" },
    { label: "Prebuild", value: "prebuild" },
    { label: "One Off Item", value: "one off item" },
  ];

  // Primary Secondary Dropdown Options
  const primarySecondaryDropdownOptions = [
    { label: "Select an option", value: "" },
    { label: "Primary", value: "primary" },
    { label: "Secondary", value: "secondary" },
    { label: "Measurement", value: "measurement" },
  ];

  // prebuild Dropdown Options functionality
  const handleDropdownChange = (e, index) => {
    const newValue = e.target.value;
    setShowForm(newValue === "one off item");
    setRowData((prevRowData) =>
      prevRowData.map((row, i) => i === index ? { ...row, prebuildOneOffSet: newValue, primarySecondary: newValue === "one off item" ? "" : row.primarySecondary, costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "" } : row)
    );
  };

  // Primary Secondary Dropdown Options functionality
  const handlePrimarySecondaryChange = async (e, index) => {
    try {
      const newValue = e.target.value;
      const prebuildoneoffitemValue = rowData[index]?.prebuildOneOffSet || "";
      let finalResult = [];
      if (prebuildoneoffitemValue === "prebuild") {
        finalResult = prebuildData
          .filter(prebuildItem => prebuildItem.CostCentre1).map(item => item.CostCentre1)
      } else if (prebuildoneoffitemValue === "one off item") {
        finalResult = oneoffItemData
          .filter(prebuildItem => prebuildItem.CostCentre1).map(item => item.CostCentre1)
      }
      const formattedOptions = finalResult.length ? finalResult.map((option) => ({ label: option, value: option })) : [];
      setRowData((prevRowData) =>
        prevRowData.map((row, i) => {
          if (i === index) {
            return { ...row, primarySecondary: newValue, costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "", costCenterNameOptions: formattedOptions, };
          }
          return row;
        })
      );
    } catch (error) {
      console.error("Error fetching cost center options:", error);
    }
  };

  // Job Cost Center Name Dropdown Options functionality
  const handleCostCenterChange = async (e, index) => {
    try {
      const newValue = e.target.value.trim();
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.costCenterName = newValue
        }
      })
      const prebuildoneoffitemValue = updatedRows[index]?.prebuildOneOffSet || "";

      let finalResult = [];
      if (prebuildoneoffitemValue === "prebuild") {
        finalResult = prebuildData
          .filter(prebuildItem => prebuildItem.CostCentre1 === newValue)
          .flatMap(costCentre1Data => costCentre1Data.CostCentre2Groups)
          .map(costCentre2Group => costCentre2Group.CostCentre2);
      } else if (prebuildoneoffitemValue === "one off item") {
        finalResult = oneoffItemData
          .filter(prebuildItem => prebuildItem.CostCentre1 === newValue)
          .flatMap(costCentre1Data => costCentre1Data.CostCentre2Groups)
          .map(costCentre2Group => costCentre2Group.CostCentre2);
      }
      if (finalResult.length) {
        const finalResultOptions = finalResult.map((item) => ({
          label: item,
          value: item,
        }));
        updatedRows = updatedRows.map((row, i) => {
          if (i === index) {
            return { ...row, costCenterName: newValue, costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "", costCenterOptions: finalResultOptions, };
          } else {
            return row;
          }
        });
      } else {
        console.log("Empty or invalid response data received.");
      }

      setRowData(updatedRows);
    } catch (error) {
      console.error("Error fetching data from the second API:", error);
    }
  };

  // Cost Centre Dropdown function
  const handleCostCenterSubChange = async (e, index) => {
    try {
      const newValue = e.target.value.trim();
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.costCenterSub = newValue
        }
      })
      const prebuildoneoffitemValue = updatedRows[index]?.prebuildOneOffSet || "";
      const costCenterNameValue = updatedRows[index]?.costCenterName || "";

      let finalResult = [];
      if (prebuildoneoffitemValue === "prebuild") {
        finalResult = prebuildData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === newValue))
          .flatMap(group => group.SubGroup1Groups.map(subGroup => subGroup.SubGroup1));
      } else if (prebuildoneoffitemValue === "one off item") {
        finalResult = oneoffItemData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === newValue))
          .flatMap(group => group.SubGroup1Groups.map(subGroup => subGroup.SubGroup1));
      }
      if (finalResult.length) {
        const finalResultOptions = finalResult.map((item) => ({
          label: item,
          value: item,
        }));
        updatedRows = updatedRows.map((row, i) => {
          if (i === index) {
            return { ...row, costCenterSub: newValue, subGroup1: "", subGroup2: "", commonName: "", comments: "", subGroup1Options: finalResultOptions, };
          } else {
            return row;
          }
        });
      } else {
        console.log("Empty or invalid response data received.");
      }

      setRowData(updatedRows);
    } catch (error) {
      console.error("Error fetching data from the second API:", error);
    }
  };

  // SubGroup1 Dropdown Option functionality
  const handleSubGroup1Change = async (e, index) => {
    try {
      const newValue = e.target.value;
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.subGroup1 = newValue
        }
      })
      const prebuildoneoffitemValue = updatedRows[index]?.prebuildOneOffSet || "";
      const costCenterNameValue = updatedRows[index]?.costCenterName || "";
      const costCenterSub = updatedRows[index]?.costCenterSub || "";

      let finalResult = [];
      if (prebuildoneoffitemValue === "prebuild") {
        finalResult = prebuildData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === costCenterSub))
          .flatMap(group => group.SubGroup1Groups.filter(group => group.SubGroup1 === newValue))
          .flatMap(group => group.SubGroup2Groups.map(subGroup => subGroup.SubGroup2));
      } else if (prebuildoneoffitemValue === "one off item") {
        finalResult = oneoffItemData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === costCenterSub))
          .flatMap(group => group.SubGroup1Groups.filter(group => group.SubGroup1 === newValue))
          .flatMap(group => group.SubGroup2Groups.map(subGroup => subGroup.SubGroup2));
      }
      if (finalResult.length) {
        const finalResultOptions = finalResult.map((item) => ({
          label: item,
          value: item,
        }));
        updatedRows = updatedRows.map((row, i) => {
          if (i === index) {
            return { ...row, subGroup1: newValue, subGroup2: "", commonName: "", comments: "", subGroup2Options: finalResultOptions, };
          } else {
            return row;
          }
        });
      } else {
        console.log("Empty or invalid response data received.");
      }

      setRowData(updatedRows);
    } catch (error) {
      console.error("Error fetching data from the second API:", error);
    }
  };

  // SubGroup2 Dropdown Option functionality
  const handleSubGroup2Change = async (e, index) => {
    try {
      const newValue = e.target.value;
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.subGroup2 = newValue
        }
      })
      const prebuildoneoffitemValue = updatedRows[index]?.prebuildOneOffSet || "";
      const costCenterNameValue = updatedRows[index]?.costCenterName || "";
      const costCenterSub = updatedRows[index]?.costCenterSub || "";
      const subGroup1 = updatedRows[index]?.subGroup1 || "";

      let finalResult = [];
      if (prebuildoneoffitemValue === "prebuild") {
        finalResult = prebuildData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === costCenterSub))
          .flatMap(group => group.SubGroup1Groups.filter(group => group.SubGroup1 === subGroup1))
          .flatMap(group2 => group2.SubGroup2Groups.filter(group2 => group2.SubGroup2 === newValue)).map(item => item.documents).flat();
      } else if (prebuildoneoffitemValue === "one off item") {
        finalResult = oneoffItemData
          .filter(prebuildItem => prebuildItem.CostCentre1 === costCenterNameValue)
          .flatMap(prebuildItem => prebuildItem.CostCentre2Groups.filter(group => group.CostCentre2 === costCenterSub))
          .flatMap(group => group.SubGroup1Groups.filter(group => group.SubGroup1 === subGroup1))
          .flatMap(group2 => group2.SubGroup2Groups.filter(group2 => group2.SubGroup2 === newValue)).map(item => item.documents).flat();
      }
      if (finalResult.length) {
        const finalResultOptions = finalResult.map((item) => ({
          label: item,
          value: item,
        }));
        updatedRows = updatedRows.map((row, i) => {
          if (i === index) {
            return { ...row, subGroup2: newValue, commonName: "", comments: "", commonNameOptions: finalResultOptions, };
          } else {
            return row;
          }
        });
      } else {
        console.log("Empty or invalid response data received.");
      }

      setRowData(updatedRows);
    } catch (error) {
      console.error("Error fetching data from the second API:", error);
    }
  };

  // Prebuild List Option functionality
  const handleCommonNameChange = async (e, index) => {
    try {
      const newValue = e.target.value;
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.commonName = newValue
        }
      })

      setRowData(updatedRows);
    } catch (error) {
      console.error("Error fetching data from the second API:", error);
    }
  };

  // Comments column functionality
  const handleCommentsChange = (e, index) => {
    try {
      const newValue = e.target.value;
      let updatedRows = [...rowData];
      updatedRows.forEach((row, rowIndex) => {
        if (rowIndex === index) {
          row.comments = newValue;
        }
      });
      setRowData(updatedRows);
    } catch (error) {
      console.error("Error updating comments:", error);
    }
  };

  // Handle for checkbox change
  const handleCheckboxChange = (event, index) => {
    const newData = [...rowData];
    newData[index].selected = event.target.checked;
    setRowData(newData);
  };

  const splitStringByNumbers = (str) => {
    return str.split(/(\d+)/).map(segment => (isNaN(segment) ? segment : parseInt(segment, 10)));
  };

  const compareSegments = (a, b) => {
    const aSegments = splitStringByNumbers(a);
    const bSegments = splitStringByNumbers(b);

    for (let i = 0; i < Math.max(aSegments.length, bSegments.length); i++) {
      if (aSegments[i] === undefined) return -1;
      if (bSegments[i] === undefined) return 1;

      if (aSegments[i] < bSegments[i]) return -1;
      if (aSegments[i] > bSegments[i]) return 1;
    }
    return 0;
  };

  // Define columns
  const columns = useMemo(() => {
    return [
      !isCalculationAvailable && {
        header: '', accessorKey: 'select', enableSorting: false,
        Cell: ({ row }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={row?.original?.selected || false}
                onChange={(e) => handleCheckboxChange(e, row?.index)}
                size="small"
              />
            }
            label=""
          />
        ),
        size: '40',
        muiTableBodyCellProps: {
          sticky: true,
        },
        muiTableHeadCellProps: {
          sticky: true,
        }
      },
      {
        header: 'prebuildOneOffItem', accessorKey: 'prebuildOneOffSet',
        Cell: ({ row }) => (
          <Select
            value={row?.original?.prebuildOneOffSet || ""}
            onChange={!isCalculationAvailable ? (e) => handleDropdownChange(e, row?.index) : undefined}
            fullWidth size="small" displayEmpty sx={{ fontSize: '13px' }}
          >
            {dropdownOptions?.map(option => (
              <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        header: 'Primary Secondary', accessorKey: 'primarySecondary',
        Cell: ({ row }) => {
          if (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") {
            return (
              <Select
                value={row?.original?.primarySecondary || ""}
                onChange={!isCalculationAvailable ? (e) => handlePrimarySecondaryChange(e, row?.index) : undefined}
                fullWidth size="small" displayEmpty sx={{ fontSize: '13px' }}
              >
                {primarySecondaryDropdownOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'CostCenter Name',
        accessorKey: 'costCenterName',
        Cell: ({ row }) => {
          if (
            (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") &&
            (row?.original?.primarySecondary === "primary" || row?.original?.primarySecondary === "secondary" || row?.original?.primarySecondary === "measurement")
          ) {
            const sortedOptions = row?.original?.costCenterNameOptions?.slice().sort((a, b) => {
              return compareSegments(a.label, b.label);
            });

            return (
              <Select
                value={row?.original?.costCenterName || ""}
                onChange={!isCalculationAvailable ? (e) => handleCostCenterChange(e, row?.index) : undefined}
                fullWidth size="small" sx={{ fontSize: '13px' }} displayEmpty placeholder="Select an option"
              >
                <MenuItem disabled value="">
                  Select an option
                </MenuItem>
                {sortedOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'CostCenter Sub',
        accessorKey: 'costCenterSub',
        Cell: ({ row }) => {
          if (
            (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") &&
            (row?.original?.primarySecondary === "primary" || row?.original?.primarySecondary === "secondary" || row?.original?.primarySecondary === "measurement") &&
            row?.original?.costCenterName !== ""
          ) {
            const sortedSubOptions = row?.original?.costCenterOptions?.slice().sort((a, b) => {
              return compareSegments(a.label, b.label);
            });

            return (
              <Select
                value={row?.original?.costCenterSub || ""}
                onChange={!isCalculationAvailable ? (e) => handleCostCenterSubChange(e, row?.index) : undefined}
                fullWidth size="small" sx={{ fontSize: '13px' }} displayEmpty placeholder="Select an option"
              >
                <MenuItem disabled value="">
                  Select an option
                </MenuItem>
                {sortedSubOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'SubGroup 1',
        accessorKey: 'subGroup1',
        Cell: ({ row }) => {
          if (
            (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") &&
            (row?.original?.primarySecondary === "primary" || row?.original?.primarySecondary === "secondary" || row?.original?.primarySecondary === "measurement") &&
            row?.original?.costCenterName !== "" &&
            row?.original?.costCenterSub !== ""
          ) {
            const sortedSubGroup1Options = row?.original?.subGroup1Options?.slice().sort((a, b) => {
              return compareSegments(a.label, b.label);
            });

            return (
              <Select
                value={row?.original?.subGroup1 || ""}
                onChange={!isCalculationAvailable ? (e) => handleSubGroup1Change(e, row?.index) : undefined}
                fullWidth size="small" sx={{ fontSize: '13px' }} displayEmpty placeholder="Select an option"
              >
                <MenuItem disabled value="">
                  Select an option
                </MenuItem>
                {sortedSubGroup1Options?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'SubGroup 2',
        accessorKey: 'subGroup2',
        Cell: ({ row }) => {
          if (
            (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") &&
            (row?.original?.primarySecondary === "primary" || row?.original?.primarySecondary === "secondary" || row?.original?.primarySecondary === "measurement") &&
            row?.original?.costCenterName !== "" &&
            row?.original?.costCenterSub !== "" &&
            row?.original?.subGroup1 !== ""
          ) {
            const sortedSubGroup2Options = row?.original?.subGroup2Options?.slice().sort((a, b) => {
              return compareSegments(a.label, b.label);
            });

            return (
              <Select
                value={row?.original?.subGroup2 || ""}
                onChange={!isCalculationAvailable ? (e) => handleSubGroup2Change(e, row?.index) : undefined}
                fullWidth size="small" sx={{ fontSize: '13px' }} displayEmpty placeholder="Select an option"
              >
                <MenuItem disabled value="">
                  Select an option
                </MenuItem>
                {sortedSubGroup2Options?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'Prebuild(List)',
        accessorKey: 'commonName',
        Cell: ({ row }) => {
          if (
            (row?.original?.prebuildOneOffSet === "prebuild" || row?.original?.prebuildOneOffSet === "one off item") &&
            (row?.original?.primarySecondary === "primary" || row?.original?.primarySecondary === "secondary" || row?.original?.primarySecondary === "measurement") &&
            row?.original?.costCenterName !== "" &&
            row?.original?.costCenterSub !== "" &&
            row?.original?.subGroup1 !== "" &&
            row?.original?.subGroup2 !== ""
          ) {
            const sortedCommonNameOptions = row?.original?.commonNameOptions?.slice().sort((a, b) => {
              return compareSegments(a.label, b.label);
            });

            return (
              <Select
                value={row?.original?.commonName || ""}
                onChange={!isCalculationAvailable ? (e) => handleCommonNameChange(e, row?.index) : undefined}
                fullWidth size="small" sx={{ fontSize: '13px' }} displayEmpty placeholder="Select an option"
              >
                <MenuItem disabled value="">
                  Select an option
                </MenuItem>
                {sortedCommonNameOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'Comments', accessorKey: 'comments',
        Cell: ({ row }) => {
          return (
            <TextField
              value={row?.original?.comments || ""}
              variant="outlined"
              size="small"
              placeholder='Enter comments'
              inputProps={{ style: { fontSize: 14 } }}
              disabled={isCalculationAvailable && userState?.user?.role === ROLE.USER}
              onChange={(e) => handleCommentsChange(e, row?.index)}
            />
          );
        },
      },
      !isCalculationAvailable && {
        header: '', accessorKey: 'copy',
        enableSorting: false,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
            <FontAwesomeIcon icon={faCopy} onClick={() => handleCopyButtonClick(row)} className="copy-icon" />
            <FontAwesomeIcon icon={faPaste} onClick={() => handlePasteButtonClick(row?.index)} className="copy-icon" />
            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteButtonClick(row?.index)} className="copy-icon" />
          </div>
        ),
        size: 150,
      },
    ].filter(Boolean);
  }, [rowData, oneoffItemData]);

  // Set theme for the table
  const mrtTheme = (theme) => ({
    baseBackgroundColor: theme.palette.background.default,
  });

  // Define styles for table header
  const muiTableHeadCellProps = {
    sx: {
      border: '1px solid #ccc',
      fontWeight: 'bold',
      backgroundColor: '#1B1B1B',
      fontFamily: 'Segoe UI',
      color: '#C0C0C0',
      fontSize: '14px',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
    },
  };

  // Define syles for table body
  const muiTableBodyCellProps = {
    sx: {
      fontFamily: 'Segoe UI',
      fontWeight: '500',
      border: '1px solid #ccc',
      cursor: 'pointer',
      fontSize: '12px',
    },
  };

  // Render content for Top bar
  const renderTopToolbarCustomActions = ({ table }) => (
    !isCalculationAvailable ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '5px', marginLeft: 'auto' }}>
        <button className="next-input-button" style={{ minWidth: '70px' }} onClick={handleNextButtonClick}> Next</button>
      </div>
    ) : null
  );

  // Render content for Bottom bar
  const renderBottomToolbarCustomActions = ({ table }) => (
    !isCalculationAvailable ? (
      <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
        <button className="add-row-button" onClick={handleAddButtonClick}> Add New Row</button>
        <button className="copy-external-button" onClick={handleCopyExternalButtonClick}> Copy External </button>
        <button className="paste-external-button" onClick={handlePasteExternalButtonClick}> Paste External</button>
        <button className="delete-button" onClick={handleDeleteRows}>Delete Rows</button>
      </div>
    ) : null
  );

  // To Add New Row
  const handleAddButtonClick = () => {
    setRowData((prevRowData) => [
      ...prevRowData,
      { primarySecondary: "", costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "", id: nanoid(10), parentId: selectedRowData ? selectedRowData.id : null, },
    ]);
    Toast.fire({
      icon: 'success',
      title: 'Row added successfully'
    });
  };

  // Function for Copy Row
  const handleCopyButtonClick = (row) => {
    const copiedRowData = rowData[row?.index];
    setCopiedRowData(copiedRowData);

    const newData = [...rowData];
    newData[row?.index].selected = false;
    setRowData(newData);

    Toast.fire({
      icon: 'success',
      title: 'Selected Row Copied successfully'
    });
  };

  // Function for Copy Rows Externally
  const handleCopyExternalButtonClick = () => {
    const selectedRows = rowData.filter(row => row.selected);
    if (selectedRows.length > 0) {
      localStorage.setItem('selectedRows', JSON.stringify(selectedRows));
      Toast.fire({
        icon: 'success',
        title: 'Selected Row Copied successfully'
      });
      const newData = rowData.map(row => ({ ...row, selected: false }));
      setRowData(newData);
    } else {
      Toast.fire({
        icon: 'error',
        title: 'No Rows Selected'
      });
    }
  };

  // Function for Paste Row
  const handlePasteButtonClick = (index) => {
    const pasteRowIndex = index;
    if (pasteRowIndex !== -1 && copiedRowData) {
      let localRowData = [...rowData]
      localRowData[index] = { ...copiedRowData, id: nanoid(10) }
      setRowData(localRowData)
      Toast.fire({
        icon: 'success',
        title: 'Row pasted successfully'
      });
    }
  };

  // Function for Paste Rows Externally
  const handlePasteExternalButtonClick = () => {
    const storedRows = JSON.parse(localStorage.getItem('selectedRows'));

    if (storedRows && storedRows.length > 0) {
      const updatedData = [...rowData];

      storedRows.forEach(storedRow => {
        const existingRowIndex = updatedData.findIndex(row => row.id === storedRow.id);
        if (existingRowIndex !== -1) {
          updatedData[existingRowIndex] = storedRow;
        } else {
          updatedData.push(storedRow);
        }
      });
      const newData = updatedData.map(row => ({ ...row, selected: false }));
      setRowData(newData);

      Toast.fire({
        icon: 'success',
        title: 'Rows pasted successfully',
      });
    } else {
      Toast.fire({
        icon: 'error',
        title: 'No rows to paste',
      });
    }
  };

  // To Delete a Row
  const handleDeleteButtonClick = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this row. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#100c08",
      cancelButtonColor: "#D1D1D1",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      customClass: {
        cancelButton: "cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let originalRowData = [...rowData];
        let filteredData = originalRowData.filter((item, rowIndex) => index !== rowIndex);
        try {
          if (filteredData.length === 0) {
            let filteredCubitItemMaterial = cubitItemMaterial.filter(item => item.parentId === selectedRowData.id)[0];
            if (filteredCubitItemMaterial?._id) {
              try {
                let filteredCubitItemMaterial = cubitItemMaterial.filter(item => item.parentId === selectedRowData.id)[0]
                let cubitItemMaterialId = filteredCubitItemMaterial?._id;
                setLoading(true)
                await deleteCubitItemMaterialById(token, cubitItemMaterialId)
                setCubitItemMaterial((prevRowData => prevRowData.map((leftTableRow) => leftTableRow._id === cubitItemMaterialId ? { ...leftTableRow, data: [] } : { ...leftTableRow })))
              } catch (err) {
                console.log('err', err)
                Toast.fire({
                  icon: 'error',
                  title: 'Something went wrong while deleting the rows.'
                });
              }
            }
          }
          setRowData([...filteredData]);
          Swal.fire({
            title: "Deleted",
            text: "Your row has been deleted.",
            icon: "success",
            confirmButtonColor: "#100c08",
          });
          setLoading(false)
        } catch (err) {
          console.log(err)
          setLoading(false)
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Your row safe :)",
          icon: "error",
          customClass: {
            confirmButton: "cancel-button-class",
          },
        });
      }
    });
  };

  // To Delete Multiple Rows
  const handleDeleteRows = () => {
    const selectedRows = rowData.filter(row => row.selected);
    if (selectedRows.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete the selected rows. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#100c08',
        cancelButtonColor: '#D1D1D1',
        confirmButtonText: 'Yes, delete them!',
        cancelButtonText: 'No, Cancel!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const remainingRows = rowData.filter(row => !row.selected);
          try {
            if (remainingRows.length === 0) {
              let filteredCubitItemMaterial = cubitItemMaterial.filter(item => item.parentId === selectedRowData.id)[0];
              if (filteredCubitItemMaterial?._id) {
                try {
                  let filteredCubitItemMaterial = cubitItemMaterial.filter(item => item.parentId === selectedRowData.id)[0]
                  let cubitItemMaterialId = filteredCubitItemMaterial?._id;
                  setLoading(true)
                  await deleteCubitItemMaterialById(token, cubitItemMaterialId)
                  setCubitItemMaterial((prevRowData => prevRowData.map((leftTableRow) => leftTableRow._id === cubitItemMaterialId ? { ...leftTableRow, data: [] } : { ...leftTableRow })))
                } catch (err) {
                  console.log('err', err)
                  Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong while deleting the rows.'
                  });
                }
              }
            }
            setRowData([...remainingRows]);
            Swal.fire({
              title: 'Deleted!',
              text: 'Selected rows have been deleted.',
              icon: 'success',
              confirmButtonColor: "#100c08",
            })
            setLoading(false)
          } catch (err) {
            setLoading(false)
          }
        } else {
          Swal.fire({
            title: "Cancelled",
            text: "Your row safe :)",
            icon: "error",
            customClass: {
              confirmButton: "cancel-button-class",
            },
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No rows selected',
        text: 'Please select rows to delete.',
      });
    }
  };

  // Next Button Function
  const handleNextButtonClick = async () => {
    setCalculationLoading(true)
    const isInvalidRow = rowData.some((row) => {
      if (row.prebuildOneOffSet && !row.commonName) {
        return true;
      }
      if (!row.prebuildOneOffSet && !row.comments) {
        return true;
      }

      return false;
    });
    if (isInvalidRow) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill the required prebuild materials for all rows before proceeding...",
        confirmButtonColor: "#100c08",
      });
      setCalculationLoading(false)
    } else {
      const confirmationResult = await Swal.fire({
        icon: "question",
        title: "Are you sure?",
        text: "The current Rate Input Form Values will be considered for estimation.",
        confirmButtonColor: "#100c08",
        showCancelButton: true,
      });
      if (confirmationResult.isConfirmed) {
        const rightTableData = cubitItemMaterial;
        if (rightTableData) {
          const rateInputFormId = projectState.rateInputFormId;
          try {
            setCalculationLoading(true);
            if (!rateInputFormId) {
              Swal.fire({
                icon: "error",
                title: "Error while calculating data",
                text: "Rate Input values are not Available.Please submit the rate input form.",
                confirmButtonColor: "#100c08"
              })
              return;
            }
            if (isRightTableDataChanged) {
              const calculationCheckResponse = await checkIfCalculationExist(token, rateInputFormId);
              //if calculation already exist for the current rate input form, that means that the user has changed the right table but has not changed the rate input form.
              // That is why,we are going to create the copy of rate input form and assign it the the calculation that will take place in the next button click.  
              if (calculationCheckResponse.status) {
                // navigate(`/calculationpage/${rateInputFormId}`);
                const createRateInputCopyResponse = await createRateInputCopy(token, rateInputFormId);
                if (createRateInputCopyResponse.status) {
                  projectDispatch({
                    type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
                    payload: createRateInputCopyResponse.data._id
                  });
                  let calculationReponse = await calculateData(token, cubitId, { rateInputFormId: createRateInputCopyResponse.data._id });
                  if (calculationReponse.status) {
                    navigate(`/calculationpage/${createRateInputCopyResponse.data._id}`);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Something went wrong while performing.",
                      confirmButtonColor: "#100c08"
                    });
                  }
                }
                return;
              } else {
                let calculationReponse = await calculateData(token, cubitId, { rateInputFormId: rateInputFormId });
                if (calculationReponse.status) {
                  navigate(`/calculationpage/${rateInputFormId}`);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Something went wrong while performing.",
                    confirmButtonColor: "#100c08"
                  });
                }
              }
            } else {
              if (rateInputFormId) {
                // If rate input form is related to a calculation then route to the calculation page, else use the current rate input form and cubit id to perform calculation and route to calculation page.
                const calculationCheckResponse = await checkIfCalculationExist(token, rateInputFormId);
                if (calculationCheckResponse.status) {
                  navigate(`/calculationpage/${rateInputFormId}`);
                } else {
                  let calculationReponse = await calculateData(token, cubitId, { rateInputFormId: rateInputFormId });
                  if (calculationReponse.status) {
                    navigate(`/calculationpage/${rateInputFormId}`);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Something went wrong while performing.",
                      confirmButtonColor: "#100c08"
                    });
                  }
                }
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error while calculating data",
                  text: "Rate Input values are not Available",
                  confirmButtonColor: "#100c08"
                })
              }
            }
          } catch (error) {
            console.error("Error performing API call:", error);
            Swal.fire({
              icon: "error",
              title: "Error while calculation Data",
              text: error,
              confirmButtonColor: "#100c08",
            });
          } finally {
            setCalculationLoading(false)
          }
        }
      } else {
        setCalculationLoading(false)
      }
    }
  };

  // To get the right table data from database
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCubitIdMaterialData(token, cubitId)
        const existingData = response || []
        setCubitItemMaterial(existingData)
      } catch (error) {
        console.log('Error fetching data:', error)
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Failed to fetch data'
        // });
      }
    }

    if (cubitId) {
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubitId]);

  // Retrieve Data from DataBase
  useEffect(() => {
    const savedData = cubitItemMaterial
    if (selectedRowData && savedData.length > 0) {
      const matchingData = savedData.find(
        (data) => data.parentId === selectedRowData.id
      );
      if (matchingData) {
        if (matchingData.data.length > 0) {
          setRowData(matchingData.data);
        } else {
          setRowData([{ primarySecondary: "", costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", commonName: "", comments: "", id: nanoid(10), parentId: selectedRowData ? selectedRowData.id : null, }]);
        }
        setCostCenterNameOptions(matchingData.costCenterNameOptions);
        setCostCenterOptions(matchingData.costCenterOptions);
        setSubGroup1Options(matchingData.subGroup1Options);
        setSubGroup2Options(matchingData.subGroup2Options);
        setCommonNameOptions(matchingData.commonNameOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowData]);

  //Save db to database
  useEffect(() => {
    saveData();
  }, [rowData]);

  // To save the data to database
  const saveData = async () => {
    const newData = { cubitId: cubitId, parentId: selectedRowData ? selectedRowData.id : null, wallCeilingType: wallCeilingType, Notes: Notes, billRef: billRef, combinedString: combinedString, data: rowData.filter((row) => row.prebuildOneOffSet || row.comments), costCenterNameOptions: costCenterNameOptions, costCenterOptions: costCenterOptions, subGroup1Options: subGroup1Options, subGroup2Options: subGroup2Options, commonNameOptions: commonNameOptions, };
    if (newData.data.length > 0) {
      try {
        const response = await saveCubitItemMaterial(token, newData);
        let localCubitItemMaterial = [...cubitItemMaterial];
        let objectExists = localCubitItemMaterial.some(obj => obj._id === response._id);
        if (!objectExists) {
          localCubitItemMaterial.push(response);
        } else {
          let index = localCubitItemMaterial.findIndex(obj => obj._id === response._id);
          localCubitItemMaterial[index] = response;
        }
        setCubitItemMaterial(localCubitItemMaterial);
        setIsRightTableDataChanged(response);
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  return (
    <>
      <div id="rightTable">
        <Resizable defaultSize={{ width: '98%', height: '100%', flex: 1 }} minHeight="200" maxHeight="1000vh" enable={{ top: false, bottom: true, left: false, right: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: true }}>
          <MaterialReactTable
            columns={columns}
            data={rowData}
            enablePagination={false}
            enableDensityToggle={false}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableColumnResizing={true}
            mrtTheme={mrtTheme}
            muiTableHeadCellProps={muiTableHeadCellProps}
            muiTableBodyCellProps={muiTableBodyCellProps}
            enableColumnPinning={true}
            initialState={{
              density: 'compact',
              columnPinning: { left: ['select'], right: ['copy'] }
            }}
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
          />
        </Resizable>
      </div>
      {showForm && (
        <div className="overlay">
          <ModalForm
            show={showForm}
            setOneoffItemData={setOneoffItemData}
            onClose={() => { setShowForm(false); }}
            className="modal-form"
            style={{ display: showForm ? "block" : "none" }}
          />
        </div>
      )}
      <div>
        {(loading) && (
          <LoadingSpinner />
        )}
        {(calculationLoading) && (
          <LoadingSpinner loadingText="Please wait while we perform calculation" />
        )}
      </div>
    </>
  )
}

export default RightTable;
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./PrebuildOneOffModal.scss";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { useProjectContext } from '../../context/project.context'
import { insertOneOffItem, existingDataCheckOneoffItem, getOffPbValue, getUniqueOneOffItem, getPageLoadOneOffItem } from "../../utils/services/oneoffitemService";
import { getUniquePrebuildData, getPbValue, createNewPrebuild, existingDataCheckPrebuildData } from "../../utils/services/prebuildService";
import { currencyFormatForNumber } from "../../utils/formatter";

const ModalForm = ({ show, onClose, setOneoffItemData }) => {
  const [formData, setFormData] = useState({ CostCentre1: "", CostCentre2: "", SubGroup1: "", SubGroup2: "", CommonName: "", PB: "", UOM: "", Labour: "", Hours: "", Rate_Material: "", Rate_Wastage: "", Rate_Sundries: "", Rate_Delivery: "", Rate_MarkUp: "", });
  const [loading, setLoading] = useState({ pb: false, CostCentre1: false, CostCentre2: false, SubGroup1: false, SubGroup2: false, CommonName: false, submit: false, newOneOffItem: false, newPrebuildItem: false });
  const [jobCostCentres, setJobCostCentres] = useState([]);
  const [selectedJobCostCentre, setSelectedJobCostCentre] = useState("");
  const [costCentre2Options, setCostCentre2Options] = useState([]);
  const [subGroup1Options, setSubGroup1Options] = useState([]);
  const [subGroup2Options, setSubGroup2Options] = useState([]);
  const [commonNameOptions, setCommonNameOptions] = useState([]);
  const token = sessionStorage.getItem('token');
  const { projectState } = useProjectContext();
  const initialFormData = { CostCentre1: "", CostCentre2: "", SubGroup1: "", SubGroup2: "", CommonName: "", UOM: "", Labour: "", Hours: "", Rate_Material: "", Rate_Wastage: "", Rate_Sundries: "", Rate_Delivery: "", Rate_MarkUp: "", };
  let numericalField = ["Labour", "Rate_Material", "Hours", "Rate_Wastage", "Rate_Sundries", "Rate_Delivery", "Rate_MarkUp"]

  // To get the PB value
  useEffect(() => {
    const body = document.querySelector("body");
    const fetchPBNumber = async () => {
      try {
        setLoading({ ...loading, pb: true })
        let pBNumberResponse;
        if (formData.selectedOption === "prebuild") {
          const pbValueResponse = await getPbValue(token);
          pBNumberResponse = pbValueResponse.pbValue;
        } else if (formData.selectedOption === "one off item") {
          const projectId = projectState.currentProjectId
          const ofnValueResponse = await getOffPbValue(token, projectId);
          pBNumberResponse = ofnValueResponse.pbValue;
        } else {
          return;
        }
        if (pBNumberResponse) {
          const nextPBNumber = incrementPBNumber(pBNumberResponse);
          setFormData((prevFormData) => ({
            ...prevFormData,
            PB: nextPBNumber,
          }));
        }
      } catch (error) {
        console.error("Error fetching latest PB:", error);
      } finally {
        setLoading({ ...loading, pb: false })
      }
    };
    if (show && formData.selectedOption) {
      body.style.overflow = "hidden";
      fetchPBNumber();
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, formData.selectedOption]);

  // To get cost centre1 values
  useEffect(() => {
    const fetchJobCostCentres = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, CostCentre1: true }));
        let pbUrl = `/get-unique-prebuild?unique=CostCentre1&cubitId=${projectState.currentCubitId}&projectId=${projectState.currentProjectId}`;
        const uniquePrebuildResponse = await getUniquePrebuildData(token, pbUrl);
        const prebuildData = uniquePrebuildResponse.uniqueData;
        const projectId = projectState.currentProjectId;
        const oneOffUrl = `/unique-oneoff/${projectId}?unique=CostCentre1`;
        const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
        const oneOffData = uniqueOneOffItemResponse.uniqueData;
        const uniqueData = new Set([...prebuildData, ...oneOffData])
        const finalResult = [...uniqueData]
        setJobCostCentres(finalResult);
      } catch (error) {
        console.error("Error fetching job cost centres:", error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, CostCentre1: false }));
      }
    };
    if (projectState.currentProjectId && token) {
      fetchJobCostCentres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectState.currentProjectId, token]);

  // To get Costcentre2 values
  useEffect(() => {
    if (loading.pb || !formData.CostCentre1) {
      return;
    }
    const fetchCostCentre2Options = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, CostCentre2: true }));
        let pbUrl = `/get-unique-prebuild?unique=CostCentre2&CostCentre1=${formData.CostCentre1}&cubitId=${projectState.currentCubitId}&projectId=${projectState.currentProjectId}`;
        const prebuildResponse = await getUniquePrebuildData(token, pbUrl);
        const prebuildData = prebuildResponse.uniqueData;
        const projectId = projectState.currentProjectId;
        const oneOffUrl = `/unique-oneoff/${projectId}?unique=CostCentre2&CostCentre1=${formData.CostCentre1}`;
        const oneOffResponse = await getUniqueOneOffItem(token, oneOffUrl);
        const oneOffData = oneOffResponse.uniqueData;
        const uniqueData = new Set([...prebuildData, ...oneOffData])
        const finalResult = [...uniqueData]
        setCostCentre2Options(finalResult);
      } catch (error) {
        console.error("Error fetching CostCentre2 options:", error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, CostCentre2: false }));
      }
    };
    if (formData.selectedOption && formData.CostCentre1 && token && projectState.currentProjectId) {
      fetchCostCentre2Options();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.CostCentre1, formData.selectedOption, token, projectState.currentProjectId, loading.pb]);

  // To get Sub Group1 values
  useEffect(() => {
    if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2) {
      return;
    }
    const fetchSubGroup1Options = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, SubGroup1: true }));
        let pbUrl = `/get-unique-prebuild?unique=SubGroup1&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&cubitId=${projectState.currentCubitId}&projectId=${projectState.currentProjectId}`;
        const uniquePrebuildResponse = await getUniquePrebuildData(token, pbUrl);
        const prebuildData = uniquePrebuildResponse.uniqueData;
        const projectId = projectState.currentProjectId;
        const oneOffUrl = `/unique-oneoff/${projectId}?unique=SubGroup1&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}`;
        const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
        const oneOffData = uniqueOneOffItemResponse.uniqueData;
        const uniqueData = new Set([...prebuildData, ...oneOffData, "Oneoff Item"])
        const finalResult = [...uniqueData]
        setSubGroup1Options(finalResult);
      } catch (error) {
        console.error("Error fetching SubGroup1 options:", error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, SubGroup1: false }));
      }
    };
    if (formData.CostCentre1 && formData.CostCentre2 && token && projectState.currentProjectId) {
      fetchSubGroup1Options();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.CostCentre1, formData.CostCentre2, token, projectState.currentProjectId]);

  // To get Sub Group2 values
  useEffect(() => {
    if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2 || !formData.SubGroup1) {
      return;
    }
    const fetchSubGroup2Options = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, SubGroup2: true }));
        let pbUrl = `/get-unique-prebuild?unique=SubGroup2&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}&cubitId=${projectState.currentCubitId}&projectId=${projectState.currentProjectId}`;
        const uniquePrebuildResponse = await getUniquePrebuildData(token, pbUrl);
        const prebuildData = uniquePrebuildResponse.uniqueData;
        const projectId = projectState.currentProjectId;
        const oneOffUrl = `/unique-oneoff/${projectId}?unique=SubGroup2&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}`;
        const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
        const oneOffData = uniqueOneOffItemResponse.uniqueData;
        const uniqueData = new Set([...prebuildData, ...oneOffData, "Oneoff Item"])
        const finalResult = [...uniqueData]
        setSubGroup2Options(finalResult);
      } catch (error) {
        console.error("Error fetching SubGroup2 options:", error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, SubGroup2: false }));
      }
    };
    if (formData.CostCentre1 && formData.CostCentre2 && formData.SubGroup1 && token && projectState.currentProjectId) {
      fetchSubGroup2Options();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.CostCentre1, formData.CostCentre2, formData.SubGroup1, token, projectState.currentProjectId]);

  //  To get Common Name values
  useEffect(() => {
    if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2 || !formData.SubGroup1 || !formData.SubGroup2) {
      return;
    }

    const fetchCommonNameOptions = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, CommonName: true }));

        let pbUrl = `/get-unique-prebuild?unique=CommonName&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}&SubGroup2=${formData.SubGroup2}&cubitId=${projectState.currentCubitId}&projectId=${projectState.currentProjectId}`;
        const uniquePrebuildResponse = await getUniquePrebuildData(token, pbUrl);
        const prebuildData = uniquePrebuildResponse.uniqueData;

        const projectId = projectState.currentProjectId;
        const oneOffUrl = `/unique-oneoff/${projectId}?unique=CommonName&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}&SubGroup2=${formData.SubGroup2}`;
        const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
        const oneOffData = uniqueOneOffItemResponse.uniqueData;

        const uniqueData = new Set([...prebuildData, ...oneOffData, "Oneoff Item"])
        const finalResult = [...uniqueData]

        setCommonNameOptions(finalResult);
      } catch (error) {
        console.error("Error fetching Common Name options:", error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, CommonName: false }));
      }
    };

    if (formData.CostCentre1 && formData.CostCentre2 && formData.SubGroup1 && formData.SubGroup2 && token && projectState.currentProjectId) {
      fetchCommonNameOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.CostCentre1, formData.CostCentre2, formData.SubGroup1, formData.SubGroup2, formData.selectedOption, token, projectState.currentProjectId]);

  // Function for increment PB value
  function incrementPBNumber(pbNumber) {
    const lastNumber = parseInt(pbNumber.substring(3), 10);
    const nextNumber = lastNumber + 1;
    if (formData.selectedOption === "prebuild") {
      return `PBN${nextNumber}`;
    } else if (formData.selectedOption === "one off item") {
      return `OFN${nextNumber}`;
    }
  }

  // Handle input chnage for input fields
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    let percentageValues = ["Rate_Wastage", "Rate_MarkUp"];
    let currencyValues = ["simProPriceUpload", "Rate_Sundries", "Rate_Delivery", "Labour", "Rate_Material"]
    if (numericalField.includes(name)) {
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        if (percentageValues.includes(name)) {
          const updatedValue = value.slice(0, -1);
          let finalValue = updatedValue;
          finalValue = updatedValue.endsWith("%") ? updatedValue : updatedValue + "%";
          if (!updatedValue) {
            finalValue = ""
          }
          console.log('filenale', finalValue);
          setFormData({ ...formData, [name]: finalValue });
          return;
        }
      }


      const cleanedValue = value.replace(/[$%,]/g, '');
      if (isNaN(cleanedValue)) {
        return;
      }
      let updatedValue = cleanedValue;

      if (percentageValues.includes(name)) {
        if (cleanedValue && !cleanedValue.includes('%')) {
          updatedValue = cleanedValue + '%';
        } else {
          updatedValue = 0 + "%";
        }
      } else if (currencyValues.includes(name)) {
        if (cleanedValue && !cleanedValue.includes('$')) {
          updatedValue = currencyFormatForNumber(cleanedValue)
        }
      } else {
        updatedValue = cleanedValue
      }
      setFormData({ ...formData, [name]: updatedValue });
    } else {
      setFormData({ ...formData, [name]: value, });
    }
  };

  if (!show) {
    return null;
  }

  // Reset form
  const handleReset = () => {
    setFormData(initialFormData);
  };

  // Submit the form function
  const handleSubmit = async (e) => {
    setLoading({ ...loading, submit: true })
    e.preventDefault();
    if (!formData.selectedOption) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select a Option in Dropdown",
        confirmButtonColor: "#100c08",
      });
      setLoading({ ...loading, submit: false })
      return;
    }
    const requiredFields = ['CostCentre1', 'CostCentre2', 'SubGroup1', 'SubGroup2', 'CommonName', 'Labour', 'Rate_Material', 'PB', 'Hours'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    if (missingFields.length > 0) {
      const errorMessage = missingFields.map(field => `Please enter ${field}`).join(', ');
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#100c08",
      });
      setLoading({ ...loading, submit: false })
      return;
    }
    const hoursValue = parseFloat(formData.Hours.replace(/[$%,]/g, ""));
    if (hoursValue <= 0) {
      Swal.fire({
        title: "Error",
        text: "Hours should be greater than 0.",
        icon: "error",
        confirmButtonColor: "#100c08",
        customClass: {
          container: 'swal-container',
        }
      });
      setLoading(false);
      return;
    }
    try {
      const projectId = projectState.currentProjectId;
      const newData = {
        CostCentre1: formData.CostCentre1.trim(),
        CostCentre2: formData.CostCentre2.trim(),
        SubGroup1: formData.SubGroup1.trim(),
        SubGroup2: formData.SubGroup2.trim(),
        CommonName: formData.CommonName.trim()
      }
      let apiResponse;
      if (formData.selectedOption === "prebuild") {
        apiResponse = await existingDataCheckPrebuildData(token, newData);
      } else if (formData.selectedOption === "one off item") {
        apiResponse = await existingDataCheckOneoffItem(token, projectId, newData);
      }
      if (apiResponse.exists) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This Data already exists. Please enter a different one.",
          confirmButtonColor: "#100c08",
        });
        setLoading({ ...loading, submit: false })
        return;
      }
    } catch (error) {
      console.error("Error checking CommonName:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error checking Common Name. Please try again.",
        confirmButtonColor: "#100c08",
      });
      setLoading({ ...loading, submit: false })
      return;
    }
    try {
      setLoading({ ...loading, submit: true })
      const projectId = projectState.currentProjectId;
      let formattedFormData = { ...formData };
      for (let key in formattedFormData) {
        if (typeof formattedFormData[key] === "string" && (formattedFormData[key].startsWith("$") || formattedFormData[key].endsWith("%"))) {
          formattedFormData[key] = formattedFormData[key].replace(/[$%,]/g, "");
        }
      }

      const oneOffItems = { ...formattedFormData, projectId };
      if (formData.selectedOption === "prebuild") {
        //To refresh the dropdown data, if in future the prebuild creation is enabled for this page, handle the logic same as one off itme given below:
        let createNewPrebuildResponse = await createNewPrebuild(token, oneOffItems);
      } else if (formData.selectedOption === "one off item") {
        let createNewOneOffResponse = await insertOneOffItem(token, oneOffItems);
        if (createNewOneOffResponse.message == "Data saved successfully") {
          setLoading({ ...loading, pb: true })
          const oneoffItemData = await getPageLoadOneOffItem(projectState?.currentProjectId, projectState.currentCubitId);
          setOneoffItemData(oneoffItemData)
          setLoading({ ...loading, pb: false })
        }
      }
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      onClose();
      setLoading({ ...loading, submit: false })
    } catch (err) {
      console.log("Error", err);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error submitting data. Please try again.",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading({ ...loading, submit: false })
    }
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-form">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 style={{ marginTop: '-30px', textAlign: 'left' }}>Create Prebuild/OneoffItem</h1><br />
            <span className="close" onClick={onClose}>
              &times;
            </span>
          </div>
          <form onSubmit={handleSubmit} style={{ padding: '0px' }}>
            <div>
              <select name="selectedOption" value={formData.selectedOption} style={{ width: '240px' }} onChange={(e) => setFormData({ ...formData, selectedOption: e.target.value })}>
                <option value="">Select an option</option>
                <option value="prebuild" disabled>New Prebuild Creation</option>
                <option value="one off item">One Off Item for Job</option>
              </select>
            </div><br />
            <div className="form-divs">
              <div className="subDiv">
                <label style={{ color: !formData.CostCentre1 && "red" }}>Job Cost Centre Name
                  <input type="text" list="jobCostCentres" placeholder="Select or Enter Job Cost Centre" value={selectedJobCostCentre} onChange={(e) => setSelectedJobCostCentre(e.target.value)} onBlur={(e) => setFormData({ ...formData, CostCentre1: e.target.value })} />
                  <datalist id="jobCostCentres">
                    {jobCostCentres.map((costCentre, index) => (
                      <option key={index} value={costCentre}>
                        {costCentre}
                      </option>
                    ))}
                  </datalist>
                </label>
                <label style={{ color: !formData.SubGroup1 && "red" }}>SubGroup1
                  <input type="text" list="subGroup1Options" placeholder="Select or Enter SubGroup 1" value={formData.SubGroup1} onChange={(e) => setFormData({ ...formData, SubGroup1: e.target.value })} onBlur={(e) => setFormData({ ...formData, SubGroup1: e.target.value })} />
                  <datalist id="subGroup1Options">
                    {subGroup1Options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </datalist>
                </label>

              </div>
              <div className="subDiv">
                <label style={{ color: !formData.CostCentre2 && "red" }}>Cost Centre
                  <input type="text" list="costCentre2Options" placeholder="Select or Enter Cost Centre " value={formData.CostCentre2} onChange={(e) => setFormData({ ...formData, CostCentre2: e.target.value })} onBlur={(e) => setFormData({ ...formData, CostCentre2: e.target.value })} />
                  <datalist id="costCentre2Options">
                    {costCentre2Options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </datalist>
                </label>
                <label style={{ color: !formData.SubGroup2 && "red" }}>SubGroup2
                  <input type="text" list="subGroup2Options" placeholder="Select or Enter SubGroup 2" value={formData.SubGroup2} onChange={(e) => setFormData({ ...formData, SubGroup2: e.target.value })} onBlur={(e) => setFormData({ ...formData, SubGroup2: e.target.value })} />
                  <datalist id="subGroup2Options">
                    {subGroup2Options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </datalist>
                </label>
              </div>
              <div className="subDiv">
                <label style={{ color: !formData.CommonName && "red" }}>Prebuild(list)
                  <input type="text" list="commonNameOptions" placeholder="Select or Enter Common Name" value={formData.CommonName} onChange={(e) => setFormData({ ...formData, CommonName: e.target.value })} onBlur={(e) => setFormData({ ...formData, CommonName: e.target.value })} />
                  <datalist id="commonNameOptions">
                    {commonNameOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </datalist>
                </label>
                <label>PB No.
                  <input type="text" name="PB" placeholder="PB No." value={formData.PB} onChange={handleInputChange} readOnly />
                </label>
              </div>
              <div className="subDiv">
                <label>U.O.M
                  <input type="text" name="UOM" placeholder="Enter U.O.M" value={formData.UOM} onChange={handleInputChange} />
                </label>
                <label style={{ color: !formData.Labour && "red" }}>Rate Labour
                  <input type="text" name="Labour" placeholder="Enter Rate Labour" value={formData.Labour} onChange={handleInputChange} />
                </label>
                <label style={{ color: !formData.Hours && "red" }}>Hours
                  <input type="text" name="Hours" placeholder="Enter Hours" value={formData.Hours} onChange={handleInputChange} />
                </label>
              </div>
              <div className="subDiv">
                <label style={{ color: !formData.Rate_Material && "red" }}>Rate Material
                  <input type="text" name="Rate_Material" placeholder="Enter Rate Material" value={formData.Rate_Material} onChange={handleInputChange} />
                </label>
                <label>Rate Wastage
                  <input type="text" name="Rate_Wastage" placeholder="Enter Rate Wastage" value={formData.Rate_Wastage} onChange={handleInputChange} />
                </label>
              </div>
              <div className="subDiv">
                <label>Rate Sundries
                  <input type="text" name="Rate_Sundries" placeholder="Enter Rate Sundries" value={formData.Rate_Sundries} onChange={handleInputChange} />
                </label>
                <label>Rate Delivery
                  <input type="text" name="Rate_Delivery" placeholder="Enter Rate Delivery" value={formData.Rate_Delivery} onChange={handleInputChange} />
                </label>
                <label>Rate MarkUp
                  <input type="text" name="Rate_MarkUp" placeholder="Enter Rate MarkUp" value={formData.Rate_MarkUp} onChange={handleInputChange} />
                </label>
              </div>
            </div>
            <div>
              <button type="button" onClick={handleReset} className="modal-form-btn">Reset</button>
              <button type="submit" className="modal-form-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div>
        {(loading.pb || loading.CostCentre1 || loading.CostCentre2 || loading.SubGroup1 || loading.SubGroup2 || loading.CommonName || loading.submit) ?
          <LoadingSpinner />
          : null}
      </div>
    </div>
  );
};

export default ModalForm;
import axios from 'axios';
let cancelToken;

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Post Calculation Data
export const calculateData = (token, cubitId, rateInputFormId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      const response = await axios.post(`${backEndUrl}/api/service/calculation/insert-calculatedata/${cubitId}`, rateInputFormId, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// Check if calculation data exists for Rate input form
export const checkIfCalculationExist = (token, rateInputId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      let response = await axios.get(`${backEndUrl}/api/service/calculation/check-if-calculation-exist/${rateInputId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// Check if calculation is based on current max prebuild
export const checkIfCalculationMaxPrebuild = (token, rateInputId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      let response = await axios.get(`${backEndUrl}/api/service/calculation/check-if-calculation-max-prebuild/${rateInputId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}


// Update calculation comment
export const updateCalculationComment = (token, data) => {

  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axios.CancelToken.source();

  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.patch(`${backEndUrl}/api/service/calculation/calculation-comment`, data, {
        headers: header,
        cancelToken: cancelToken.token
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}
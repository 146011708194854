import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Create Cubit File
export const createCubitData = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      };
      let response = await axios.post(`${backEndUrl}/api/service/cubit/insert-cubit`, formData, { headers });
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// To get data for cubit version for the project table UI
export const fetchCubitDataForCubitVersion = (token, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/cubit/cubit-data-for-cubit-version/${cubitId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Delete Cubit
export const deleteCubit = (token, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.delete(`${backEndUrl}/api/service/cubit/delete-cubit/${cubitId}`, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To get the all cubit Data for projects
export const getCubitDataForProject = (token, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/cubit/cubit-data/${projectId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To get the single cubit Data for projects
export const getCubitSingleDataForProject = (token, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/cubit/cubit-single-data/${cubitId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Create New Cubit Version
export const createNewCubitVersion = (token, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${backEndUrl}/api/service/cubit/create-new-cubit`, {cubitId}, { headers });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}
import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// Export Excel Prebuild Data
export const prebuildDataExport = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${backEndUrl}/api/service/exportexcel/prebuildDataExport`, {}, {
        headers: header,
        responseType: 'blob'
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// Export Excel OneoffItem Data
export const oneoffitemDataExport = (token, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${backEndUrl}/api/service/exportexcel/oneoffitemDataExport/${projectId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// Export Excel Calculation Data
export const finalCalculationExport = (token, rateInputFormId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const response = await axios.post(`${backEndUrl}/api/service/exportexcel/finalCalculationExport/${rateInputFormId}`, {}, {
        responseType: 'blob',
        headers: headers,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
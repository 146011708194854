import axios from 'axios';

const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/user`;

// To login Users
export const userlogin = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${backEndUrl}/login`, data,{headers});
      return resolve(response);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};

//  To Create User
export const createUser = (formData, userState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        'Content-Type': 'application/json',
        'x-Auth-token': userState.token
      };
      let response = await axios.put(`${backEndUrl}/createuser`, formData, { headers });
      return resolve(response.data);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};

//  To Delete User Details
export const deleteUser = (token, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.delete(`${backEndUrl}/delete-user/${userId}`, {
        headers: header,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

//  To Get User Details
export const getUserdetails = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/logindetails`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Send OTP for Email for Forgot password
export const handleForgotPassword = async (email) => {
  try {
    const header = {
      'Content-Type': 'application/json', 
    };

    const response = await axios.post(`${backEndUrl}/forgot-password`, email, {
      headers: header
    });

    return response; 
  } catch (error) {
    throw error; 
  }
};

// To Reset the Password for Forgot password
export const resetPasswordForUser = async (email, userData) => {
  try {
    const header = {
      'Content-Type': 'application/json',
    };
    const response = await axios.post(`${backEndUrl}/reset-password`, { email, userData }, {
      headers: header,
    });
    return response.data; 
    
  } catch (error) {
    console.error('Error:', error);
    throw error; 
  }
};
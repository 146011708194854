import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To get All Sites List
export const getAllSitesList = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${backEndUrl}/api/simpro/sites/get-all-sites-list`);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
}

// To Create New Site
export const createSite = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${backEndUrl}/api/simpro/sites/create-site`, data);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
}

// To Update Site(Assign site to customer)
export const updateSite = (siteId, customerId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = { siteId, customerId };
            let response = await axios.patch(`${backEndUrl}/api/simpro/sites/update-site`, data);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
}
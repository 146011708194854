// Currency logic for strings
export const currencyFormatForString = (value) => {
  if (typeof value !== 'string' || !value.trim()) {
    return value;
  }
  const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
  if (isNaN(numericValue)) {
    return value;
  }
  return numericValue.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
}

// Current format logic for numbers
export const currencyFormatForNumber = (inputValue, currencySymbol = '$') => {
  if (typeof inputValue !== 'string') {
    inputValue = inputValue.toString();
  }
  const cleanedValue = inputValue?.replace(/[^0-9.]/g, '');
  if (!cleanedValue || isNaN(cleanedValue)) {
    return '';
  }
  const [integerPart, fractionalPart] = cleanedValue.split('.');

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let formattedValue = currencySymbol + formattedInteger;
  if (fractionalPart !== undefined) {
    formattedValue += '.' + fractionalPart.slice(0, 3);
  }
  return formattedValue;
};


export const percentageFormatter = (input) => {

  if (typeof input !== 'string') {
    input = input.toString();
  }

  const sanitizedInput = input.replace(/[^0-9.]/g, '');
  const num = parseFloat(sanitizedInput);

  if (isNaN(num)) {
    return '';
  }

  const decimalIndex = sanitizedInput.indexOf('.');
  let formattedValue;

  if (decimalIndex === -1) {
    formattedValue = sanitizedInput.toString();
  } else {
    const decimalPart = sanitizedInput.slice(decimalIndex + 1);
    if (decimalPart.length > 2) {
      formattedValue = (Math.round(sanitizedInput * 100) / 100).toString();
    } else {
      formattedValue = sanitizedInput.toString();
    }
  }

  return formattedValue + '%';
};
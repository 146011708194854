import { createContext, useContext, useReducer } from 'react';

const initialState = {
    projects: [],
    filesInProject:[],
    selectedProject:"",
    projectId: "",
    currentCubitId: "",
    currentProjectId: "",
    isFormSubmitted: false,
    rateInputFormId : "",
};

export const projectActionTypes = {
    UPDATE_PROJECTS: 'UPDATE_PROJECTS',
    UPDATE_FILE_IN_PROJECT: 'UPDATE_FILE_IN_PROJECT',
    UPDATE_SELECTED_PROJECT: 'UPDATE_SELECTED_PROJECT',
    UPDATE_PROJECTID: "UPDATE_PROJECTID",
    UPDATE_CURRENT_PROJECTID: "UPDATE_CURRENT_PROJECTID",
    UPDATE_CUBITID: "UPDATE_CUBITID",
    UPDATE_ISFORM_SUBMITTED: "UPDATE_ISFORM_SUBMITTED",
    SUBMIT_FORM: 'SUBMIT_FORM',
    UPDATE_RATE_INPUT_FORMID: "UPDATE_RATE_INPUT_FORMID"
};

// Define reducer function to update state based on actions
const userReducer = (state, action) => {
    switch (action.type) {
        case projectActionTypes.UPDATE_PROJECTS:
            return { ...state, projects: action.payload };
        case projectActionTypes.UPDATE_FILE_IN_PROJECT:
            return { ...state, filesInProject: action.payload };
        case projectActionTypes.UPDATE_SELECTED_PROJECT:
            return {...state, selectedProject: action.payload };
        case projectActionTypes.UPDATE_PROJECTID:
            return { ...state, projectId: action.payload };
        case projectActionTypes.UPDATE_CUBITID:
            return {...state, currentCubitId: action.payload };
        case projectActionTypes.UPDATE_CURRENT_PROJECTID:
            return { ...state, currentProjectId: action.payload };
        case projectActionTypes.UPDATE_ISFORM_SUBMITTED:
            return {...state, isFormSubmitted: action.payload };
        case projectActionTypes.SUBMIT_FORM:
            return { ...state, isFormSubmitted: true };
        case projectActionTypes.UPDATE_RATE_INPUT_FORMID:
            return { ...state, rateInputFormId: action.payload };
        default:
            return state;
    }
};

// Create user context
const ProjectContext = createContext();

// User provider component
export const ProjectProvider = ({ children }) => {
    const [projectState, projectDispatch] = useReducer(userReducer, initialState);
    return (
        <ProjectContext.Provider value={{ projectState, projectDispatch }}>
            {children}
        </ProjectContext.Provider>
    );
};

// Custom hook to consume user context
export const useProjectContext = () => useContext(ProjectContext);
import React from "react";
import './spinner.css'

export default function LoadingSpinner({ loadingText }) {
  return (
    <div className="outer-container">
      <div className="spinner-container">
        <span className="loading"></span>
        {loadingText ? <> <h1 >{loadingText}</h1></> : <></>}
      </div>
    </div>

  );
}
import React from "react";
import "./Navbar.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Swal from "sweetalert2";
import logo from "../../assets/logo3.png";
import { ROLE } from "../../utils/constant";
import { useUserContext, userActionTypes } from "../../context/user.context";

function Navbar() {
  const { userState,userDispatch } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const fullName = userState?.user?.fullname;

  // Fuction to remove user details from session storage when user click logout
  const handleLogout = () => {
    sessionStorage.setItem("fullname", "");
    sessionStorage.setItem("email", "");
    sessionStorage.setItem("mobile", "");
    sessionStorage.setItem("clientId", "");
    sessionStorage.setItem("role", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("_id", "");

    userDispatch({
      type: userActionTypes.UPDATE_USER,
      payload: null,
    });
    userDispatch({
      type: userActionTypes.UPDATE_TOKEN,
      payload: "",
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Logout Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    navigate("/");
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo-container">
          <Link to="/projects" className="navbar-logo-link">
            <img src={logo} alt="Logo" className="nav-logo" style={{ width: "200px" }}/>
          </Link>
        </div>
        <ul className="navbar-menu">
          {userState?.user?.role === ROLE.ADMIN || userState?.user?.role === ROLE.SUPER_ADMIN ? (
            <>
              <li>
                <Link to="/users" className={`navbar-link ${location.pathname === "/users" ? "navbar-active-link" : ""}`}>Users List</Link>
              </li>
            </>
          ) : (
            <></>
          )}
          <li>
            <Link to="/projects" className={`navbar-link ${location.pathname === "/projects" ? "navbar-active-link" : ""}`}>Projects</Link>
          </li>
          {userState?.user?.role === ROLE.ADMIN || userState?.user?.role === ROLE.SUPER_ADMIN ? (
            <>
              <li>
                <Link to="/prebuild-data" className={`navbar-link ${ location.pathname === "/prebuild-data" ? "navbar-active-link": ""}`}>Prebuild Data</Link>
              </li>
            </>
          ) : (
            <></>
          )}
          <li>
            <Link className="navbar-link">
              <FontAwesomeIcon icon={faUser} className="login-icon" />
                <span className="user-fullname">{fullName}</span>
            </Link>
          </li>
          {userState?.user?.email ? (
            <>
              <button onClick={handleLogout} style={{ backgroundColor: "white", color: "black", border: "1px solid black", borderRadius: "5px", cursor: "pointer", padding: "1px 1px 0px 1px",}}>
                <ExitToAppIcon />
              </button>
            </>
          ) : (
            <></>
          )}
        </ul>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
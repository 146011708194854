import React, { useState } from "react";
import './SignIn.css'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import promanagerLogo from "../../assets/logo4.png";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { useUserContext, userActionTypes } from "../../context/user.context";
import { userlogin } from "../../utils/services/authService";

function SIgnIn() {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDispatch } = useUserContext();
  const navigate = useNavigate();

  // Validation for password
  const feedback = [
    "Password must contain at least 8 characters",
    "Password must contain at least one lowercase letter",
    "Password must contain at least one uppercase letter",
    "Password must contain at least one digit",
    "Password must contain at least one special character (!@#$%^&*())",
  ];
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
    return passwordRegex.test(password);
  };

  // Validation for email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Validation for login data
  const validateLoginData = (data) => {
    if (!validateEmail(data.email)) {
      return false;
    }
    if (!validatePassword(data.password)) {
      return false;
    }
    return true;
  };

  // To view password 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle Input change
  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.id]: e.target.value });
  };

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const passwordCriteria = [ /^(?=.{8,})/, /^(?=.*[a-z])/, /^(?=.*[A-Z])/, /^(?=.*\d)/, /^(?=.*[!@#$%^&*()])/,];
    let passwordFeedback = "";
    passwordCriteria.forEach((criteria, index) => {
      if (!criteria.test(loginData.password)) {
        passwordFeedback += feedback[index] + "\n";
      }
    });
    if (passwordFeedback !== "") {
      Swal.fire({
        icon: "error",
        title: "Password Validation Error",
        text: passwordFeedback,
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      return;
    }
    try {
      let isValidData = validateLoginData(loginData);
      if (isValidData) {
        const loginResponse = await userlogin(loginData);
        if (loginResponse.status === 200) {
          let loginResponseData = loginResponse.data;
          userDispatch({
            type: userActionTypes.UPDATE_USER,
            payload: loginResponseData.user,
          });
          userDispatch({
            type: userActionTypes.UPDATE_TOKEN,
            payload: loginResponseData.token,
          });
          navigate("/projects");
          sessionStorage.setItem("_id", loginResponseData.user._id);
          sessionStorage.setItem("fullname", loginResponseData.user.fullname);
          sessionStorage.setItem("email", loginResponseData.user.email);
          sessionStorage.setItem("mobile", loginResponseData.user.mobile);
          sessionStorage.setItem("company", loginResponseData.user.company);
          sessionStorage.setItem("role", loginResponseData.user.role);
          sessionStorage.setItem("token", loginResponseData.token);
          Swal.fire({
            icon: "success",
            title: `Welcome ${loginResponseData.user.fullname}`,
            confirmButtonColor: "#100c08",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      let errorData = error.response;
      console.log("Error: " + error);
      Swal.fire({
        icon: "error",
        title: errorData?.data?.msg || "Invalid credentials",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);

    }
  };

  return (
    <div className="login-container">
      <div className="login-panels-container">
        <div>
          <img src="images/working2.svg" alt="" className="login-image" />
        </div>
      </div>
      <div className="login-form-container">
        <div className="login">
          <div className="logo-container">
            <img src={promanagerLogo} alt="Company Logo" className="logo" />
          </div>
          <form action="" className="login-form" onSubmit={handleLogin}>
            <h2 className="login-title">Sign In</h2>
            <div className="login-input-container">
              <FontAwesomeIcon icon={faUser} className="login-icon" />
              <input type="email" id="email" placeholder="Enter Email" onChange={handleInputChange} />
            </div>
            <div className="login-input-container">
              <FontAwesomeIcon icon={faLock} className="login-icon" />
              <input type={showPassword ? "text" : "password"} id="password" placeholder="Enter Password" onChange={handleInputChange} />
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="login-password-toggle-icon" onClick={togglePasswordVisibility} />
            </div>
            <Link to='/forgot-password'>
              <h4 className="signin-forgot-password-link">Forgot Password?</h4>
            </Link>
            <input type="submit" value="login" className="login-btn" onClick={handleLogin} />
          </form>
        </div>
      </div>
      
      { loading && <LoadingSpinner/>}
    </div>
  );
}

export default SIgnIn;
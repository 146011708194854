import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Upload Prebuild File
export const insertPrebuildData = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.put(`${backEndUrl}/api/service/prebuild/insert-prebuild`, formData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// To Get Unique Prebuild Data
export const getUniquePrebuildData = (token, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/prebuild${url}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To get pageload prebuild data
export const getPageloadPrebuildData = (currentProjectId, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${backEndUrl}/api/service/prebuild/get-pageload-prebuild-data`, {
        params: {
          projectId: currentProjectId,
          cubitId: cubitId
        }
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To get total prebuild data
export const fetchPrebuildData = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/prebuild/get-prebuild-data`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

// To get the PB value for last record
export const getPbValue = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/prebuild/get-prebuild-pb`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Insert New Prebuild Data
export const createNewPrebuild = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(`${backEndUrl}/api/service/prebuild/add-prebuildata`, formData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Delete Prebuild Data
export const deletePrebuild = (token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const response = await axios.delete(`${backEndUrl}/api/service/prebuild/delete-prebuild/${id}`, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Update Prebuild Data
export const updatePrebuild = (token, _id, updatedData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.put(`${backEndUrl}/api/service/prebuild/update-prebuild/${_id}`, updatedData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Check Existing Data for Prebuild Data
export const existingDataCheckPrebuildData = (token, newData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(`${backEndUrl}/api/service/prebuild/existing-data-check-prebuild`, newData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
import React, { useEffect, useState } from "react";
import "./UserList.css";
import { MaterialReactTable } from 'material-react-table';
import { getUserdetails } from "../../utils/services/authService";
import CreateUserForm from "../../components/UserComponents/CreateUser/CreateUser"
import UserDetailsForm from "../../components/UserComponents/UserDetails/UserDetails";
import { ROLE } from "../../utils/constant";
import { useUserContext } from "../../context/user.context";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fade, Modal } from "@mui/material";

const UserLists = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [detailsForm, setDetailsForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { userState } = useUserContext();
  const token = sessionStorage.getItem('token');

  // To Fetch User Details
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserdetails(token);
        setUserData(response.Data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Create user click
  const handleCreateUserClick = () => {
    setShowForm(true);
  };

  // Handle to Close user details form
  const handleCloseDetailsForm = () => {
    setDetailsForm(false);
  };

  // Handle to click user details form
  const handleUserDetails = (userIndex) => {
    if (userState.user.role === ROLE.SUPER_ADMIN || userState.user.role === ROLE.ADMIN) {
      const user = userData[userIndex];
      setSelectedUser(user);
      setDetailsForm(true);
    }
  };

  // Define Column Name
  const columns = [
    {
      accessorKey: 'isActive', header: 'Active', enableSorting: false,
      Cell: ({ cell }) => (
        <div className="table-div">
          {cell.getValue() ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} className="red-cross" />}
        </div>
      )
    },
    {
      accessorKey: 'superAdmin', header: 'Super Admin', enableSorting: false,
      Cell: ({ row }) => (
        <div className="table-div">
          {row.original.role === 'super-admin' ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} className="red-cross" />}
        </div>
      )
    },
    {
      accessorKey: 'admin', header: 'Admin', enableSorting: false,
      Cell: ({ row }) => (
        <div className="table-div">
          {row.original.role === 'admin' ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} className="red-cross" />}
        </div>
      )
    },
    {
      accessorKey: 'fullname',
      header: 'Full Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'company',
      header: 'Company',
    }
  ];

  // To set theme for table
  const mrtTheme = (theme) => ({
    baseBackgroundColor: theme.palette.background.default,
  });

  // Define styles for table header
  const muiTableHeadCellProps = {
    sx: {
      border: '1px solid #ccc',
      fontWeight: 'bold',
      backgroundColor: '#1B1B1B',
      fontFamily: 'Segoe UI',
      color: '#C0C0C0',
      fontSize: '14px',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
    },
  };

  // Define styles for table body
  const muiTableBodyCellProps = {
    sx: {
      fontFamily: 'Segoe UI',
      fontWeight: '500',
      border: '1px solid #ccc',
      cursor: 'pointer',
      fontSize: '12px'
    },
  };

  return (
    <div className="user-list-container">
      <div className="user-table-header">
        <h1>Users List</h1>
        <button onClick={handleCreateUserClick}>Create New User</button>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <MaterialReactTable
          columns={columns}
          data={userData}
          enableColumnFilters={false}
          enableSorting={true}
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          mrtTheme={mrtTheme}
          muiTableHeadCellProps={muiTableHeadCellProps}
          muiTableBodyCellProps={muiTableBodyCellProps}
          muiTableBodyRowProps={(row, rowIndex, staticRowIndex, table) => ({
            onClick: () => handleUserDetails(row?.row?.index),
            style: { cursor: 'pointer' }
          })}
        />
      )}
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={showForm} closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Fade in={showForm}>
          <div style={{ width: '60%', minWidth: '55%', maxHeight: '80%', overflowY: 'auto' }}>
            <CreateUserForm
              show={showForm}
              onClose={() => {
                setShowForm(false);
              }}
              userList={userData}
              setUserList={setUserData}
            />
          </div>
        </Fade>
      </Modal>
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={detailsForm} closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Fade in={detailsForm}>
          <div style={{ width: '60%', minWidth: '55%', maxHeight: '90%', overflowY: 'auto' }}>
            <UserDetailsForm
              show={detailsForm}
              onClose={() => {
                handleCloseDetailsForm();
              }}
              user={selectedUser}
              userList={userData}
              setUserList={setUserData}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default UserLists;

import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Save Cubit Item Material Data i.e, Rate table Data
export const saveCubitItemMaterial = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      const response = await axios.post(`${backEndUrl}/api/service/cubititem-material/insert-cubititem-material`, formData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Get Cubit Item Material Data i.e, Rate table Data
export const getCubitIdMaterialData = (token, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      let response = await axios.get(`${backEndUrl}/api/service/cubititem-material/get-cubititem-material-data/${cubitId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Delete Cubit Item Material
export const deleteCubitItemMaterialById = async(token, cubitItemMaterialId) => {
  return new Promise(async(resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      let response = await axios.delete(`${backEndUrl}/api/service/cubititem-material/delete-cubititem-material/${cubitItemMaterialId}`, {
        headers: header,
      });
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};
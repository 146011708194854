import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To get All Company Customer List
export const getAllCustomersList = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${backEndUrl}/api/simpro/customer/get-all-company-customers-list`);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
}

// To Create New Company Customer
export const createCompanyCustomer = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${backEndUrl}/api/simpro/customer/create-company-customer`, data);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    });
}
import React, { useState, useEffect, useMemo } from "react";
import "../Quote/Quote.css";
import '../../pages/Calculationpage/Calculationpage.css'
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faFolder, faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Box, IconButton, Modal, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import moment from 'moment';
import { Toast } from "../../utils/helper";
import { ROLE } from "../../utils/constant";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import CreateSiteModal from '../../components/SimproComponents/SimproSite/CreateSiteModal.js'
import CreateCustomerModal from "../../components/SimproComponents/SimproCustomer/CreateCustomerModal"
import { getAllCustomersList } from "../../utils/simproServices/customerServices";
import { getAllSitesList, updateSite } from "../../utils/simproServices/siteServices.js";
import { getUserdetails } from "../../utils/services/authService";
import { createProjectFolder, getAllProjectsForUsers, deleteProject, updateProjectPermission, updateProjectName } from "../../utils/services/projectService";

const ProjectTable = () => {
  const [loading, setLoading] = useState(false);
  const [showProjectAddModal, setShowProjectAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [addProjectDetails, setAddProjectDetails] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [projects, setProjects] = useState([]);
  const [userData, setUserData] = useState([]);
  const [projectForEdit, setProjectForEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let _id = sessionStorage.getItem("_id");
  let token = sessionStorage.getItem("token");
  const userRole = sessionStorage.getItem("role");
  const navigate = useNavigate();

  // New code to add customer and site to project in simpro
  const [createProjectSteps, setCreateProjectSteps] = useState(1);
  const [isSiteModalOpen, setSiteModalOpen] = useState(false);
  const [customersList, setCustomerList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [newProjectName, setNewProjectName] = useState({});

  // To fetch site and customer deatils
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        const customerData = await getAllCustomersList();
        const customerOptions = customerData.map(customer => ({
          value: customer.ID,
          label: customer.CompanyName
        }));
        setCustomerList(customerOptions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching customers:', err);
        setLoading(false);
      }
    };
    fetchCustomers();

    const fetchSites = async () => {
      try {
        setLoading(true);
        const siteData = await getAllSitesList();
        const siteOptions = siteData.map(site => ({
          value: site.ID,
          label: site.Name
        }));
        setSiteList(siteOptions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching site:', err);
        setLoading(false);
      }
    };

    fetchSites();

  }, [fetchTrigger]);

  // To Display All Projects Details
  useEffect(() => {
    fetchProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);
  const fetchProjectDetails = async () => {
    try {
      if (_id) {
        setLoading(true);
        const response = await getAllProjectsForUsers(token, _id);
        if (response.status) {
          setProjects(response.data);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("Error loading project", error);
      setLoading(false);
    }
  };

  // Validate Form data
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProjectDetails, createProjectSteps, selectedCustomer, selectedSite]);
  const validateForm = () => {
    if (createProjectSteps === 1) {
      const { quotationNumber, jobName, builderName } = addProjectDetails;
      if (quotationNumber && jobName && builderName) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else if (createProjectSteps === 2) {
      if (selectedCustomer?.value && selectedSite?.value) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      setIsFormValid(true);
    }
  };

  // To fetch user details
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserdetails(token);
        setUserData(response.Data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setting super-admin and project created details for default premissions
  useEffect(() => {
    const superAdminUserIds = userData
      .filter(user => user.role === ROLE.SUPER_ADMIN || user._id === _id)
      .map(user => user._id);
    setSelectedUserIds([...superAdminUserIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // To open create new project modal
  const handleCreateProject = () => {
    setShowProjectAddModal(true);
  };

  // Handle input value change
  const handleOnNewProjectValueChange = (event) => {
    event.preventDefault();
    setAddProjectDetails({
      ...addProjectDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateProjectNameChange = (event) => {
    event.preventDefault();
    setNewProjectName({
      ...newProjectName,
      [event.target.name]: event.target.value,
    });
  };

  // To close create new project modal
  const onCloseProjectClick = () => {
    setShowProjectAddModal(false);
    setShowEditModal(false)
    setProjectForEdit(null)
    setSelectedCustomer(null);
    setSelectedSite(null);
    setCreateProjectSteps(1);
    setAddProjectDetails({})
  };

  // handle to open customer modal
  const handleOpenCustomerModal = () => {
    setCustomerModalOpen(true);
  };

  // handle to close customer modal
  const handleCloseCustomerModal = () => {
    setCustomerModalOpen(false);
    setFetchTrigger(prev => !prev);
  };

  // handle to open site modal
  const handleOpenSiteModal = () => {
    setSiteModalOpen(true);
  };

  // handle to close site modal
  const handleCloseSiteModal = () => {
    setSiteModalOpen(false);
    setFetchTrigger(prev => !prev);
  };

  // Handle checkbox
  const handleCheckboxChange = (userId) => {
    setSelectedUserIds(prevIds => {
      if (prevIds.includes(userId)) {
        return prevIds.filter(id => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  // Handle Next button
  const handleNextButtonClick = () => {
    setCreateProjectSteps(createProjectSteps + 1)
  };

  // Handle Back button
  const handleBackButtonClick = () => {
    if (createProjectSteps > 1) {
      setCreateProjectSteps(createProjectSteps - 1)
    }
  };

  // Handle to submit project
  const onProjectSubmitClick = async () => {
    try {
      setLoading(true);
      if (!addProjectDetails.jobName || !addProjectDetails.quotationNumber || !addProjectDetails.builderName) {
        Toast.fire({
          icon: "error",
          title: "Required field is missing!",
        });
        return;
      }
      if (!selectedCustomer.value || !selectedSite.value) {
        Toast.fire({
          icon: "error",
          title: "Customer and Site are required fields",
        });
        return;
      }
      // Attach site to customer
      const attachSiteResponse = await updateSite(selectedSite.value, selectedCustomer.value);
      if (!attachSiteResponse.status) {
        Toast.fire({
          icon: "error",
          title: "Something went wrong while assigning site to customer.",
        });
        return;
      }
      let data = {
        ...addProjectDetails,
        createdBy: _id,
        members: selectedUserIds,
        simproCustomerId: selectedCustomer.value,
        simproSiteId: selectedSite.value,
      };
      const createProjectResponse = await createProjectFolder(token, data);
      const projectName = createProjectResponse.data.projectName
      if (createProjectResponse.status) {
        setProjects([...projects, createProjectResponse.data]);
        Swal.fire({
          icon: "success",
          title: `Project ${projectName} Created Successfully`,
          confirmButtonColor: "#100c08",
        })
        onCloseProjectClick();
      }
      setLoading(false);
    } catch (err) {
      console.log("Err", err);
      if (err?.response?.data?.message?.includes('E11000')) {
        Toast.fire({
          icon: "error",
          title: "Project Name already exists!",
        })
      }
      setLoading(false);
    }
  };

  const handleEditProjectName = (row, event) => {
    event.stopPropagation();
    if (editingRowId) {
      Toast.fire({
        icon: 'error',
        title: 'Only one project can be edited at a time.',
      });
      return;
    }
    setEditingRowId(row.original._id);

    let splittedProjectName = row.original.projectName.split("_");
    setNewProjectName({
      quotationNumber: splittedProjectName[0],
      builderName: splittedProjectName[1],
      jobName: splittedProjectName[2],
    });
  };

  const handleCancelEdit = () => {
    setEditingRowId(null);
    setNewProjectName({});
  };

  const handleSaveEdit = async () => {
    try {
      if (!newProjectName.quotationNumber || !newProjectName.jobName || !newProjectName.builderName) {
        Toast.fire({
          icon: 'error',
          title: 'All fields are required.',
        });
        return;
      }
      const response = await updateProjectName(token, newProjectName, editingRowId);
      if (response.status) {
        let localProjects = [...projects];
        localProjects = localProjects.map(project =>
          project._id === response.data._id ? response.data : project
        );

        setProjects(localProjects);
        setEditingRowId(null);
        setNewProjectName("");
        Toast.fire({
          icon: 'success',
          title: response.message,
        });
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      console.error("Error updating project name", err);
      if (err.message && err.message.includes('E11000 duplicate key error')) {
        Toast.fire({
          icon: 'error',
          title: 'Project name already exists. Please use a different name.',
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Failed to update project name',
        });
      }
    }
  };


  // Define columns 
  const columns = useMemo(() => [
    {
      accessorKey: 'file',
      header: '',
      enableSorting: false,
      size: 50,
      Cell: () => <FontAwesomeIcon icon={faFolder} style={{ width: '18px', height: '18px' }} />,
    },
    {
      accessorKey: 'projectName',
      header: 'Project Name',
      size: 150,
      Cell: ({ row }) => {

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{row.original.projectName}</span>
            {userRole == ROLE.SUPER_ADMIN &&
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ width: '18px', height: '16px', cursor: 'pointer' }}
                onClick={(e) => handleEditProjectName(row, e)}
              />
            }

          </div>
        );
      },
    },
    {
      accessorKey: 'createdBy',
      header: 'Created By',
      size: 200,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 150,
    },
    userRole === ROLE.SUPER_ADMIN && {
      accessorKey: 'edit',
      header: 'Edit Permissions',
      size: 150,
      enableSorting: false,
      Cell: ({ row }) => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ width: '18px', height: '16px', cursor: 'pointer' }}
          onClick={(event) => handleEditClick(row.original._id, event)}
        />
      ),
    },
    userRole === ROLE.SUPER_ADMIN && {
      accessorKey: 'action',
      header: 'Action',
      size: 150,
      enableSorting: false,
      Cell: ({ row }) => (
        <FontAwesomeIcon
          icon={faTrashCan}
          style={{ width: '18px', height: '16px', color: 'red', cursor: 'pointer' }}
          onClick={(event) => handleProjectFileDeleteById(row?.original?._id, event)}
        />
      ),
    },
  ].filter(Boolean), [projects]);

  // Handle edit click
  const handleEditClick = (projectId, event) => {
    event.stopPropagation();
    let selectedProject = projects.filter(project => project._id === projectId)[0];
    setProjectForEdit(selectedProject)
    setShowEditModal(true);
  };

  // Handle to edit permissions for project
  const handleEditCheckboxChange = (userId) => {
    setProjectForEdit(prevState => {
      if (prevState.members.includes(userId)) {
        return {
          ...prevState,
          members: prevState.members.filter(id => id !== userId)
        };
      } else {
        return {
          ...prevState,
          members: [...prevState.members, userId]
        };
      }
    });
  };

  // Handle to update permissions
  const handleUpdatePermissionClick = async () => {
    try {
      setIsLoading(true)
      let data = {
        projectId: projectForEdit._id,
        members: projectForEdit.members,
      }
      const updatePermissionResponse = await updateProjectPermission(token, data);
      if (updatePermissionResponse.status) {
        const localProjects = [...projects];
        localProjects.forEach(project => {
          if (project._id === updatePermissionResponse?.data?._id) {
            project.members = updatePermissionResponse.data.members
          }
        })

        setProjects([...localProjects])
        setIsLoading(false)
        Toast.fire({
          icon: 'success',
          title: 'Permissions updated successfully'
        })
      }

    } catch (err) {
      setIsLoading(false)
      console.log("Error", err);
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong while updating the permissions.'
      })
    }
  }

  // To Delete Project
  const handleProjectFileDeleteById = async (projectId, event) => {
    try {
      event.stopPropagation();
      const confirmationResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete the project folder. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#100c08',
        cancelButtonColor: '#D1D1D1',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: "No, cancel!",
        customClass: {
          cancelButton: "cancel-button-class",
        },
      });
      if (confirmationResult.isConfirmed) {
        setIsLoading(true);
        await deleteProject(token, projectId);
        await fetchProjectDetails();
        navigate("/projects");
        Swal.fire({
          title: "Deleted",
          text: "Your Project Folder has been deleted.",
          icon: "success",
          confirmButtonColor: "#100c08",
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Your Project folder is safe :)',
          icon: 'info',
          customClass: {
            confirmButton: "cancel-button-class",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error deleting Project folder",
        confirmButtonColor: "#100c08",
      });
      console.error("Error deleting Project folder:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Project Click
  const handleProjectItemClick = (projectId) => {
    navigate('/projects/' + projectId)
  }

  const data = useMemo(() => {
    return projects?.length ? projects?.map(project => ({
      ...project,
      createdAt: moment(project?.createdAt).format("DD MMM YYYY"),
      createdBy: project?.createdBy?.fullname || 'Unknown User',
      file: '',
      edit: '',
      action: '',
    })) : [];
  }, [projects]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({

      onClick: (event) => handleProjectItemClick(row.original._id),
      component: TableRow,
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#8f8f8f',
          color: 'white',
        },
      },
    }),
    muiTableProps: {
      sx: {
        border: '1px solid #ccc',
        caption: {
          captionSide: 'top',
        },
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <h2 variant="contained" style={{ textDecoration: "underline" }}>
        Projects :
      </h2>
    ),
    muiTableHeadCellProps: {
      sx: {
        border: '1px solid #ccc',
        fontWeight: 'bold',
        backgroundColor: '#1B1B1B',
        fontFamily: 'Segoe UI',
        color: '#C0C0C0',
        fontSize: '14px',
        '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
          color: '#fff !important',
        },
        '& .MuiTableSortLabel-icon': {
          color: '#fff',
        },
        '& .MuiIconButton-root': {
          color: '#fff',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: 'Segoe UI',
        fontWeight: '500',
        border: '1px solid #ccc',
        cursor: 'pointer',
        fontSize: '12px',
      },
    },
  });

  const customDropdownStyles = {
    menuPortal: base => ({ ...base, zIndex: 13 }),
    control: base => ({ ...base, minHeight: '30px' }),
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <></>
      )}

      {editingRowId ?
        <>
          <div className="overlay">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-form" style={{ borderRadius: '0px' }}>
                  <span className="close" onClick={handleCancelEdit}>&times;</span>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h1 style={{ textAlign: 'left', marginBottom: '30px', marginTop: '-30px' }}>Update Project Name</h1>
                    </div>
                  </Box>
                  <div className="model-form-content-style" style={{ marginTop: '-30px', textAlign: 'left' }}>
                    <div>
                      <label htmlFor="quotationNumber" style={{ fontSize: '16px' }}>Enter Quotation Number<span className="required-field"></span></label>
                      <input type="text" name="quotationNumber" id="quotationNumber" style={{ border: !newProjectName.quotationNumber && "1px solid red" }} value={newProjectName.quotationNumber} onChange={handleUpdateProjectNameChange} />
                    </div>

                    <div>
                      <label htmlFor="builderName" style={{ color: !newProjectName.builderName && "", fontSize: '16px' }}>Enter Builder Name<span className="required-field"></span> </label>
                      <input type="text" name="builderName" id="builderName" style={{ border: !newProjectName.builderName && "1px solid red" }} value={newProjectName.builderName} onChange={handleUpdateProjectNameChange} />
                    </div>
                    <div>
                      <label htmlFor="jobName" style={{ fontSize: '16px' }}>Enter Job Name<span className="required-field"></span></label>
                      <input type="text" name="jobName" id="jobName" style={{ border: !newProjectName.jobName && "1px solid red" }} value={newProjectName.jobName} onChange={handleUpdateProjectNameChange} />
                    </div>
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button type="button" style={{ flex: 1 }} className={`rate-input-form-button `} onClick={handleSaveEdit}>Update</button>
                    <button type="button" style={{ flex: 1 }} className="rate-input-form-button" onClick={handleCancelEdit}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </> : null
      }

      {
        showProjectAddModal && (
          <div className="overlay">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-form" style={{ borderRadius: '0px' }}>
                  <span className="close" onClick={onCloseProjectClick}>&times;</span>
                  {createProjectSteps === 1 ?
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h1 style={{ textAlign: 'left', marginBottom: '30px', marginTop: '-30px' }}>Create New Project</h1>
                        </div>
                      </Box>
                      <div className="model-form-content-style" style={{ marginTop: '-30px', textAlign: 'left' }}>
                        <div>
                          <label htmlFor="quotationNumber" style={{ color: !addProjectDetails.quotationNumber && "", fontSize: '16px' }}>Enter Quotation Number<span className="required-field"></span></label>
                          <input type="text" name="quotationNumber" id="quotationNumber" value={addProjectDetails.quotationNumber} onChange={handleOnNewProjectValueChange} />
                        </div>

                        <div>
                          <label htmlFor="builderName" style={{ color: !addProjectDetails.builderName && "", fontSize: '16px' }}>Enter Builder Name<span className="required-field"></span> </label>
                          <input type="text" name="builderName" id="builderName" value={addProjectDetails.builderName} onChange={handleOnNewProjectValueChange} />
                        </div>
                        <div>
                          <label htmlFor="jobName" style={{ color: !addProjectDetails.jobName && "", fontSize: '16px' }}>Enter Job Name<span className="required-field"></span></label>
                          <input type="text" name="jobName" id="jobName" value={addProjectDetails.jobName} onChange={handleOnNewProjectValueChange} />
                        </div>
                      </div>
                    </>
                    : createProjectSteps === 2 ?
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 style={{ textAlign: 'left', marginBottom: '30px', marginTop: '-30px' }}>Select Customer and Site</h1>
                          </div>
                        </Box>
                        <div className="quote-container" style={{ marginTop: '-20px' }}>
                          <div className="quote-form">
                            <label htmlFor="customer" style={{ fontSize: '16px', }}>Customer <span className="required-field"></span></label>
                            <Select
                              id="customer"
                              className="select-box"
                              options={customersList}
                              placeholder="Select Customer"
                              isSearchable
                              onChange={setSelectedCustomer}
                              value={selectedCustomer}
                              styles={customDropdownStyles}
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                            />
                            <div>
                              <span className="new-link" onClick={handleOpenCustomerModal} style={{ fontSize: '14px' }}>+ New Customer</span>
                            </div>
                          </div>

                          <div className="quote-form" style={{ marginTop: '20px' }}>
                            <label htmlFor="site" style={{ fontSize: '16px' }}>Site <span className="required-field"></span></label>
                            <Select
                              id="site"
                              className="select-box"
                              options={siteList}
                              placeholder="Select Site"
                              isSearchable
                              onChange={setSelectedSite}
                              value={selectedSite}
                              styles={customDropdownStyles}
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                            />
                            <div>
                              <span className="new-link" onClick={handleOpenSiteModal} style={{ fontSize: '14px' }}>+ New Site</span>
                            </div>
                          </div>
                        </div>
                      </> : createProjectSteps === 3 ?
                        <>
                          <div className="model-form-content-style">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <h1 style={{ marginTop: '-50px', textAlign: 'left' }}>Select Users</h1>
                            </div>
                            <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'auto', marginTop: '20px' }}>
                              <table style={{ width: '90%', margin: '0 auto', borderCollapse: 'collapse' }}>
                                <thead>
                                  <tr>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Employee Name</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Role</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Select Users</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userData.map((user, index) => (
                                    <tr key={index}>
                                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.fullname}</td>
                                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.role}</td>
                                      <td style={{ border: '1px solid #ddd', padding: '8px', cursor: 'pointer', textAlign: 'center' }}>
                                        <input
                                          type="checkbox"
                                          name={`employee${index}`}
                                          onChange={() => handleCheckboxChange(user._id)}
                                          checked={selectedUserIds.includes(user._id)}
                                          disabled={user.role === ROLE.SUPER_ADMIN || user._id === _id}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </> : <></>}

                  {createProjectSteps === 1 || createProjectSteps === 2 ?
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                      <button type="button" style={{ flex: 1 }} className={`rate-input-form-button ${createProjectSteps <= 1 ? 'disabled-button' : ''}`} onClick={handleBackButtonClick} disabled={createProjectSteps <= 1}>Back</button>
                      <button type="button" style={{ flex: 1 }} className={`rate-input-form-button ${!isFormValid ? 'disabled-button' : ''}`} onClick={handleNextButtonClick} disabled={!isFormValid}>Next</button>
                    </div>
                    : <>
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                        <button type="button" style={{ flex: 1 }} className={`rate-input-form-button ${createProjectSteps <= 1 ? 'disabled-button' : ''}`} onClick={handleBackButtonClick} disabled={createProjectSteps <= 1}>Back</button>
                        <button type="button" style={{ flex: 1 }} className="rate-input-form-button" onClick={onProjectSubmitClick}>Submit</button>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        showEditModal && (
          <div className="edit-permissions-form">
            <div className="edit-permissions-modal">
              <div className="edit-permissions-modal-content">
                <form className="edit-permissions-modal-form" style={{ backgroundColor: "#f6f6f6" }}>
                  <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#1B1B1B', display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', borderRadius: '4px' }}>
                    <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>{projectForEdit?.projectName} : Edit Permissions</h2>
                    <IconButton onClick={onCloseProjectClick}>
                      <CloseIcon sx={{ fontSize: '22px', color: '#c0c0c0', marginTop: '-15px' }} />
                    </IconButton>
                  </Box>
                  <table style={{ width: '100%', margin: '0 auto', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Employee Name</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Role</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Select Users</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((user, index) => (
                        <tr key={index}>
                          <td style={{ border: '1px solid #ccc', padding: '8px' }}>{user.fullname}</td>
                          <td style={{ border: '1px solid #ccc', padding: '8px' }}>{user.role}</td>
                          <td style={{ border: '1px solid #ccc', padding: '8px', cursor: 'pointer', textAlign: 'center' }}>
                            <input
                              type="checkbox"
                              name={`employee${index}`}
                              checked={projectForEdit?.members?.includes(user._id)}
                              onChange={() => handleEditCheckboxChange(user._id)}
                              disabled={user.role === ROLE.SUPER_ADMIN || user._id === _id}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button type="button" className="edit-permissions-form-button" onClick={handleUpdatePermissionClick}>Update</button>
                </form>
              </div>
            </div>
          </div>
        )
      }
      <div className="project-table">
        <div className="project-tile" onClick={handleCreateProject}>
          <FontAwesomeIcon icon={faFolderPlus} style={{ width: '20px', height: '20px', marginBottom: '5px' }} />
          <div>Create New Project</div>
        </div>
        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>
      <CreateCustomerModal open={isCustomerModalOpen} handleClose={handleCloseCustomerModal} disableBackdropClick />
      <CreateSiteModal open={isSiteModalOpen} handleClose={handleCloseSiteModal} disableBackdropClick />
      {loading && <LoadingSpinner />}
    </>
  );
};

export default ProjectTable;
import React, { useEffect, useState } from "react";
import './App.css'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "./components/Navbar/Navbar.js";
import ProjectTable from "./pages/ProjectTable/ProjectTable.js";
import Calculationpage from "./pages/Calculationpage/Calculationpage.js";
import PrebuildData from "./pages/PrebuildData/PrebuildData.js";
import UserLists from "./pages/UserLists/UserLists.js";
import { jwtDecode } from "jwt-decode";
import PrivateRoute from "./routes/PrivateRoute.js";
import AdminRoute from "./routes/AdminRoute.js";
import { useUserContext, userActionTypes } from "./context/user.context.js";
import LoadingSpinner from "./components/Loader/LoadingSpinner.js";
import ProjectItems from "./pages/ProjectItems/ProjectItems.jsx";
import CubitInformation from "./pages/CubitInformation/CubitInformation.js";
import CubitVersionData from "./pages/CubitVersionData/CubitVersionData.jsx";
import OneOffItemData from "./pages/OneOffItemData/OneOffItem.js"
import SignIn from './pages/Login_Signin/SIgnIn.js'
import ForgotPassword from "./pages/Forgot-Password/ForgotPassword.js";
import Quote from "./pages/Quote/Quote.js";

function App() {
  const navigate = useNavigate();
  const { userState, userDispatch } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Handle logout or navigation 
  useEffect(() => {
    if (location.pathname === "/" && !userState.token) {
      handleLogout();
    } else {
      if (location.pathname === "/") {
        navigate("/projects")
      } else {
        navigate(location.pathname)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // if token is expired handle logout
  useEffect(() => {
    try {
      setIsLoading(true);
      let _id = sessionStorage.getItem("_id");
      let fullname = sessionStorage.getItem("fullname");
      let email = sessionStorage.getItem("email");
      let mobile = sessionStorage.getItem("mobile");
      let role = sessionStorage.getItem("role");
      let company = sessionStorage.getItem("company");
      let token = sessionStorage.getItem("token");
      if (token) {
        let decode = jwtDecode(token);
        if (!decode) {
          handleLogout();
        } else {
          const currentTime = Math.floor(Date.now() / 1000);
          const isExpired = decode.exp < currentTime;
          const willExpireSoon = decode.exp - currentTime < 3600;
          if (isExpired || willExpireSoon) {
            handleLogout();
          } else {
            userDispatch({
              type: userActionTypes.UPDATE_USER,
              payload: { _id, fullname, email, mobile, company, role },
            });
            userDispatch({
              type: userActionTypes.UPDATE_TOKEN,
              payload: token,
            });
          }
        }
      } else {
        handleLogout();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle logout logic
  const handleLogout = () => {
    sessionStorage.setItem("fullname", "");
    sessionStorage.setItem("email", "");
    sessionStorage.setItem("mobile", "");
    sessionStorage.setItem("clientId", "");
    sessionStorage.setItem("role", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("_id", "");

    userDispatch({
      type: userActionTypes.UPDATE_USER,
      payload: null,
    });
    userDispatch({
      type: userActionTypes.UPDATE_TOKEN,
      payload: "",
    });

    navigate("/");
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <></>
      )}
      <Toaster />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path="/" element={<Navbar />}>
          <Route path="/cubit-information/:cubitId" element={<PrivateRoute />}>
            <Route path="/cubit-information/:cubitId" element={<CubitInformation />} />
          </Route>

          <Route path="/projects" element={<PrivateRoute />}>
            <Route path="/projects" element={<ProjectTable />} />
          </Route>

          <Route path="/projects/:projectId" element={<PrivateRoute />}>
            <Route path="/projects/:projectId" element={<ProjectItems />} />
          </Route>

          <Route path="/cubit-version-data/:cubitId" element={<PrivateRoute />}>
            <Route path="/cubit-version-data/:cubitId" element={<CubitVersionData />} />
          </Route>

          <Route path="/calculationpage/:rateInputFormId" element={<PrivateRoute />}>
            <Route path="/calculationpage/:rateInputFormId" element={<Calculationpage />} />
          </Route>

          <Route path="/prebuild-data" element={<AdminRoute />}>
            <Route path="/prebuild-data" element={<PrebuildData />} />
          </Route>

          <Route path="/one-off-item-data/:projectId" element={<PrivateRoute />}>
            <Route path="/one-off-item-data/:projectId" element={<OneOffItemData />} />
          </Route>

          <Route path="/quote/:calculationID" element={<PrivateRoute />}>
            <Route path="/quote/:calculationID" element={<Quote />} />
          </Route>

          <Route path="/users" element={<AdminRoute />}>
            <Route path="/users" element={<UserLists />} />
          </Route>

        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import './LeftTable.css'
import { useParams, useNavigate } from "react-router-dom";
import { MaterialReactTable } from 'material-react-table';
import { Resizable } from "re-resizable";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import RightTable from '../RightTable/RightTable'
import CalculationForm from "../../components/RateInputForm/CalculationForm";
import { getCubitIdMaterialData } from "../../utils/services/cubititemmaterialService";
import { fetchCubitDataForCubitVersion } from '../../utils/services/cubitService'

function LeftTable({ rowData, isEditing, prebuildData, oneoffItemData, setOneoffItemData }) {
  const [loading, setLoading] = useState(true);
  const [cubitItemMaterial, setCubitItemMaterial] = useState([]);
  const [isCalculationAvailable, setIsCalculationAvailable] = useState(true);
  const [showNewForm, setShowNewForm] = useState(false);
  const [clickedLQuantity, setClickedLQuantity] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [rightTableData, setRightTableData] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedWallCeilingType, setSelectedWallCeilingType] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [selectedBillRef, setSelectedBillRef] = useState(null);
  const [selectedCombined, setSelectedCombined] = useState(null);
  const token = sessionStorage.getItem('token')
  const { cubitId } = useParams();
  const navigate = useNavigate();

  // To fetch right table data
  useEffect(() => {
    const fetchData = async () => {
      if (cubitId) {
        try {
          setLoading(true)
          let response = await getCubitIdMaterialData(token, cubitId);
          const existingData = response || [];
          setCubitItemMaterial(existingData);
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log('Error fetching cubit', error);
        }

        try {
          setLoading(true)
          const cubitDataResponse = await fetchCubitDataForCubitVersion(token, cubitId);
          setIsCalculationAvailable(cubitDataResponse.isCalculationData);
          setLoading(false)
        } catch (err) {
          setLoading(false)
          console.log('Error fetching fetchCubitDataForCubitVersion', err);
        }
      }
    };

    fetchData();
  }, [cubitId, token]);

  // Handle to Open Rate Input Form
  const handleNewFormClick = () => {
    setShowNewForm(true);
  };

  // Handle to Close Rate Input Form
  const handleCloseForm = () => {
    setShowNewForm(false);
  };

  // Define columns
  const columns = useMemo(() => {
    return [
      {
        header: 'S.No',
        accessorKey: 'SNo',
        enableSorting: false,
        size: 80,
      },
      {
        header: 'Package',
        accessorKey: 'Package',
      },
      {
        header: 'Wall/Ceiling Types',
        accessorKey: 'Wall/Ceiling Types',
      },
      {
        header: 'Notes',
        accessorKey: 'Notes',
      },
      {
        header: 'Bill Ref',
        accessorKey: 'Bill Ref',
      },
    ];
  }, []);

  // Left Table Row Click
  const handleOnRowClicked = (event) => {
    if (event && event.row) {
      const clickedRowId = event.row.index;
      const wallCeilingType = event.row.original["Wall/Ceiling Types"];
      const Notes = event.row.original.Notes;
      const billRef = event.row.original["Bill Ref"];
      const combinedString = `${event.row.original["Wall/Ceiling Types"]} - ${event.row.original.Notes} - ${event.row.original["Bill Ref"]}`;
      const clickedRowData = event.row.original;
      const LQuantity = clickedRowData["LQuantity"];
      setClickedLQuantity(LQuantity);
      setSelectedRowId(clickedRowId);
      if (rightTableData[clickedRowId]) {
        setSelectedRowData(rightTableData[clickedRowId]);
      } else {
        const newData = { prebuildOneOffSet: "", primarySecondary: "", costCenterName: "", costCenterSub: "", subGroup1: "", subGroup2: "", CommonName: "", id: clickedRowId, };
        setSelectedRowData(newData);
        setRightTableData((prevData) => ({ ...prevData, [clickedRowId]: newData, }));
      }
      setSelectedWallCeilingType(wallCeilingType);
      setSelectedNotes(Notes);
      setSelectedBillRef(billRef);
      setSelectedCombined(combinedString);
    }
  };

  // Set theme for the table
  const mrtTheme = (theme) => ({
    baseBackgroundColor: theme.palette.background.default,
  });

  // Define styles for table header
  const muiTableHeadCellProps = {
    sx: {
      border: '1px solid #ccc',
      fontWeight: 'bold',
      backgroundColor: '#1B1B1B',
      fontFamily: 'Segoe UI',
      color: '#C0C0C0',
      fontSize: '14px',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      overflow: 'visible',
      textOverflow: 'clip',
      display: 'block',
    },
  };

  // Define syles for table body
  const muiTableBodyCellProps = {
    sx: {
      fontFamily: 'Segoe UI',
      fontWeight: '500',
      border: '1px solid #ccc',
      cursor: 'pointer',
      fontSize: '12px',
    },
  };

  // To set background color for row
  const getRowBackgroundColor = (row) => {
    const combinedStringToCheck = `${row?.row?.original["Wall/Ceiling Types"]} - ${row?.row?.original?.Notes} - ${row?.row?.original["Bill Ref"]}`;
    const matchInLocalStorage = cubitItemMaterial.some((item) => item?.combinedString === combinedStringToCheck && item?.data?.length > 0);
    return row?.row?.index === selectedRowId ? "#4D4D4D" : matchInLocalStorage ? "#808080" : "";
  };

  // To set text color for row
  const getRowColor = (row) => {
    const combinedStringToCheck = `${row?.row?.original["Wall/Ceiling Types"]} - ${row?.row?.original?.Notes} - ${row?.row?.original["Bill Ref"]}`;
    const matchInLocalStorage = cubitItemMaterial.some((item) => item?.combinedString === combinedStringToCheck && item?.data?.length > 0);
    return row?.row?.index === selectedRowId ? "white" : matchInLocalStorage ? "white" : "black";
  };

  // Render content for Top bar
  const renderTopToolbarCustomActions = ({ table }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '5px' }}>
      {cubitId ? <button onClick={() => navigate('/cubit-version-data/' + cubitId)} className="rate-input-btn">Back</button> : ''}
      <button onClick={handleNewFormClick} className="rate-input-btn">Rate Input Form</button>
    </div>
  );

  return (
    <div style={{ display: "flex", width: "100vw", height: "93vh" }} className='table-container1'>
      <Resizable defaultSize={{ width: "30%", height: "100%" }} style={{ overflow: 'hidden', flex: 1 }} maxWidth="35%" enable={{ top: false, bottom: true, left: false, right: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: true, }}>
        <div className="table-container2" style={{ overflowX: 'auto' }}>
          <MaterialReactTable
            columns={columns}
            data={rowData}
            enablePagination={false}
            enableDensityToggle={false}
            enableStickyHeader={true}
            enableColumnResizing={true}
            mrtTheme={mrtTheme}
            muiTableHeadCellProps={muiTableHeadCellProps}
            muiTableBodyCellProps={muiTableBodyCellProps}
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            initialState={{ density: 'compact' }}
            muiTableBodyRowProps={(row) => ({
              onClick: () => handleOnRowClicked(row),
              sx: {
                backgroundColor: getRowBackgroundColor(row),
                '& .MuiTableCell-root': {
                  color: getRowColor(row),
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }
              }
            })}
          />
        </div>
      </Resizable>
      {selectedRowData && (
        <RightTable
          selectedRowData={selectedRowData}
          wallCeilingType={selectedWallCeilingType}
          Notes={selectedNotes}
          billRef={selectedBillRef}
          combinedString={selectedCombined}
          LQuantity={clickedLQuantity}
          key={selectedRowData.id}
          setCubitItemMaterial={setCubitItemMaterial}
          cubitItemMaterial={cubitItemMaterial}
          isCalculationAvailable={isCalculationAvailable}
          prebuildData={prebuildData}
          oneoffItemData={oneoffItemData}
          setOneoffItemData={setOneoffItemData}
        />
      )}
      {showNewForm && (
        <div className="overlay">
          <CalculationForm show={showNewForm} onClose={handleCloseForm} isCalculationAvailable={isCalculationAvailable} />
        </div>
      )}
      {loading && <LoadingSpinner />}
    </div>
  );
}

export default LeftTable;

/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import './ForgotPassword.css'
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { handleForgotPassword, resetPasswordForUser } from "../../utils/services/authService";

function ForgotPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState({ password: "", confirmPassword: "", otp: "", });
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle to sent otp for email afetr user enter email
  const handleForgotPasswordSubmitClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Email",
          text: "Please enter a valid email address",
          confirmButtonColor: "#100c08",
        });
        setLoading(false);
        return;
      }
      const response = await handleForgotPassword({ email });
      if (response && response.data.message === 'OTP sent successfully') {
        setShowResetPassword(true);
        Swal.fire({
          icon: "success",
          title: "OTP Sent",
          text: "OTP Sent to  email sent successfully",
          confirmButtonColor: "#100c08",
        });
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Failed to send OTP to email",
          confirmButtonColor: "#100c08",
        });
        setLoading(false);
      }
    } catch (error) {
      console.log('Something went wrong', error);
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Failed to send password reset email",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
    }
  };

  // Handle to reset password
  const handleOnResetPassword = async () => {
    setLoading(true);
    if (!userData.otp || !userData.password || !userData.confirmPassword) {
      Swal.fire({
        icon: "warning",
        title: "All fields are required",
        text: "Please Enter All the Fields",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      return;
    }
    if (userData.password !== userData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Password Doesn't Matched",
        text: "Entred Password Doesn't matched",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      return;
    }
    try {
      const response = await resetPasswordForUser(email, userData);
      Swal.fire({
        icon: "success",
        title: 'Password updated successfully',
        text: `${response.message}`,
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      navigate('/')
    } catch (err) {
      console.log('error reset password', err)
      Swal.fire({
        icon: "error",
        title: `${err.response.data.error}`,
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {showResetPassword ? (
        <div className='forgotpassword-content'>
          {loading && <LoadingSpinner />}
          <h1>Reset Password</h1>
          <div className='resetpassword-inputContainer'>
            <input type='text' name='otp' placeholder='Enter OTP' value={userData.otp} onChange={(e) => { setUserData({ ...userData, otp: e.target.value }); }} />
          </div>
          <div className='resetpassword-inputContainer'>
            <input type="password" name='newPassword' placeholder='New Password' value={userData.password} onChange={(e) => { setUserData({ ...userData, password: e.target.value }); }} />
          </div>
          <div className='resetpassword-inputContainer'>
            <input type="password" name='confirmPassword' placeholder='Confirm Password' value={userData.confirmPassword} onChange={(e) => { setUserData({ ...userData, confirmPassword: e.target.value }); }} />
          </div>
          <button className='reset-button' onClick={handleOnResetPassword}>Reset Password</button>
          <Link to="/" className="forgot-password-link">&lt; Back To Login</Link>
        </div>
      ) : (
        <div className='forgotpassword-content'>
          {loading && <LoadingSpinner />}
          <h1>Forgot Password</h1>
          <p className='forgotpassword-p'>Please enter your email to get an OTP for reset password.</p>
          <div className='forgotpassword-inputContainer'>
              <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
            <input type='email' name='email' id='email' placeholder='Enter Email Address' value={email} onChange={(e) => { setEmail(e.target.value); }} />
          </div>
          <button className='forgot-button' onClick={handleForgotPasswordSubmitClick}>Submit</button>
          <Link to="/" className="forgot-password-link">&lt; Back To Login</Link>
        </div>
      )}
    </div>
  )
}

export default ForgotPassword
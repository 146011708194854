import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Get the Default Rate Input Values
export const getDefaultRateInputValues = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/rateinputform/get-default-rate-input-values`, {
        headers: header,
      });
      return resolve(response.data.Data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To insert Rate Input Form Values
export const insertRateInputFormValues = (projectId, cubitId, rateInputValues) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${backEndUrl}/api/service/rateinputform/insert-rateinputform`, { projectId, cubitId, rateInputValues });
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Get the Rate Input Values
export const getRateInputFormData = (token, projectId, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const url = `${backEndUrl}/api/service/rateinputform/get-rateinputformdata/${projectId}/${cubitId}`;

      const response = await axios.get(url, {
        headers: headers
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Get the Rate Input Values By ID
export const getRateInputDataById = (token, rateInputId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const url = `${backEndUrl}/api/service/rateinputform/rate-input-by-id/${rateInputId}`;

      const response = await axios.get(url, {
        headers: headers
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// Create a copy of rate input form.
export const createRateInputCopy = (token, rateInputFormId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      let response = await axios.post(`${backEndUrl}/api/service/rateinputform/copy-rateinputform`, { rateInputFormId }, {
        headers: headers
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import Swal from "sweetalert2";
import { useUserContext } from "../../context/user.context";
import { projectActionTypes, useProjectContext } from "../../context/project.context";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import LeftTable from "../../components/LeftTable/LeftTable";
import { getRateInputFormData } from '../../utils/services/rateinputService';
import { getCubitSingleDataForProject } from '../../utils/services/cubitService'
import { getPageloadPrebuildData } from "../../utils/services/prebuildService";
import { getPageLoadOneOffItem } from "../../utils/services/oneoffitemService";

const socket = io(process.env.REACT_APP_BACKEND_URL);

function CubitInformation() {
  const [fileData1, setFileData1] = useState([]);
  const [prebuildData, setPrebuildData] = useState([]);
  const [oneoffItemData, setOneoffItemData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const { projectDispatch } = useProjectContext()
  const { projectState } = useProjectContext();
  const { userState } = useUserContext();
  const { cubitId } = useParams();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  // When one opened project another user cant able to edit
  useEffect(() => {
    const userID = sessionStorage.getItem('_id')
    socket.emit("startEditing", cubitId, userID);
    setIsEditing(true);
    socket.on('editingDenied', () => {
      Swal.fire({
        title: "Acces Deined!!",
        text: "Another user is currently working on this project",
        icon: "warning",
        confirmButtonColor: "#100c08",
      });
      navigate('/projects')
      setIsEditing(false);
    });
    return () => {
      socket.emit("stopEditing", cubitId, userID);
      setIsEditing(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To fetch cubit file data, prebuild data and oneoff data when page loads
  useEffect(() => {
    const fetchData = async (cubitId) => {
      try {
        setLoading(true);
        const response = await getCubitSingleDataForProject(token, cubitId);
        setFileData1(response.data.filterData);
        projectDispatch({ type: 'UPDATE_CURRENT_PROJECTID', payload: response.data.projectId });
        const rateInputResponse = await getRateInputFormData(token, response.data.projectId, cubitId);
        projectDispatch({
          type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
          payload: rateInputResponse.Data[0]?._id
        });
        const prebuildData = await getPageloadPrebuildData(projectState?.currentProjectId, cubitId);
        setPrebuildData(prebuildData)
        const oneoffItemData = await getPageLoadOneOffItem(projectState?.currentProjectId, cubitId);
        setOneoffItemData(oneoffItemData)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (cubitId) {
      fetchData(cubitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubitId, userState.token]);

  return (
    <div style={{ overflow: 'hidden' }}>
      {loading && <LoadingSpinner />}
      {fileData1 && fileData1.length > 0 && <LeftTable rowData={fileData1} prebuildData={prebuildData} oneoffItemData={oneoffItemData} setOneoffItemData={setOneoffItemData} />}
    </div>
  );
}

export default CubitInformation;
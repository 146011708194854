import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Create Projects
export const createProjectFolder = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(`${backEndUrl}/api/service/project/create-project-folder`, data, { headers });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Get All Projects Lists
export const getAllProjectsForUsers = (token, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/project/get-projects/${userId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

// To Delete project
export const deleteProject = (token, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.delete(`${backEndUrl}/api/service/project/delete-projects/${projectId}`, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To update project permissions
export const updateProjectPermission = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.patch(`${backEndUrl}/api/service/project/edit-permision-projects`, data, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}


// To Update Project Name
export const updateProjectName = async (token, data, projectId) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    
    const response = await axios.patch(
      `${backEndUrl}/api/service/project/update-project-name/${projectId}`,
      data,
      { headers }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error response:', error.response.data);
      throw new Error(`Failed to update project name: ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      console.error('Error request:', error.request);
      throw new Error('No response received from the server while updating project name.');
    } else {
      console.error('Error message:', error.message);
      throw new Error(`Error updating project name: ${error.message}`);
    }
  }
};

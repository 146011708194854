import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './CalculationForm.css';
import { Toast } from '../../utils/helper';
import { ROLE } from "../../utils/constant";
import { currencyFormatForNumber, percentageFormatter } from '../../utils/formatter';
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { projectActionTypes, useProjectContext } from "../../context/project.context";
import { getDefaultRateInputValues, getRateInputDataById, insertRateInputFormValues } from "../../utils/services/rateinputService";

const CalculationForm = ({ show, onClose, handleSubmitNewRateInputForm }) => {
  const [tableData, setTableData] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const { projectState, projectDispatch } = useProjectContext();
  const location = useLocation();
  const { cubitId } = useParams();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token')
  const costCentresWithPercent = ['Colnvest', 'Payroll Tax', 'Work Cover', 'Night Works', 'Set Margin'];
  const costCentresWithCurrency = ["Hourly Rate", "Incolink", "Super", "Overheads", "Scissor | 19ft", "Shop Steward", "Site Foreman", "Site Start Up", "Travel", "LAHA", "Site Allowance", "Airport Allowance", "Rate Multiplier", "Height/loading"]
  const userRole = sessionStorage.getItem("role");
  const { rateInputFormId } = useParams();

  // To get rate input data
  useEffect(() => {
    const formValues = async () => {
      try {
        setLoading(true);
        const rateInputFormIdForApi = (rateInputFormId || projectState.rateInputFormId);
        if (rateInputFormIdForApi) {
          const response = await getRateInputDataById(token, rateInputFormIdForApi);
          const dataToSet = response.Data.rateInputValues;
          setTableData(dataToSet);
          setLoading(false);
          projectDispatch({
            type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
            payload: response.Data._id
          });
        } else {
          // eslint-disable-next-line no-throw-literal
          throw {
            message: "Rate input form id does not exist"
          }
        }

      } catch (error) {
        console.error("Error fetching form data:", error);
        setLoading(false);
        const defaultResponse = await getDefaultRateInputValues(token);
        setTableData(defaultResponse);
      }
    };
    if (projectState.currentCubitId || cubitId || rateInputFormId) {
      formValues();
    } else {
      getDefaultData();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectState.currentCubitId, cubitId, projectState.currentProjectId, token]);

  // To get default Rate input values
  const getDefaultData = async () => {
    try {
      setLoading(true);
      const response = await getDefaultRateInputValues(token,);
      setTableData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching default data:', error);
      if (error.response) {
        console.log('Error response:', error.response);
      }
    }
  };

  // To hide scrollbar
  useEffect(() => {
    const body = document.querySelector('body');
    if (show) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
    return () => {
      body.style.overflow = 'auto';
    };
  }, [show]);

  // dropdown options
  const dropdownOptions = useMemo(
    () => [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ], []
  );

  if (!show) {
    return null;
  }

  // Handle change for input fields
  const handleFormValue = (event, data) => {
    try {
      setFormChanged(true);
      const field = event.target.name;
      const newValue = event.target.value;
      let updatedValue = newValue.replace(/[^0-9.]/g, '');
      let updatedPercentageValue = data.Percentage;
      let updatedActualRateValue = data.ActualRate;
      let updatedRateValue = data.Rate;
      let selectedCostCenter = data.CostCentre;
      let numericalRateValue = parseFloat(data.Rate.replace(/[^0-9.]/g, ''));
      let numericalPercentageValue = parseFloat(data.Percentage.replace(/[^0-9.]/g, ''));

      if (isNaN(updatedValue)) {
        return;
      }

      if (event.nativeEvent.inputType === "deleteContentBackward") {
        if (field === "Percentage") {
          if (!numericalRateValue) {
            Toast.fire({
              icon: 'error',
              title: 'Default Rate is required in order to enter actual rate.'
            })
            return;
          }
          updatedValue = updatedValue.slice(0, -1);
          updatedPercentageValue = updatedValue ? (updatedValue.endsWith('%') ? updatedValue : percentageFormatter(updatedValue)) : "";
          let cleanedRate = (data.Rate.replace(/[^0-9.]/g, ''));

          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? currencyFormatForNumber(cleanedRate * (updatedValue / 100)) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? percentageFormatter(cleanedRate * (updatedValue / 100)) : "";
          } else {
            updatedActualRateValue = updatedValue ? (cleanedRate * (updatedValue / 100)) : "";
          }
        } else if (field === "ActualRate") {
          if (!numericalRateValue) {
            Toast.fire({
              icon: 'error',
              title: 'Default Rate is required in order to enter actual rate.'
            })
            return;
          }
          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? currencyFormatForNumber(updatedValue) : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedValue = updatedValue.slice(0, -1);
            updatedActualRateValue = updatedValue ? percentageFormatter(updatedValue) : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          } else {
            updatedActualRateValue = updatedValue ? updatedValue : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          }
        } else if (field === "Rate") {
          let cleanedRate = (updatedValue.replace(/[^0-9.]/g, ''))
          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedRateValue = updatedValue ? currencyFormatForNumber(updatedValue) : "";
            updatedActualRateValue = numericalPercentageValue ? currencyFormatForNumber(cleanedRate * (numericalPercentageValue / 100)) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedValue = updatedValue.slice(0, -1);
            updatedRateValue = updatedValue ? percentageFormatter(updatedValue) : "";
            updatedActualRateValue = numericalPercentageValue ? percentageFormatter(cleanedRate * (numericalPercentageValue / 100)) : "";
          } else {
            updatedRateValue = updatedValue ? updatedValue : "";
            updatedActualRateValue = (numericalPercentageValue && (cleanedRate * (numericalPercentageValue / 100))) ? (cleanedRate * (numericalPercentageValue / 100)) : "";
          }
        }
      } else {
        if (field === "Percentage") {
          if (!numericalRateValue) {
            Toast.fire({
              icon: 'error',
              title: 'Default Rate is required in order to enter actual rate.'
            })
            return;
          }
          updatedPercentageValue = percentageFormatter(updatedValue);
          let cleanedRate = (data.Rate.replace(/[^0-9.]/g, ''));
          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? currencyFormatForNumber(cleanedRate * (updatedValue / 100)) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? percentageFormatter(cleanedRate * (updatedValue / 100)) : "";
          } else {
            updatedActualRateValue = updatedValue ? (cleanedRate * (updatedValue / 100)) : "";
          }
        } else if (field === "ActualRate") {
          if (!numericalRateValue) {
            Toast.fire({
              icon: 'error',
              title: 'Default Rate is required in order to enter actual rate.'
            })
            return;
          }
          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? currencyFormatForNumber(updatedValue) : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedActualRateValue = updatedValue ? percentageFormatter(updatedValue) : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          } else {
            updatedActualRateValue = updatedValue ? updatedValue : "";
            updatedPercentageValue = updatedValue ? percentageFormatter(updatedValue * 100 / numericalRateValue) : "";
          }
        } else if (field === "Rate") {
          let cleanedRate = (updatedValue.replace(/[^0-9.]/g, ''));
          if (costCentresWithCurrency.includes(selectedCostCenter)) {
            updatedRateValue = updatedValue ? currencyFormatForNumber(updatedValue) : "";
            updatedActualRateValue = numericalPercentageValue ? currencyFormatForNumber(cleanedRate * (numericalPercentageValue / 100)) : "";
          } else if (costCentresWithPercent.includes(selectedCostCenter)) {
            updatedRateValue = updatedValue ? percentageFormatter(updatedValue) : "";
            updatedActualRateValue = numericalPercentageValue ? percentageFormatter(cleanedRate * (numericalPercentageValue / 100)) : "";
          } else {
            updatedRateValue = updatedValue ? updatedValue : "";
            updatedActualRateValue = numericalPercentageValue ? (cleanedRate * (numericalPercentageValue / 100)) : "";
          }
        }
      }

      data.Rate = updatedRateValue;
      data.Percentage = updatedPercentageValue;
      data.ActualRate = updatedActualRateValue;
      setTableData(prevData => prevData.map(item => item.CostCentre === data.CostCentre ? { ...data } : item));
    } catch (err) {
      console.log("error", err);
    }
  };

  // Save function
  const onSaveClick = async () => {
    if (!(projectState.currentCubitId || cubitId)) {
      Toast.fire({
        icon: 'error',
        title: 'Could not find cubit id. Please retry.'
      })
      navigate('/projects');
      return;
    }
    const isRequiredNotAvailable = tableData.find(item => !item.Required);

    if (isRequiredNotAvailable) {
      Swal.fire({
        icon: 'error',
        title: 'Required Value Missing',
        text: `Required value is not selected for ${isRequiredNotAvailable.CostCentre}. Please selected required value and try again.`,
        confirmButtonColor: "#100c08",
      })
      return;
    }
    const invalidEntry = tableData.find(item => !item.ActualRate);
    if (invalidEntry) {
      Swal.fire({
        icon: 'error',
        title: 'Actual Rate Missing',
        text: `Actual rate is not entered for ${invalidEntry.CostCentre}. Please enter the actual rate and try again.`,
        confirmButtonColor: "#100c08",
      });
      return;
    }
    if (!formChanged) {
      const confirmationResult = await Swal.fire({
        icon: "question",
        title: "Are you sure?",
        text: "No changes detected in rate input form. Are you sure you want to submit the default values?",
        confirmButtonColor: "#100c08",
        showCancelButton: true,
      });

      if (!confirmationResult.isConfirmed) {
        return;
      }
    }
    try {
      setLoading(true);
      const projectId = projectState.currentProjectId || projectState.projectId;
      const response = await insertRateInputFormValues(projectId, (projectState.currentCubitId || cubitId), tableData);
      projectDispatch({
        type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
        payload: response.data.updatedData._id
      });
      projectDispatch({ type: projectActionTypes.SUBMIT_FORM });

      if (location?.pathname?.startsWith('/calculationpage')) {
        onClose()
        handleSubmitNewRateInputForm(response.data.updatedData._id)
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Rate Input Form Data saved successfully.',
          confirmButtonColor: "#100c08",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error saving data:", error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred while saving the data. Please try again later.',
      });
    }
  };

  // Handle dropdown change
  const dropDownOnChange = (e, rowData) => {
    let updatedValue = e.target.value;
    setFormChanged(true);
    setTableData(prevData =>
      prevData.map(row =>
        row._id === rowData._id ? { ...row, Required: updatedValue } : row
      )
    );
  }

  //Dropdown Rendering
  const DropdownRenderer = ({ value, options, rowData }) => {
    const isDisabled = location?.pathname?.startsWith("/cubit-version-data");
    return (
      <select
        value={value}
        onChange={(e) => dropDownOnChange(e, rowData)}
        className="select-dropdown"
        disabled={isDisabled}
        style={{
          backgroundColor: (location?.pathname?.startsWith("/cubit-version-data")) ? '#FADBD8' : '#E8E8E8', color: '#000000', border: `1px solid ${!value ? "#e85d50" : "#4ca64c"}`, borderRadius: '3px', height: '30px', marginTop: '5px', marginBottom: '5px'
        }}
      >
        <option key="default" value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  // To render table
  const renderTableRows = () => {
    return tableData?.map((row, index) => (
      <tr key={index}>
        <td>
          {row.CostCentre}
        </td>
        <td>
          <input
            type='text'
            value={row.Rate}
            onChange={(e) => handleFormValue(e, row)}
            name="Rate"
            disabled={location?.pathname?.startsWith("/cubit-version-data") || userRole === ROLE.USER}
            style={{ backgroundColor: (location?.pathname?.startsWith("/cubit-version-data") || userRole === ROLE.USER) ? '#FADBD8' : '#E8E8E8', color: '#000000', border: `1px solid ${!row.Rate ? "#e85d50" : "#4ca64c"}`, borderRadius: '3px', height: '30px', marginTop: '5px', marginBottom: '5px' }}
          />
        </td>
        <td>
          <input
            type='text'
            value={row.ActualRate}
            onChange={(e) => handleFormValue(e, row)}
            name="ActualRate"
            disabled={location?.pathname?.startsWith("/cubit-version-data")}
            style={{ backgroundColor: (location?.pathname?.startsWith("/cubit-version-data")) ? '#FADBD8' : '#E8E8E8', color: '#000000', border: `1px solid ${!row.ActualRate ? "#e85d50" : "#4ca64c"}`, borderRadius: '3px', height: '30px', marginTop: '5px', marginBottom: '5px' }}
          />
        </td>
        <td>
          <input
            type='text'
            value={row.Percentage}
            onChange={(e) => handleFormValue(e, row)}
            name="Percentage"
            disabled={location?.pathname?.startsWith("/cubit-version-data")}
            style={{ backgroundColor: (location?.pathname?.startsWith("/cubit-version-data")) ? '#FADBD8' : '#E8E8E8', color: '#000000', border: `1px solid ${!row.Percentage ? "#e85d50" : "#4ca64c"}`, borderRadius: '3px', height: '30px', marginTop: '5px', marginBottom: '5px' }}
          />
        </td>
        <td>
          <DropdownRenderer value={row.Required} rowData={row} options={dropdownOptions} />
        </td>
      </tr>
    ));
  };


  return (
    <>
      <div className="modal-content">
        <div className="modal-form">
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#3b3535', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px', marginBottom: '10px' }}>
            <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>Rate Input Form</h2>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
            </IconButton>
          </Box>
          <div className="table-container">
            <table className="responsive-table">
              <thead style={{ position: 'sticky', top: '-2px', zIndex: '1' }}>
                <tr>
                  <th>Cost Centre</th>
                  <th>Default rate</th>
                  <th>Actual rate</th>
                  <th>Percentage</th>
                  <th>Required</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
          {!location?.pathname?.startsWith("/cubit-version-data") && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button type="button" onClick={onSaveClick} className="rate-input-form-button">Submit</button>
            </div>
          )}
        </div>
      </div>
      {loading ?
        <>
          <div>
            <LoadingSpinner />
          </div>
        </>
        :
        <></>
      }
    </>
  );
};

CalculationForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CalculationForm;
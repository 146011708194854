import React, { useEffect, useState } from 'react';
import './Quote.css';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { generateQuote, getSimproDataForProject } from "../../utils/simproServices/quoteServices.js";

function Quote() {
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedProManagerSection, setSelectedProManagerSection] = useState(null);
    const [selectedProManagerCostCentre, setSelectedProManagerCostCentre] = useState(null);
    const [selectedProManagerLQuantity, setSelectedProManagerLQuantity] = useState(null);
    const [selectedProManagerPrebuildNumber, setSelectedProManagerPrebuildNumber] = useState(null);
    const [isQuoteDialogOpen, setQuoteDialogOpen] = useState(false);
    const [quoteName, setQuoteName] = useState('');
    const [loading, setLoading] = useState(false);
    const { calculationID } = useParams();
    const token = sessionStorage.getItem("token");
    const proManagerColumns = ["Order", "Price Comparison", "Job File No", "Job → Project Name", "Stage", "Building", "Zone/Area", "Level", "Plan Name", "Package", "Wall/Ceiling Types", "Notes", "Bill Ref", "Ceiling Height", "Description", "PrimarySecondaryMeasurement", "LQuantity", "MUnit", "PrebuildOneOffSet", "PrimarySecondary", "costCenterName", "costCenterSub", "SubGroup1", "SubGroup2", "commonName", "PreBuild No", "Rate_Material", "Rate_Wastage", "Rate_Sundries", "Rate_Delivery", "Rate_MarkUp", "Labour", "Hours", "Cost_Material", "Cost_Wastage", "Cost_Sundries", "Cost_Delivery", "Cost_MarkUp", "Cost_Material_Combine", "Cost_Labour_Std", "Total_Hours", "Hourly_Rate", "Incolink", "Super", "Colnvest", "Payroll_Tax", "Work_Cover", "Overheads", "Scissor", "Shop_Steward", "Site_Foreman", "Site_StartUp", "Travel", "Laha", "Site_Allowance", "Airport_Allowance", "Height_Loading", "Night_Works", "Downtime", "Cost_Labour", "Combined_Rate", "Sub_total", "Margin_Rate", "Sales_Price", "Margin_Amount", "GST", "Total_GST"];

    // To get the customer and site details
    useEffect(() => {
        fetchDataOnLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchDataOnLoad = async () => {
        try {
            setLoading(true);
            const simproDataResponse = await getSimproDataForProject(token, calculationID);
            if (simproDataResponse.status) {
                setSelectedCustomer(simproDataResponse?.data?.selectedCustomer)
                setSelectedSite(simproDataResponse?.data?.selectedSite)
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("Err", err);
        }
    }

    // If they refresh page the data will lost
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Your selected data will be lost. Are you sure you want to leave?';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Handle Generate Quote
    const handleGenerateQuote = () => {
        if (!selectedProManagerSection || !selectedProManagerCostCentre || !selectedProManagerLQuantity || !selectedProManagerPrebuildNumber) {
            Swal.fire({
                icon: 'warning',
                title: 'Please select all columns before generating the quote.',
                confirmButtonColor: "#100c08",
            });
            return;
        }
        if (!selectedSite || !selectedCustomer) {
            Swal.fire({
                icon: 'warning',
                title: 'Please select a site and a customer before generating quote.',
                confirmButtonColor: "#100c08",
            });
            return;
        }
        setQuoteDialogOpen(true);
    };
    const handleQuoteDialogClose = async () => {
        setQuoteDialogOpen(false);
        const quoteData = {
            calculationID,
            siteId: selectedSite?.ID,
            customerId: selectedCustomer?.ID,
            quoteName: quoteName,
            sectionName: selectedProManagerSection ? selectedProManagerSection.value : '',
            costCentre: selectedProManagerCostCentre ? selectedProManagerCostCentre.value : '',
            lQuantity: selectedProManagerLQuantity ? selectedProManagerLQuantity.value : '',
            prebuildNumber: selectedProManagerPrebuildNumber ? selectedProManagerPrebuildNumber.value : ''
        };
        try {
            setLoading(true);
            await generateQuote(quoteData)
            Swal.fire({
                icon: 'success',
                title: 'Quote Generated',
                text: 'Quote generated successfully',
                confirmButtonColor: "#100c08",
            })
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Quote Generation Failed',
                text: `${error?.response?.data?.error || 'Quote Generation Failed'}`,
                confirmButtonColor: "#100c08",
            });
        }
        setLoading(false);
    };

    // Customizing proManagerColumns to display "Cost Center" for "costCenterSub"
    const customizedProManagerColumns = proManagerColumns.map(column =>
        column === 'costCenterSub'
            ? { value: column, label: 'Cost Center' }
            : { value: column, label: column }
    );

    return (
        <>
            <div className="quote-container">
                <div className="columns-container">
                    <label htmlFor="customer" className='column-label'>Project Customer</label>
                    <label className='column-label'> {selectedCustomer?.CompanyName}</label>
                </div>
                <div className="columns-container" style={{ marginTop: '20px' }}>
                    <label htmlFor="site" className='column-label'>Project Site</label>
                    <label className='column-label'> {selectedSite?.Name}</label>
                </div>
            </div>
            <div className="related-columns">
                <label className="related-columns-label">Select Related Columns</label>
                <div className="columns-container">
                    <div className="column">
                        <h4>Simpro Columns</h4>
                        <Select placeholder="Section Name" isDisabled />
                        <Select placeholder="Cost Centre" isDisabled />
                        <Select placeholder="L Quantity" isDisabled />
                        <Select placeholder="Prebuild Number" isDisabled />
                    </div>
                    <div className="column">
                        <h4>ProManager Columns</h4>
                        <Select placeholder="Section Name" options={customizedProManagerColumns} onChange={setSelectedProManagerSection} value={selectedProManagerSection} />
                        <Select placeholder="Cost Centre" options={customizedProManagerColumns} onChange={setSelectedProManagerCostCentre} value={selectedProManagerCostCentre} />
                        <Select placeholder="L Quantity" options={customizedProManagerColumns} onChange={setSelectedProManagerLQuantity} value={selectedProManagerLQuantity} />
                        <Select placeholder="Prebuild Number" options={customizedProManagerColumns} onChange={setSelectedProManagerPrebuildNumber} value={selectedProManagerPrebuildNumber} />
                    </div>
                </div>
            </div>
            <div className='generate-quote'>
                <button className='generate-quote-btn' onClick={handleGenerateQuote}>Generate Quote</button>
            </div>
            <Dialog open={isQuoteDialogOpen} onClose={() => setQuoteDialogOpen(false)}>
                <DialogTitle>Enter Quote Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter a name for your quote.</DialogContentText>
                    <TextField autoFocus margin="dense" id="quoteName" label="Quote Name" type="text" fullWidth variant="standard" value={quoteName} onChange={(e) => setQuoteName(e.target.value)} sx={{ mb: 2 }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setQuoteDialogOpen(false)} sx={{ color: 'black' }}>Cancel</Button>
                    <Button onClick={handleQuoteDialogClose} sx={{ color: 'black' }}>Submit</Button>
                </DialogActions>
            </Dialog>
            <div>
                <div>{loading && <LoadingSpinner />}</div>
            </div>
        </>
    );
}

export default Quote;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserProvider } from "./context/user.context";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ProjectProvider } from "./context/project.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <UserProvider>
      <ProjectProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ProjectProvider>
    </UserProvider>
  </>
);

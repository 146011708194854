import React, { useState } from 'react';
import Swal from "sweetalert2";
import { Box, Button, Modal, TextField, Typography, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createSite } from "../../../utils/simproServices/siteServices";

// Styled components for box
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function SiteModal({ open, handleClose, disableBackdropClick }) {
    const [formData, setFormData] = useState({ Name: '', Zone: '', Address: '', City: '', State: '', postalcode: '', Country: 'Australia', });

    // Handle chnage for input fields
    const handleChange = (e) => {
        const { id, value } = e.target;
        const parsedValue = id === "Zone" ? parseInt(value) || '' : value;
        setFormData((prevData) => ({
            ...prevData,
            [id]: parsedValue,
        }));
    };

    // Handle submit logic
    const handleSubmit = async () => {
        const transformedData = {
            Name: formData.Name,
            Address: {
                Address: formData.Address,
                City: formData.City,
                State: formData.State,
                PostalCode: formData.postalcode,
                Country: formData.Country
            }
        };

        try {
            await createSite(transformedData);
            Swal.fire({
                icon: 'success',
                title: 'Site Created',
                text: 'Site Created Successfully',
                confirmButtonColor: "#100c08",
            });
            handleClose();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Site Created Failed',
                text: 'Failed to create site. Please try again later.',
                confirmButtonColor: "#100c08",
            });
            console.error('Error creating site:', error);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} disableBackdropClick={disableBackdropClick} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-10px' }}>
                    <Typography id="modal-title" variant="h6" component="h2">New Site</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', }}>
                    <TextField id="Name" label="Site Name" variant="outlined" fullWidth margin="normal" size='small' value={formData.Name} onChange={handleChange} required />
                    <TextField id="Zone" label="Zone" variant="outlined" fullWidth margin="normal" size='small' value={formData.Zone} onChange={handleChange} />
                </Box>
                <Typography variant="h6" component="h4" style={{ marginTop: '20px' }}>Location</Typography>

                <TextField id="Address" label="Street Address" variant="outlined" fullWidth margin="normal" size='small' value={formData.Address} onChange={handleChange} />
                <Box sx={{ display: 'flex', gap: '20px' }}>
                    <TextField id="City" label="Suburb" variant="outlined" fullWidth margin="normal" size='small' value={formData.City} onChange={handleChange} />
                    <FormControl fullWidth margin="normal" size='small'>
                        <InputLabel id="state-label">State</InputLabel>
                        <Select labelId="state-label" id="State" label="State" value={formData.State} onChange={(e) => setFormData({ ...formData, State: e.target.value })}>
                            <MenuItem value="NSW">New South Wales</MenuItem>
                            <MenuItem value="VIC">Victoria</MenuItem>
                            <MenuItem value="QLD">Queensland</MenuItem>
                            <MenuItem value="SA">South Australia</MenuItem>
                            <MenuItem value="WA">Western Australia</MenuItem>
                            <MenuItem value="TAS">Tasmania</MenuItem>
                            <MenuItem value="ACT">Australian Capital Territory</MenuItem>
                            <MenuItem value="NT">Northern Territory</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                    <TextField id="postalcode" label="Postcode" variant="outlined" fullWidth margin="normal" size='small' value={formData.postalcode} onChange={handleChange} />
                    <TextField id="Country" label="Country" variant="outlined" fullWidth margin="normal" defaultValue="Australia" size='small' value={formData.Country} onChange={handleChange} />
                </Box>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        background: '#3B3535',
                        color: '#C0C0C0',
                        backgroundSize: '200%',
                        marginTop: '20px',
                        '&:hover': {
                            background: '#808080',
                            color: 'black'
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    );
}

export default SiteModal;

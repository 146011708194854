import React, { useEffect, useState } from 'react'
import './CubitVersionData.scss';
import "../ProjectTable/ProjectTable.scss";
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Toast, downloadFile } from '../../utils/helper';
import { currencyFormatForNumber } from '../../utils/formatter';
import LoadingSpinner from '../../components/Loader/LoadingSpinner';
import { ROLE } from '../../utils/constant';
import { useUserContext } from '../../context/user.context';
import { useProjectContext, projectActionTypes } from '../../context/project.context'
import CalculationForm from "../../components/RateInputForm/CalculationForm";
import { fetchCubitDataForCubitVersion } from '../../utils/services/cubitService';
import { updateCalculationComment } from '../../utils/services/calculationService';

const CubitVersionData = () => {
    const [cubitDataForCubitVersion, setCubitDataForCubitVersion] = useState([]);
    const [showNewForm, setShowNewForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { projectState, projectDispatch } = useProjectContext();
    const { userState } = useUserContext();
    const { cubitId } = useParams();
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");

    // To get the information about cubit file
    useEffect(() => {
        if (cubitId) {
            projectDispatch({ type: projectActionTypes.UPDATE_CUBITID, payload: cubitId });
            handleCubitDataForCubitVersion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cubitId]);
    const handleCubitDataForCubitVersion = async () => {
        try {
            setIsLoading(true);
            const cubitDataResponse = await fetchCubitDataForCubitVersion(token, cubitId);
            if (cubitDataResponse.status) {
                setCubitDataForCubitVersion(cubitDataResponse.data);
            }
            projectDispatch({ type: 'UPDATE_CURRENT_PROJECTID', payload: cubitDataResponse.data[0].projectId });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log("Error while fetch the data", err)
        }
    }

    // handle to go to cubit version page
    const handleGoToCubitVersions = () => {
        const projectId = projectState.projectId
        navigate(`/projects/${projectId}`)
    }

    // Handle to cubit daa item i.e, download cubit file, view cubit information
    const handleCubitAction = (cubitDataItem) => {
        if (cubitDataItem.action === 'Download Cubit File') {
            if (!cubitDataItem.value.location) {
                Toast.fire({
                    icon: "error",
                    title: "Cubit file not found",
                })
            } else {
                downloadFile(`${cubitDataItem.value.location}`, cubitDataItem.value.originalname)
            }
        } else if (cubitDataItem.action === 'View Cubit Information') {
            if (!cubitDataItem.value) {
                Toast.fire({
                    icon: "error",
                    title: "Cubit information not found",
                })
            } else {
                navigate(`/cubit-information/${cubitId}`)
            }
        } else {
            Toast.fire({
                icon: "error",
                title: "Invalid action selected",
            })
        }

    }

    // Handle to cubit daa item i.e, if calculation is done then calculation page, raeinput form
    const handleCalculationAction = (calculationItem, type) => {
        if (type === 'calculation') {
            if (!calculationItem._id) {
                Toast.fire({
                    icon: "error",
                    title: "Calculation data not found",
                })
            } else if (!calculationItem.rateInputFormId._id) {
                Toast.fire({
                    icon: "error",
                    title: "No rate input form found for this calculation. Please check with IT team.",
                })
            } else {
                projectDispatch({
                    type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
                    payload: calculationItem.rateInputFormId._id
                });
                navigate(`/calculationpage/${calculationItem.rateInputFormId._id}`)
            }
        } else if (type === 'rateInputForm') {
            setShowNewForm(true);
            projectDispatch({
                type: projectActionTypes.UPDATE_RATE_INPUT_FORMID,
                payload: calculationItem.rateInputFormId._id
            });
        } else {
            Toast.fire({
                icon: "error",
                title: "Invalid action selected",
            })
        }
    }

    // Handle to Close Rate Input Form
    const handleCloseForm = () => {
        setShowNewForm(false);
    };

    // To add comments when calculation is done
    const handleCalculationCommentUpdate = async (e, calculationItem) => {
        try {
            let newValue = e.target.value;
            let localCalculationData = cubitDataForCubitVersion.filter(item => item.name === "Calculation Data");
            if (localCalculationData?.length) {
                let calculationIndex = localCalculationData[0]?.value?.findIndex(item => item._id === calculationItem._id);
                if (calculationIndex !== -1) {
                    localCalculationData[0].value[calculationIndex].calculationComment = newValue;
                    console.log(localCalculationData[0])
                    let updatedCubitVersionData = cubitDataForCubitVersion.map(item => {
                        if (item.name === "Calculation Data") {
                            return localCalculationData[0];
                        } else {
                            return item;
                        }
                    })
                    setCubitDataForCubitVersion([...updatedCubitVersionData]);
                }
            }
            const data = {
                calculationComment: newValue,
                calculationId: calculationItem._id
            };
            await updateCalculationComment(token, data);
        } catch (err) {
            console.log("error: " + err);
        }
    };

    return (
        <div>
            {isLoading ? (
                <div>
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    <div
                        style={{ margin: "10px", display: "flex", justifyContent: "space-between", }}>
                        <span>
                            <button className="custom_file_input" style={{ marginLeft: '0px' }} onClick={handleGoToCubitVersions}>Go To Cubit Versions</button>
                        </span>
                    </div>
                    <div className="project-header">
                        <h2>Cubit Version Data: </h2>
                    </div>
                    <div className="project-container">
                        <div className="project-table-data">
                            <div className="header">
                                <div className="column" style={{ maxWidth: '50px' }}></div>
                                <div className="column" style={{ maxWidth: '450px' }}>Cubit Data Item </div>
                                <div className="column" style={{ display: 'flex', flexWrap: 'wrap', flex: 2, alignItems: 'center', justifyContent: 'center' }}>Action </div>
                            </div>
                            <div className="body">
                                {cubitDataForCubitVersion.map((cubitDataItem, index) => (
                                    <span key={index}>
                                        {cubitDataItem?.name !== 'Calculation Data' ?
                                            <div className="row" key={index} >
                                                <div className="column" style={{ maxWidth: '50px' }}>
                                                    <FontAwesomeIcon icon={index === 0 ? faFileExcel : faFileLines} style={{ width: '18px', height: '18px' }} />
                                                </div>
                                                <div className="column" style={{ maxWidth: '450px' }}>{cubitDataItem.name} </div>
                                                <div className="column" style={{ display: 'flex', flexWrap: 'wrap', flex: 2, justifyContent: 'left' }}>
                                                    <button onClick={() => handleCubitAction(cubitDataItem)} style={{ padding: '8px 0px', color: '#c0c0c0' }}>{cubitDataItem.action}</button>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {cubitDataItem.value.map((calculationItem, idx) =>
                                                    <>
                                                        <div className="row" key={idx}>
                                                            <div className="column" style={{ maxWidth: '50px' }}>
                                                                <FontAwesomeIcon icon={index === 0 ? faFileExcel : faFileLines} style={{ width: '18px', height: '18px' }} />
                                                            </div>
                                                            <div className="column" style={{ maxWidth: '450px' }}>{`V  ${calculationItem?.cubitId?.version}.${calculationItem?.version} ${cubitDataItem.name}`} </div>
                                                            <div className="column" style={{ display: 'flex', flex: 2, justifyContent: 'left', alignItems: 'center' }}>
                                                                <button onClick={() => handleCalculationAction(calculationItem, 'calculation')} style={{ padding: '8px 0px', color: '#c0c0c0', minWidth: '200px' }}>{cubitDataItem.action}</button>
                                                                <button style={{ marginLeft: '10px', padding: '8px 0px', color: '#c0c0c0', minWidth: '200px' }} onClick={() => handleCalculationAction(calculationItem, 'rateInputForm', idx)}>View Rate Input</button>
                                                                <button style={{ marginLeft: '10px', backgroundColor: 'lightgray', borderRadius: '2px', padding: '9px 8px', minWidth: '250px', color: 'black', fontSize: '12px', }}>Sales Price Ex GST :  {currencyFormatForNumber(calculationItem.totalSalesPrice)}</button>
                                                                <div className="input-wrapper">
                                                                    <input type="text" placeholder="Enter comment"
                                                                        value={calculationItem?.calculationComment}
                                                                        onChange={(e) => {
                                                                            setIsEditing(true)
                                                                            handleCalculationCommentUpdate(e, calculationItem);
                                                                        }}
                                                                        maxLength={50}
                                                                        disabled={
                                                                            userState?.user?.role === ROLE.SUPER_ADMIN
                                                                                ? false
                                                                                : calculationItem?.calculationComment?.length === 0
                                                                                    ? false
                                                                                    : calculationItem?.calculationComment?.length > 0 && isEditing
                                                                                        ? false
                                                                                        : true
                                                                        }
                                                                        className="input-field"
                                                                    />
                                                                    <span className="input-length">
                                                                        {calculationItem?.calculationComment?.length} / 50
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                }
                                            </>
                                        }
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    {showNewForm && (
                        <div className="overlay">
                            <CalculationForm show={showNewForm} onClose={handleCloseForm} />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default CubitVersionData
import { Navigate, Outlet } from "react-router-dom";
import {jwtDecode} from 'jwt-decode';

const PrivateRoute = () => {
  const token = sessionStorage.getItem("token");
  const userEmail = sessionStorage.getItem("email");
  let auth;

  if ((token === null || token === "") && (userEmail == null || userEmail === "" || userEmail === false)) {
    auth = false;
  } else if (userEmail && token !== null) {
    const decodedToken = jwtDecode(token);
    if (!decodedToken) {
      auth = false;
    } else {
      auth = true;
    }
  } else if (userEmail && (token == null || token === "")) {
    auth = false;
  } else {
    auth = false;
  }

  return auth ? (
    <span className="wrapper">
      <Outlet />
    </span>
  ) : (
    <Navigate to="/" replace={true} />
  );
};

export default PrivateRoute;

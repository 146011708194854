import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Generate Quote
export const generateQuote = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${backEndUrl}/api/simpro/quotes/generate-quote`, data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To get simpro data related to project using calculation id from project
export const getSimproDataForProject = (token, calculationId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${backEndUrl}/api/simpro/quotes/simpro-data/${calculationId}`, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
import React, { useEffect, useState } from 'react'
import './CreateOneoff.css'
import { useParams } from 'react-router-dom'
import { Box, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { currencyFormatForNumber } from '../../../utils/formatter';
import { insertOneOffItem, getOffPbValue, existingDataCheckOneoffItem, getUniqueOneOffItem } from "../../../utils/services/oneoffitemService";

function CreateOneoff({ show, onClose }) {
    const [formData, setFormData] = useState({ CostCentre1: "", CostCentre2: "", SubGroup1: "", SubGroup2: "", CommonName: "", PB: "", UOM: "", Labour: "", Hours: "", Rate_Material: "", Rate_Wastage: "", Rate_Sundries: "", Rate_Delivery: "", Rate_MarkUp: "" });
    const [loading, setLoading] = useState({ pb: false, CostCentre1: false, CostCentre2: false, SubGroup1: false, SubGroup2: false, CommonName: false, submit: false, newOneOffItem: false });
    const [jobCostCentres, setJobCostCentres] = useState([]);
    const [selectedJobCostCentre, setSelectedJobCostCentre] = useState("");
    const [costCentre2Options, setCostCentre2Options] = useState([]);
    const [subGroup1Options, setSubGroup1Options] = useState([]);
    const [subGroup2Options, setSubGroup2Options] = useState([]);
    const [commonNameOptions, setCommonNameOptions] = useState([]);
    const { projectId } = useParams();
    const token = sessionStorage.getItem('token');
    const initialFormData = { CostCentre1: "", CostCentre2: "", SubGroup1: "", SubGroup2: "", PB: "", CommonName: "", UOM: "", Labour: "", Hours: "", Rate_Material: "", Rate_Wastage: "", Rate_Sundries: "", Rate_Delivery: "", Rate_MarkUp: "", Concat1: '', Concat2: '', NameWithHeights: '', QuoteName: '', LabourGroup1: '', LabourGroup2: '', simProPriceUpload: '', Onsite: '', ContactSubGroup: '' };
    let numericalField = ["Labour", "Rate_Material", "Hours", "Rate_Wastage", "Rate_Sundries", "Rate_Delivery", "Rate_MarkUp"]

    // To fetch the PB value
    const fetchPBNumber = async () => {
        try {
            setLoading((prevLoading) => ({ ...prevLoading, pb: true }));
            const ofnValueResponse = await getOffPbValue(token, projectId);
            const pBNumberResponse = ofnValueResponse.pbValue;
            if (pBNumberResponse) {
                const nextPBNumber = incrementPBNumber(pBNumberResponse);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    PB: nextPBNumber,
                }));
            }
        } catch (error) {
            console.error("Error fetching latest PB:", error);
        } finally {
            setLoading((prevLoading) => ({ ...prevLoading, pb: false }));
        }
    };

    useEffect(() => {
        const body = document.querySelector("body");
        if (show) {
            body.style.overflow = "hidden";
            fetchPBNumber();
        } else {
            body.style.overflow = "auto";
        }
        return () => {
            body.style.overflow = "auto";
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    // To get cost centre1 values
    useEffect(() => {
        const fetchJobCostCentres = async () => {
            try {
                setLoading(prevLoading => ({ ...prevLoading, CostCentre1: true }));
                const oneOffUrl = `/unique-oneoff/${projectId}?unique=CostCentre1`;
                const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
                const oneOffData = uniqueOneOffItemResponse.uniqueData;
                const uniqueData = new Set([...oneOffData,])
                const finalResult = [...uniqueData]
                setJobCostCentres(finalResult);
            } catch (error) {
                console.error("Error fetching job cost centres:", error);
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, CostCentre1: false }));
            }
        };
        if (projectId && token) {
            fetchJobCostCentres();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, token]);

    // To get Costcentre2 values
    useEffect(() => {
        if (loading.pb || !formData.CostCentre1) {
            return;
        }
        const fetchCostCentre2Options = async () => {
            try {
                setLoading(prevLoading => ({ ...prevLoading, CostCentre2: true }));
                const oneOffUrl = `/unique-oneoff/${projectId}?unique=CostCentre2&CostCentre1=${formData.CostCentre1}`;
                const oneOffResponse = await getUniqueOneOffItem(token, oneOffUrl);
                const oneOffData = oneOffResponse.uniqueData;
                const uniqueData = new Set([...oneOffData])
                const finalResult = [...uniqueData]
                setCostCentre2Options(finalResult);
            } catch (error) {
                console.error("Error fetching CostCentre2 options:", error);
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, CostCentre2: false }));
            }
        };
        if (formData.CostCentre1 && token && projectId) {
            fetchCostCentre2Options();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.CostCentre1, token, projectId, loading.pb]);

    // To get Sub Group1 values
    useEffect(() => {
        if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2) {
            return;
        }
        const fetchSubGroup1Options = async () => {
            try {
                setLoading(prevLoading => ({ ...prevLoading, SubGroup1: true }));
                const oneOffUrl = `/unique-oneoff/${projectId}?unique=SubGroup1&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}`;
                const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
                const oneOffData = uniqueOneOffItemResponse.uniqueData;
                const uniqueData = new Set([...oneOffData])
                const finalResult = [...uniqueData]
                setSubGroup1Options(finalResult);
            } catch (error) {
                console.error("Error fetching SubGroup1 options:", error);
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, SubGroup1: false }));
            }
        };
        if (formData.CostCentre1 && formData.CostCentre2 && token && projectId) {
            fetchSubGroup1Options();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.CostCentre1, formData.CostCentre2, token, projectId]);

    // To get Sub Group2 values
    useEffect(() => {
        if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2 || !formData.SubGroup1) {
            return;
        }
        const fetchSubGroup2Options = async () => {
            try {
                setLoading(prevLoading => ({ ...prevLoading, SubGroup2: true }));
                const oneOffUrl = `/unique-oneoff/${projectId}?unique=SubGroup2&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}`;
                const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
                const oneOffData = uniqueOneOffItemResponse.uniqueData;
                const uniqueData = new Set([...oneOffData])
                const finalResult = [...uniqueData]
                setSubGroup2Options(finalResult);
            } catch (error) {
                console.error("Error fetching SubGroup2 options:", error);
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, SubGroup2: false }));
            }
        };
        if (formData.CostCentre1 && formData.CostCentre2 && formData.SubGroup1 && token && projectId) {
            fetchSubGroup2Options();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.CostCentre1, formData.CostCentre2, formData.SubGroup1, token, projectId]);

    //  To get Common Name values
    useEffect(() => {
        if (loading.pb || !formData.CostCentre1 || !formData.CostCentre2 || !formData.SubGroup1 || !formData.SubGroup2) {
            return;
        }
        const fetchCommonNameOptions = async () => {
            try {
                setLoading(prevLoading => ({ ...prevLoading, CommonName: true }));
                const oneOffUrl = `/unique-oneoff/${projectId}?unique=CommonName&CostCentre1=${formData.CostCentre1}&CostCentre2=${formData.CostCentre2}&SubGroup1=${formData.SubGroup1}&SubGroup2=${formData.SubGroup2}`;
                const uniqueOneOffItemResponse = await getUniqueOneOffItem(token, oneOffUrl);
                const oneOffData = uniqueOneOffItemResponse.uniqueData;

                const uniqueData = new Set([...oneOffData])
                const finalResult = [...uniqueData]
                setCommonNameOptions(finalResult);
            } catch (error) {
                console.error("Error fetching Common Name options:", error);
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, CommonName: false }));
            }
        };
        if (formData.CostCentre1 && formData.CostCentre2 && formData.SubGroup1 && formData.SubGroup2 && token && projectId) {
            fetchCommonNameOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.CostCentre1, formData.CostCentre2, formData.SubGroup1, formData.SubGroup2, formData.selectedOption, token, projectId]);

    // Function for increment PB value
    function incrementPBNumber(pbNumber) {
        const lastNumber = parseInt(pbNumber.substring(3), 10);
        const nextNumber = lastNumber + 1;
        return `OFN${nextNumber}`;
    }

    // Handle input chnage for input fields
    const handleInputChange = (e) => {
        let { name, value } = e.target;
        let percentageValues = ["Rate_Wastage", "Rate_MarkUp"];
        let currencyValues = ["simProPriceUpload", "Rate_Sundries", "Rate_Delivery", "Labour", "Rate_Material"]
        if (numericalField.includes(name)) {
            if (e.nativeEvent.inputType === "deleteContentBackward") {
                if (percentageValues.includes(name)) {
                    const updatedValue = value.slice(0, -1);
                    let finalValue = updatedValue;
                    finalValue = updatedValue.endsWith("%") ? updatedValue : updatedValue + "%";
                    if (!updatedValue) {
                        finalValue = ""
                    }
                    console.log('filenale', finalValue);
                    setFormData({ ...formData, [name]: finalValue });
                    return;
                }
            }
            const cleanedValue = value.replace(/[$%,]/g, '');
            if (isNaN(cleanedValue)) {
                return;
            }
            let updatedValue = cleanedValue;

            if (percentageValues.includes(name)) {
                if (cleanedValue && !cleanedValue.includes('%')) {
                    updatedValue = cleanedValue + '%';
                } else {
                    updatedValue = 0 + "%";
                }
            } else if (currencyValues.includes(name)) {
                if (cleanedValue && !cleanedValue.includes('$')) {
                    updatedValue = currencyFormatForNumber(cleanedValue)
                }
            } else {
                updatedValue = cleanedValue
            }

            setFormData({ ...formData, [name]: updatedValue });
        } else {
            setFormData({ ...formData, [name]: value, });
        }
    };

    // Handle submit
    const handleSubmit = async () => {
        setLoading(true);
        const requiredFields = ['CostCentre1', 'CostCentre2', 'SubGroup1', 'SubGroup2', 'CommonName', 'Labour', 'Hours', 'Rate_Material', 'PB'];
        const missingFields = requiredFields.filter(field => !formData[field]);
        if (missingFields.length > 0) {
            const errorMessage = missingFields.map(field => `Please enter ${field}`).join(', ');
            Swal.fire({
                title: "Error",
                text: errorMessage,
                icon: "error",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
            setLoading(false);
            return;
        }
        if (formData.Hours < 0) {
            Swal.fire({
                title: "Error",
                text: "Hours should be greater than 0.",
                icon: "error",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
            setLoading(false);
            return;
        }
        try {
            const newData = {
                CostCentre1: formData.CostCentre1.trim(),
                CostCentre2: formData.CostCentre2.trim(),
                SubGroup1: formData.SubGroup1.trim(),
                SubGroup2: formData.SubGroup2.trim(),
                CommonName: formData.CommonName.trim()
            }
            const response = await existingDataCheckOneoffItem(token, projectId, newData)
            if (response.exists) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "This Data already exists. Please enter a different one.",
                    confirmButtonColor: "#100c08",
                    customClass: {
                        container: 'swal-container',
                    }
                });
                setLoading(false);
                return;
            }
        } catch (error) {
            console.error("Error checking CommonName:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error checking Common Name. Please try again.",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
            setLoading(false);
            return;
        }
        try {
            let formattedFormData = { ...formData };
            for (let key in formattedFormData) {
                if (typeof formattedFormData[key] === "string" && (formattedFormData[key].startsWith("$") || formattedFormData[key].endsWith("%"))) {
                    formattedFormData[key] = formattedFormData[key].replace(/[$%,]/g, "");
                }
            }
            const oneOffItems = { ...formattedFormData, projectId };
            await insertOneOffItem(token, oneOffItems);
            Swal.fire({
                title: "Updated Newly Added Data!",
                text: "Newly added data has been updated successfully.",
                icon: "success",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
            setFormData(initialFormData);
            fetchPBNumber();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error updating newly added data:", error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while updating newly added data.",
                icon: "error",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }

    // Handle to submit another data
    const handleAddAnotherData = () => {
        setFormData(initialFormData);
        fetchPBNumber();
    };

    return (
        <div style={{ zIndex: '9999' }}>
            <div className="modal-contents">
                <form className="create-form" >
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#3b3535', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
                        <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>Create New Oneoff</h2>
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
                        </IconButton>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '10px 0' }}>
                        <Button onClick={handleAddAnotherData} variant="outlined" sx={{ color: '#808080', border: '1px solid #3b3535' }}>Add Another Data</Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary" style={{ background: '#3b3535', color: 'c0c0c0' }}>Submit</Button>
                    </div>
                    <div className="form-divs">
                        <div className="subDiv">
                            <label style={{ color: !formData.CostCentre1 && "red" }}>Job Cost Centre Name
                                <input type="text" list="jobCostCentres" placeholder="Select or Enter Job Cost Centre" value={selectedJobCostCentre} onChange={(e) => setSelectedJobCostCentre(e.target.value)} onBlur={(e) => setFormData({ ...formData, CostCentre1: e.target.value })} />
                                <datalist id="jobCostCentres">
                                    {jobCostCentres.map((costCentre, index) => (
                                        <option key={index} value={costCentre}>
                                            {costCentre}
                                        </option>
                                    ))}
                                </datalist>
                            </label>
                            <label style={{ color: !formData.SubGroup2 && "red" }}>SubGroup2
                                <input type="text" list="subGroup2Options" placeholder="Select or Enter SubGroup 2" value={formData.SubGroup2} onChange={(e) => setFormData({ ...formData, SubGroup2: e.target.value })} onBlur={(e) => setFormData({ ...formData, SubGroup2: e.target.value })} />
                                <datalist id="subGroup2Options">
                                    {subGroup2Options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </datalist>
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.CostCentre2 && "red" }}>Cost Centre
                                <input type="text" list="costCentre2Options" placeholder="Select or Enter Cost Centre " value={formData.CostCentre2} onChange={(e) => setFormData({ ...formData, CostCentre2: e.target.value })} onBlur={(e) => setFormData({ ...formData, CostCentre2: e.target.value })} />
                                <datalist id="costCentre2Options">
                                    {costCentre2Options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </datalist>
                            </label>
                            <label style={{ color: !formData.CommonName && "red" }}>Prebuild(list)
                                <input type="text" list="commonNameOptions" placeholder="Select or Enter Common Name" value={formData.CommonName} onChange={(e) => setFormData({ ...formData, CommonName: e.target.value })} onBlur={(e) => setFormData({ ...formData, CommonName: e.target.value })} />
                                <datalist id="commonNameOptions">
                                    {commonNameOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </datalist>
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.SubGroup1 && "red" }}>SubGroup1
                                <input type="text" list="subGroup1Options" placeholder="Select or Enter SubGroup 1" value={formData.SubGroup1} onChange={(e) => setFormData({ ...formData, SubGroup1: e.target.value })} onBlur={(e) => setFormData({ ...formData, SubGroup1: e.target.value })} />
                                <datalist id="subGroup1Options">
                                    {subGroup1Options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </datalist>
                            </label>
                            <label>PB No.
                                <input type="text" name="PB" placeholder="PB No." value={formData.PB} onChange={handleInputChange} readOnly />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label>U.O.M
                                <input type="text" name="UOM" placeholder="Enter U.O.M" value={formData.UOM} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.Labour && "red" }}>Rate Labour
                                <input type="text" name="Labour" placeholder="Enter Rate Labour" value={formData.Labour} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.Hours && "red" }}>Hours
                                <input type="text" name="Hours" placeholder="Enter Hours" value={formData.Hours} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.Rate_Material && "red" }}>Rate Material
                                <input type="text" name="Rate_Material" placeholder="Enter Rate Material" value={formData.Rate_Material} onChange={handleInputChange} />
                            </label>
                            <label>Rate Wastage
                                <input type="text" name="Rate_Wastage" placeholder="Enter Rate Wastage" value={formData.Rate_Wastage} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label>Rate Sundries
                                <input type="text" name="Rate_Sundries" placeholder="Enter Rate Sundries" value={formData.Rate_Sundries} onChange={handleInputChange} />
                            </label>
                            <label>Rate Delivery
                                <input type="text" name="Rate_Delivery" placeholder="Enter Rate Delivery" value={formData.Rate_Delivery} onChange={handleInputChange} />
                            </label>
                            <label>Rate MarkUp
                                <input type="text" name="Rate_MarkUp" placeholder="Enter Rate MarkUp" value={formData.Rate_MarkUp} onChange={handleInputChange} />
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                {(loading.pb || loading.CostCentre1 || loading.CostCentre2 || loading.SubGroup1 || loading.SubGroup2 || loading.CommonName || loading.submit) ?
                    <LoadingSpinner />
                    : null}
            </div>
        </div>
    )
}

export default CreateOneoff

import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faFolder, faTrashCan, faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "../ProjectTable/ProjectTable.scss";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { createCubitData, getCubitDataForProject, deleteCubit, createNewCubitVersion } from "../../utils/services/cubitService";
import { useUserContext } from "../../context/user.context";
import { useProjectContext, projectActionTypes, } from "../../context/project.context";
import { ROLE } from "../../utils/constant";
import { TableRow } from '@mui/material';

const ProjectItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCubitFile, setSelectedCubitFile] = useState(null);
  const [cubitDataForProject, setCubitDataForProject] = useState([]);
  const { userState } = useUserContext();
  // eslint-disable-next-line no-unused-vars
  const { projectState, projectDispatch } = useProjectContext();
  const token = sessionStorage.getItem("token");
  const userRole = sessionStorage.getItem("role");
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectId) {
      navigate("/projects");
      Swal.fire({
        icon: "error",
        title: "Project Id not found",
        confirmButtonColor: "#100c08",
      });
    } else {
      projectDispatch({
        type: projectActionTypes.UPDATE_PROJECTID,
        payload: projectId,
      });
      fetchCubitDataForProject(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const fetchCubitDataForProject = async (projectId) => {
    try {
      setIsLoading(true);
      const fetchedCubitResponse = await getCubitDataForProject(token, projectId);
      if (fetchedCubitResponse.status) {
        setCubitDataForProject(fetchedCubitResponse.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("Error", err);
    }
  };

  const handleCubitFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedCubitFile(file);
  };

  const handleUploadCubitFile = async () => {
    if (selectedCubitFile) {
      const formData = new FormData();
      formData.append("createdBy", userState.user._id);
      formData.append("projectId", projectId);
      formData.append("file", selectedCubitFile);

      try {
        setIsLoading(true);
        const cubitResponse = await createCubitData(token, formData);
        if (cubitResponse.status === 201) {
          localStorage.removeItem("multipliedValues");
          projectDispatch({ type: projectActionTypes.UPDATE_CUBITID, payload: cubitResponse.data._id });
          navigate("/cubit-information/" + cubitResponse.data._id);
          projectDispatch({ type: projectActionTypes.UPDATE_CUBITID, payload: cubitResponse.data._id });
          Swal.fire({
            icon: "success",
            title: "Cubit File Uploaded Successfully!",
            confirmButtonColor: "#100c08",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error uploading file",
          confirmButtonColor: "#100c08",
        });
        console.error("Error uploading file:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "No Cubit File Selected",
        confirmButtonColor: "#100c08",
      });
    }
  };

  const handleCubitFileDelete = () => {
    setSelectedCubitFile(null);
  }

  const handleGoToProjectsClick = () => {
    navigate("/projects");
  }

  const handleCubitVersionClick = (cubitId) => {
    navigate("/cubit-version-data/" + cubitId);
  }

  const handleOneOffItemDataClick = () => {
    navigate(`/one-off-item-data/${projectId}`);
  }

  const handleCubitFileDeleteById = async (cubitId, event) => {
    try {
      event.stopPropagation();
      const confirmationResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete the Cubit file. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#100c08",
        cancelButtonColor: "#D1D1D1",
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No Cancel!',
        customClass: {
          cancelButton: "cancel-button-class",
        },
      });
      if (confirmationResult.isConfirmed) {
        setIsLoading(true);
        await deleteCubit(token, cubitId);
        await fetchCubitDataForProject(projectId);
        // navigate("/projects");
        Swal.fire({
          title: "Deleted",
          text: "Your Cubit File has been deleted.",
          icon: "success",
          confirmButtonColor: "#100c08",
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Your Cubit file is safe :)',
          icon: 'error',
          customClass: {
            confirmButton: "cancel-button-class",
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error deleting Cubit file",
        confirmButtonColor: "#100c08",
      });
      console.error("Error deleting Cubit file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyCubitVersion = async (cubitId, event) => {
    try {
      event.stopPropagation();
      const confirmationResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about the create a new version of cubit from the selected cubit.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#100c08",
        cancelButtonColor: "#D1D1D1",
        confirmButtonText: 'Yes, Create copy!',
        cancelButtonText: 'No Cancel!',
        customClass: {
          cancelButton: "cancel-button-class",
        },
      });
      if (confirmationResult.isConfirmed) {
        setIsLoading(true);
        const newCubitResponse = await createNewCubitVersion(token, cubitId);
        if (newCubitResponse.status) {
          setCubitDataForProject(prevCubitDataForProject => [
            ...prevCubitDataForProject,
            newCubitResponse.data
          ]);
        }
        Swal.fire({
          title: "Created",
          text: "Created new version of cubit",
          icon: "success",
          confirmButtonColor: "#100c08",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error creating copy of Cubit file",
        confirmButtonColor: "#100c08",
      });
      console.error("Error creating copy of Cubit file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'file',
        header: '',
        enableSorting: false,
        size: 50,
        Cell: () => <FontAwesomeIcon icon={faFolder} style={{ width: '18px', height: '18px' }} />
      },
      {
        accessorKey: 'projectName',
        header: 'Project Name',
        size: 250,
      },
      {
        accessorKey: 'version',
        header: 'Version',
        size: 100,
      },
      {
        accessorKey: 'createdOn',
        header: 'Created On',
        size: 200,
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        size: 200,
      },
      {
        accessorKey: 'createdCopy',
        header: 'Create Copy',
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <FontAwesomeIcon
            icon={faCopy}
            style={{ width: '18px', height: '18px', color: 'black', cursor: 'pointer' }}
            onClick={(event) => handleCopyCubitVersion(row?.original?._id, event)}
          />
        )
      },
      userRole === ROLE.SUPER_ADMIN && {
        accessorKey: 'action',
        header: 'Action',
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ width: '18px', height: '18px', color: 'red', cursor: 'pointer' }}
            onClick={(event) => handleCubitFileDeleteById(row?.original?._id, event)}
          />
        )
      },
    ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole]
  );

  const data = useMemo(() => {
    return cubitDataForProject.map(cubitDataItem => ({
      ...cubitDataItem,
      projectName: cubitDataItem?.projectId?.projectName,
      version: cubitDataItem?.version,
      createdOn: moment(cubitDataItem?.createdAt).format("DD MMM YYYY"),
      createdBy: cubitDataItem?.createdBy?.fullname || 'Unknown User',
      file: '',
      copy: '',
      action: ''
    }));
  }, [cubitDataForProject]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      onClick: (event) => handleCubitVersionClick(row.original._id),
      component: TableRow,
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#8f8f8f',
          color: 'white',
        },
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <h2 variant="contained" style={{ textDecoration: "underline" }}>
        Cubit Versions :
      </h2>
    ),
    muiTableProps: {
      sx: {
        border: '1px solid #ccc',
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: '1px solid #ccc',
        fontWeight: 'bold',
        backgroundColor: '#1B1B1B',
        fontFamily: 'Segoe UI',
        color: '#C0C0C0',
        fontSize: '14px',
        '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
          color: '#fff !important',
        },
        '& .MuiTableSortLabel-icon': {
          color: '#fff',
        },
        '& .MuiIconButton-root': {
          color: '#fff',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: '1px solid #ccc',
        cursor: 'pointer',
        fontFamily: 'Segoe UI',
        fontWeight: '500',
        fontSize: '12px'
      },
    },
  });

  return (
    <div>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            style={{ margin: "10px", display: "flex", justifyContent: "space-between", }}>
            <span>
              <button className="custom_file_input" onClick={handleGoToProjectsClick}>Go To Projects</button>
            </span>
            <span>
              {selectedCubitFile?.name?.length > 0 ?
                <>
                  <span className="custom_file_input">
                    <span style={{ marginRight: '10px' }}>{selectedCubitFile.name}</span>
                    <FontAwesomeIcon icon={faTrashAlt} onClick={handleCubitFileDelete} />
                  </span>
                </> :
                <>
                  <label htmlFor="file1" className="custom_file_input">Choose New Cubit File</label>
                  <input type="file" id="file1" name="file1" accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="file_input" onChange={handleCubitFileChange} />
                </>
              }
              <button className="upload_btn2" onClick={handleUploadCubitFile}>Upload New Cubit File</button>
            </span>
          </div>
          <div className="project-header" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className="oneoff-item-btn" style={{ textDecoration: 'none' }} onClick={handleOneOffItemDataClick}>OneoffItem Data</button>
          </div>
          <div>
            <MaterialReactTable table={table} />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectItems;
import axios from 'axios';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To Get the Prebuild Data According to Pagination
let cancelToken;
export const fetchPaginatedPrebuildData = async (token, pageNum, pageSize, globalFilterValue) => {
  try {

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    let header = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      'x-Auth-token': token
    };

    let response = await axios.get(`${backEndUrl}/api/service/pagination/paginated-prebuild-data/${pageSize}/${pageNum}/${globalFilterValue}`, {
      headers: header,
      cancelToken: cancelToken.token
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      throw error;
    }
  }
};

// To Get the OneoffItem Data according to Pagination
export const fetchPaginatedOneOffData = (token, projectId, pageNum, pageSize, globalFilterValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'x-Auth-token': token
      };
      let response = await axios.get(`${backEndUrl}/api/service/pagination/paginated-one-off-data/${projectId}/${pageSize}/${pageNum}/${globalFilterValue}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

// To Get the calculation Data according to Pagination
export const fetchPaginatedCalculationData = (token, pageNum, pageSize, rateInputFormId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'x-Auth-token': token
      };
      let response = await axios.get(`${backEndUrl}/api/service/pagination/paginated-calculation-data/${rateInputFormId}/${pageSize}/${pageNum}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
import React, { useEffect, useMemo, useState } from 'react'
import './PrebuildData.css'
import { MaterialReactTable } from 'material-react-table'
import { Checkbox, Fade, FormControlLabel, Modal } from '@mui/material';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { ROLE } from "../../utils/constant";
import { useUserContext } from "../../context/user.context";
import { fetchPaginatedPrebuildData, } from "../../utils/services/paginationService";
import { prebuildDataExport } from "../../utils/services/exportexcelService"
import { insertPrebuildData, deletePrebuild } from "../../utils/services/prebuildService";
import CreatePrebuild from '../../components/PrebuildComponents/CreatePrebuild/CreatePrebuild.js';
import PrebuildDetailsForm from '../../components/PrebuildComponents/PrebuildDetailsForm/PrebuildDetailsForm.js';

function PrebuildData() {
  const [rowData, setRowData] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedPrebuild, setSelectedPrebuild] = useState(null);
  const [pebuildDetailsForm, setPebuildDetailsForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userState } = useUserContext();
  const token = sessionStorage.getItem('token');
  const [globalFilter, setGlobalFilter] = useState('');

  // To fetch current page data
  useEffect(() => {
    fetchData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, globalFilter]);

  useEffect(() => {
    const totalNumberOfPages = Math.ceil(totalNumberOfRecords / pageSize);
    setTotalNumberOfPages(totalNumberOfPages);
    if (currentPage > totalNumberOfPages) {
      setCurrentPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNumberOfRecords, pageSize]);

  // To load the PB Data  
  const fetchData = async (currentPageNumber) => {
    try {
      setLoading(true);
      const paginatedResponse = await fetchPaginatedPrebuildData(token, currentPageNumber, pageSize, (globalFilter || ""));
      if (paginatedResponse?.status) {
        const { paginatedPrebuildData } = paginatedResponse.data;
        setRowData(paginatedPrebuildData);
        setTotalNumberOfRecords(paginatedResponse.data.totalNumberOfRecords);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  // Handle page size change
  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
  };

  // Handle page Change
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalNumberOfPages) {
      setCurrentPage(pageNumber);
      fetchData(pageNumber)
    }
  };

  // Handle to view page range
  const getRangeText = () => {
    if (totalNumberOfRecords === 0) {
      return `Show 0-0 of 0`;
    } else {
      const startRange = Math.min((currentPage - 1) * pageSize + 1, totalNumberOfRecords);
      const endRange = Math.min(currentPage * pageSize, totalNumberOfRecords);
      return `Show ${startRange}-${endRange} of ${totalNumberOfRecords}`;
    }
  };

  // To create new pebuild click
  const handleCreatePrebuildClick = () => {
    setShowForm(true);
  };

  // Prebuild File Upload
  const handleUpload2 = async () => {
    if (selectedFile2) {
      const formData = new FormData();
      formData.append("file", selectedFile2);
      try {
        setLoading(true)
        insertPrebuildData(token, formData).then(response => {
          Swal.fire({
            icon: "success",
            title: "Prebuild File Uploaded Successfully!",
            confirmButtonColor: "#100c08",
          });
          fetchData(currentPage);
          setLoading(false);
        }).catch((err => {
          Swal.fire({
            icon: "error",
            title: "Error uploading file",
            confirmButtonColor: "#100c08",
          });
          setLoading(false);
        }));
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error uploading file",
          confirmButtonColor: "#100c08",

        });
        setLoading(false);
        console.error("Error uploading file:", error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "No Prebuild File Selected",
        confirmButtonColor: "#100c08",

      });
    }
  };
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
  };

  // Handle to remove selected file while importing file
  const handlePrebuildFileDelete = () => {
    setSelectedFile2(null);
  }

  // Export to Excel Function
  async function exportToExcel() {
    try {
      setLoading(true);
      const response = await prebuildDataExport(token);
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PrebuildData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Swal.fire({
        icon: "success",
        title: "Data Downloaded!",
        text: "Prebuild Data Exported to Excel.",
        confirmButtonColor: "#100c08",
      });
    } catch (error) {
      console.error("Failed to export data to Excel:", error);
      Swal.fire({
        icon: "error",
        title: "Data Downloaded Failed",
        text: "Failed to export data to Excel. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  }

  // Delete rows
  const handleDeleteRows = async () => {
    const selectedRows = rowData.filter(row => row.selected);
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete the selected rows. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#100c08",
        cancelButtonColor: "#D1D1D1",
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel",
      });
      if (result.isConfirmed) {
        for (const row of selectedRows) {
          await deletePrebuild(token, row._id)
        }
        fetchData(currentPage);
        setLoading(false);
        Swal.fire({
          title: "Deleted!",
          text: "Selected rows have been deleted.",
          icon: "success",
          confirmButtonColor: "#100c08",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setLoading(false);
        Swal.fire({
          title: "Cancelled",
          text: "Selected rows are safe :)",
          icon: "error",
          customClass: {
            confirmButton: "cancel-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Error deleting rows:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting rows.",
        icon: "error",
        confirmButtonColor: "#100c08",
      });
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();
    const newData = [...rowData];
    if (newData[id]) {
      newData[id].selected = event?.target?.checked;
      setRowData(newData)
    }
  };

  // Handle to open prebuild details form
  const handlePrebuildDetails = (userIndex) => {
    if (userState.user.role === ROLE.SUPER_ADMIN) {
      const user = rowData[userIndex];
      setSelectedPrebuild(user);
      setPebuildDetailsForm(true);
    }
  };

  // Handle to close prebuild details form
  const handleCloseDetailsForm = () => {
    setPebuildDetailsForm(false);
  };

  // Define table content
  const TableCellContent = ({ row, handleClick, field }) => {
    try {
      const text = row.original[field];
      if (typeof text !== "string") {
        throw new Error("Invalid text value");
      }

      const escapedGlobalFilterValue = globalFilter?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escapedGlobalFilterValue})`, 'gi');
      const parts = text.split(regex);

      return (
        <span
          style={{
            padding: 0,
            width: '100%',
            height: '100%',
            margin: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handlePrebuildDetails(row?.index)}
        >
          <span style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            overflow: 'visible',
          }}>
            {parts.map((part, index) =>
              part.toLowerCase() === globalFilter?.toLowerCase() ? (
                <span key={index}>
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              )
            )}
          </span>
        </span>
      );
    } catch (error) {
      console.error("Error in TableCellContent:", error.message);
      return (
        <span style={{ padding: 0, width: '100%', height: '100%', margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handlePrebuildDetails(row?.index)}
        >
          {field}
        </span>
      );
    }
  };

  // Define columns
  const columns = useMemo(() => {
    return [
      {
        header: '', accessorKey: 'select', enableSorting: false,
        Cell: ({ row }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={row?.original?.selected || false}
                onChange={(e) => handleCheckboxChange(e, row?.index)}
                size="small"
              />
            }
            label=""
          />
        ),
        size: '40',
        muiTableBodyCellProps: {
          sticky: true,
        },
        muiTableHeadCellProps: {
          sticky: true,
        }
      },
      {
        header: 'Cost Centre', accessorKey: 'CostCentre1',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="CostCentre1" />
        ),
      },
      {
        header: 'Cost Centre2', accessorKey: 'CostCentre2',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="CostCentre2" />
        ),
      },
      {
        header: 'Sub Group 1', accessorKey: 'SubGroup1',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="SubGroup1" />
        ),
      },
      {
        header: 'Sub Group 2', accessorKey: 'SubGroup2',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="SubGroup2" />
        ),
      },
      {
        header: 'Sub Group 3', accessorKey: 'SubGroup3',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="SubGroup3" />
        ),
      },
      {
        header: 'Concat 1', accessorKey: 'Concat1',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Concat1" />
        ),
      },
      {
        header: 'Concat 2', accessorKey: 'Concat2',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Concat2" />
        ),
      },
      {
        header: 'Name With Height', accessorKey: 'NameWithHeights',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="NameWithHeights" />
        ),
      },
      {
        header: 'Common Name', accessorKey: 'CommonName',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="CommonName" />
        ),
      },
      {
        header: 'Quote Name', accessorKey: 'QuoteName',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="QuoteName" />
        ),
      },
      {
        header: 'Labour Group 1', accessorKey: 'LabourGroup1',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="LabourGroup1" />
        ),
      },
      {
        header: 'Labour Group 2', accessorKey: 'LabourGroup2',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="LabourGroup2" />
        ),
      },
      {
        header: 'U.O.M.', accessorKey: 'UOM',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="UOM" />
        ),
      },
      {
        header: 'Labour', accessorKey: 'Labour',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Labour" />
        ),
      },
      {
        header: 'Hours', accessorKey: 'Hours',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Hours" />
        ),
      },
      {
        header: 'Rate | Material', accessorKey: 'Rate_Material',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Rate_Material" />
        ),
      },
      {
        header: 'simPRO Price Upload', accessorKey: 'simProPriceUpload',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="simProPriceUpload" />
        ),
      },
      {
        header: 'Rate | Wastage', accessorKey: 'Rate_Wastage',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Rate_Wastage" />
        ),
      },
      {
        header: 'Rate | Sundries', accessorKey: 'Rate_Sundries',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Rate_Sundries" />
        ),
      },
      {
        header: 'Rate | Delivery', accessorKey: 'Rate_Delivery',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Rate_Delivery" />
        ),
      },
      {
        header: 'Rate | MarkUp', accessorKey: 'Rate_MarkUp',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Rate_MarkUp" />
        ),
      },
      {
        header: 'PB', accessorKey: 'PB',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="PB" />
        ),
      },
      {
        header: 'Onsite', accessorKey: 'Onsite',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="Onsite" />
        ),
      },
      {
        header: 'Concat SubGroup', accessorKey: 'ContactSubGroup',
        Cell: ({ row }) => (
          <TableCellContent row={row} field="ContactSubGroup" />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  // Set theme for the table
  const mrtTheme = (theme) => ({
    baseBackgroundColor: theme.palette.background.default,
  });

  // Define styles for table header
  const muiTableHeadCellProps = {
    sx: {
      border: '1px solid #ccc',
      fontWeight: 'bold',
      backgroundColor: '#1B1B1B',
      fontFamily: 'Segoe UI',
      color: '#C0C0C0',
      fontSize: '14px',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '& .MuiInputBase-input': {
        color: '#fff',
      },
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      overflow: 'visible',
      textOverflow: 'clip',
      display: 'block',
    },
  };

  // Define syles for table body
  const muiTableBodyCellProps = {
    sx: {
      fontFamily: 'Segoe UI',
      fontWeight: '500',
      border: '1px solid #ccc',
      cursor: 'pointer',
      fontSize: '12px',
    },
  };

  // Define components at top
  const renderTopToolbarCustomActions = ({ table }) => (
    <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
      <div>
        {userState?.user?.role === ROLE.SUPER_ADMIN ? (
          <>
            <button className="Delete-row" onClick={handleDeleteRows}>Delete Row</button>
          </>
        ) : (
          <></>
        )}
      </div>
      <button className="Export-Data" onClick={() => setGlobalFilter("")}>Reset Filter</button>
      {(userState?.user?.role === ROLE.SUPER_ADMIN) ? (
        <>
          <div style={{ marginRight: "30px" }}>
            <button className="Export-Data" onClick={exportToExcel}>Export To Excel</button>
          </div>
        </>
      ) : (
        <div></div>
      )}
      {userState?.user?.role === ROLE.SUPER_ADMIN ? (
        <>
          {selectedFile2?.name?.length > 0 ?
            <>
              <span className="custom_file_input2">
                <span style={{ marginRight: '10px' }}>{selectedFile2.name}</span>
                <FontAwesomeIcon icon={faTrashAlt} onClick={handlePrebuildFileDelete} />
              </span>
            </> :
            <>
              <span className="custom_file_input2">
                <input type="file" id="file2" name="file2" accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="file_input2" onChange={handleFileChange2} style={{ display: "none" }} />
                <label htmlFor="file2" >Choose Prebuild File</label>
              </span>
            </>
          }
          <button className="upload_btn3" onClick={handleUpload2}>Import Prebuild File</button>
        </>
      ) : (
        <></>
      )}
    </div>
  );

  // Define components at bottom
  const renderBottomToolbarCustomActions = ({ table }) => (
    <div style={{ marginRight: '30px', marginTop: '10px', display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      {userState?.user?.role === ROLE.SUPER_ADMIN ? (
        <div>
          <button className="Add-Data" onClick={handleCreatePrebuildClick}>Add New Data</button>
        </div>
      ) : (
        <div></div>
      )}
      <div>
        <span style={{ border: '1px solid gray', padding: '5px', margin: "0px 5px 0px 15px", borderRadius: '5px' }}>{getRangeText()}</span>
        <label>Page Size:</label>
        <select value={pageSize} onChange={handleChangePageSize}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <button className="Update-row" onClick={() => goToPage(1)} disabled={currentPage === 1}>First</button>
        <button className="Update-row" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
        <span style={{ border: '1px solid gray', padding: '5px', margin: "0px 5px 0px 15px", borderRadius: '5px' }}>{`Page ${currentPage} of ${totalNumberOfPages}`}</span>
        <button className="Update-row" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalNumberOfPages}>Next</button>
        <button className="Update-row" onClick={() => goToPage(totalNumberOfPages)} disabled={currentPage === totalNumberOfPages}>Last</button>
      </div>
    </div>
  );

  return (
    <div className='prebuild-component'>
      <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
        <MaterialReactTable
          columns={columns}
          data={rowData}
          enablePagination={false}
          enableDensityToggle={false}
          enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnResizing={true}
          manualFiltering={true}
          manualPagination={true}
          manualSorting={true}
          onGlobalFilterChange={setGlobalFilter}
          enableFilterMatchHighlighting={true}
          state={globalFilter}
          mrtTheme={mrtTheme}
          muiTableHeadCellProps={muiTableHeadCellProps}
          muiTableBodyCellProps={muiTableBodyCellProps}
          enableColumnPinning={true}
          initialState={{
            density: 'compact',
            columnPinning: { left: ['select'] }
          }}
          muiTableBodyRowProps={() => ({
            sx: {
              '& .MuiTableCell-root': {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }
            }
          })}
          muiTableBodyProps={() => ({
            sx: {
              height: '65vh',
            }
          })}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
        />
      </div>
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={showForm} closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Fade in={showForm}>
          <div style={{ width: '70%', minWidth: '55%', maxHeight: '95%', overflowY: 'auto', }}>
            <CreatePrebuild
              show={showForm}
              onClose={() => {
                setShowForm(false);
              }}
            />
          </div>
        </Fade>
      </Modal>
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={pebuildDetailsForm} closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Fade in={pebuildDetailsForm}>
          <div style={{ width: '70%', minWidth: '55%', maxHeight: '95%', overflowY: 'auto' }}>
            <PrebuildDetailsForm
              show={pebuildDetailsForm}
              onClose={() => {
                handleCloseDetailsForm();
              }}
              prebuildData={selectedPrebuild}
              prebuildList={rowData}
              setprebuildList={setRowData}
            />
          </div>
        </Fade>
      </Modal>
      <div>
        <div>{loading && <LoadingSpinner />}</div>
      </div>
    </div>
  )
}

export default PrebuildData

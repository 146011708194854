import { createContext, useContext, useReducer } from 'react';

const initialState = {
    user: null,
    token: '',
};

export const userActionTypes = {
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
};

// Define reducer function to update state based on actions
const userReducer = (state, action) => {
    switch (action.type) {
        case userActionTypes.UPDATE_USER:
            return { ...state, user: action.payload };
        case userActionTypes.UPDATE_TOKEN:
            return { ...state, token: action.payload };
        default:
            return state;
    }
};

// Create user context
const UserContext = createContext();

// User provider component
export const UserProvider = ({ children }) => {
    const [userState,userDispatch] = useReducer(userReducer, initialState);
    return (
        <UserContext.Provider value={{ userState, userDispatch }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to consume user context
export const useUserContext = () => useContext(UserContext);
import { Navigate, Outlet } from "react-router-dom";
import {jwtDecode} from 'jwt-decode';
import { ROLE } from "../utils/constant";

// Admin Routes:- Only this user can able to see the projects page
const AdminRoute = () => {
  const token = sessionStorage.getItem("token");
  const userEmail = sessionStorage.getItem("email");
  const userRole = sessionStorage.getItem("role");

  let auth;

  if ((token === null || token === "") && (userEmail == null || userEmail === "" || userEmail === false)) {
    auth = false;
  } else if (userEmail && token !== null) {
    const decodedToken = jwtDecode(token);
    if (!decodedToken) {
      auth = false;
    } else {
        if(userRole=== ROLE.ADMIN || userRole=== ROLE.SUPER_ADMIN) {
            auth = true;
        } else{
            auth = false;
        }  
    }
  } else if (userEmail && (token == null || token === "")) {
    auth = false;
  } else {
    auth = false;
  }

  return auth ? (
    <span className="wrapper">
      <Outlet />
    </span>
  ) : userEmail && userRole &&token ?(<Navigate to="/projects" replace={true} />): (
    <Navigate to="/" replace={true} />
  );
};

export default AdminRoute;

import axios from 'axios';
const backEndUrl = process.env.REACT_APP_BACKEND_URL;

// To insert oneoffitem value
export const insertOneOffItem = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(`${backEndUrl}/api/service/oneoffitem/insert-oneoffitem`, data, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Update OneoffItem Data
export const updateOneoffitem = (token, _id, updatedData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.put(`${backEndUrl}/api/service/oneoffitem/update-oneoffitem/${_id}`, updatedData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Delete OneoffItem Data
export const deleteoneoffitem = (token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.delete(`${backEndUrl}/api/service/oneoffitem/delete-oneoffitem/${id}`, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

// To Get Unique OneoffItem Data
export const getUniqueOneOffItem = (token, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/oneoffitem${url}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To get page load oneoff item Data
export const getPageLoadOneOffItem = (currentProjectId, cubitId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${backEndUrl}/api/service/oneoffitem/page-load-oneoff`, {
        params: {
          projectId: currentProjectId,
          cubitId: cubitId
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Get Total OneoffItem Data
export const fetchOneOffData = (token, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/oneoffitem/get-oneoffitemdata/${projectId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      throw err;
    }
  });
};

// To get the OFN value for last record
export const getOffPbValue = (token, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${backEndUrl}/api/service/oneoffitem/oneoffitem-ofn/${projectId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

// To Check Existing Data for OneoffItem Data
export const existingDataCheckOneoffItem = (token, projectId, newData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(`${backEndUrl}/api/service/oneoffitem/existing-data-check-oneoffitem/${projectId}`, newData, {
        headers: header,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
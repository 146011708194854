import React, { useEffect, useState } from "react";
import "./Calculationpage.css";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from 'react-router-dom'
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import CalculationForm from "../../components/RateInputForm/CalculationForm";
import { finalCalculationExport } from "../../utils/services/exportexcelService"
import { fetchPaginatedCalculationData } from "../../utils/services/paginationService";
import { currencyFormatForString } from "../../utils/formatter";
import { Toast } from '../../utils/helper';
import { calculateData, checkIfCalculationExist, checkIfCalculationMaxPrebuild } from "../../utils/services/calculationService";
import { useProjectContext } from "../../context/project.context";
import { createRateInputCopy } from "../../utils/services/rateinputService";
import { Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Calculationpage() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [showNewForm, setShowNewForm] = useState(false);
  const { rateInputFormId } = useParams();
  const token = sessionStorage.getItem('token')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [projectName, setProjectName] = useState("")
  const [calculationID, setCalculationDataID] = useState("")
  const { projectState } = useProjectContext();
  const navigate = useNavigate();
  const [cubitId, setCubitId] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const open = Boolean(showMenu);

  // To fetch current page data
  useEffect(() => {
    if (rateInputFormId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, rateInputFormId]);
  useEffect(() => {
    const totalNumberOfPages = Math.ceil(totalNumberOfRecords / pageSize);
    setTotalNumberOfPages(totalNumberOfPages);
  }, [totalNumberOfRecords, pageSize]);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
  };

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalNumberOfPages) {
      setCurrentPage(pageNumber);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const paginatedCalculationResponse = await fetchPaginatedCalculationData(token, currentPage, pageSize, rateInputFormId)
      if (paginatedCalculationResponse.status) {
        setFinalData(paginatedCalculationResponse.data)
        setTotalNumberOfRecords(paginatedCalculationResponse.data.totalNumberOfRecords);
        setProjectName(paginatedCalculationResponse.data.ProjectName)
        setCalculationDataID(paginatedCalculationResponse.data.calculationDataID)
        setCubitId(paginatedCalculationResponse.data.cubitId)
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getRowStyle = (params) => {
    if (!params.data || !params.data["PreBuild No"]) {
      return { background: "#A0153E", color: 'white' };
    }
    return null;
  };

  // Text Format for currency($)
  const formatCurrency = (params) => {
    if (!params || typeof params.value === 'undefined' || params.value === null) {
      return '';
    }
    const value = parseFloat(params.value.replace('$', ''));
    const roundedValue = value.toFixed(2);

    return currencyFormatForString(roundedValue);
  };

  // Text Format for currency(%)
  // const formatPercentage = (params) => {
  //   if (!params || typeof params.value === 'undefined' || params.value === null) {
  //     return '';
  //   }
  //   const value = parseFloat(params.value.replace('%', ''));
  //   const roundedValue = value.toFixed(2);
  //   const formattedValue = `${roundedValue}%`
  //   const parts = formattedValue.split(/\d/)
  //   const symbol = parts[parts.length - 1]
  //   const number = formattedValue.replace(symbol, '').trim();
  //   return { symbol, number };
  // }

  // Render style for Text cell format for Currency($)
  // const CurrencyCell = ({ value }) => {
  //   console.log(value)

  //   const { symbol, number } = formatCurrency({ value });
  //   console.log(symbol)
  //   console.log(number)

  //   return (
  //     <div className="currency-cell">
  //       <span className="currency-symbol">{symbol}</span>
  //       <span className="currency-value">{number}</span>
  //     </div>
  //   );
  //   return "jsdnj"
  // };

  // Render style for Text cell format for Currency(%)
  // const PrecentageCell = ({ cell }) => {
  //   const { symbol, number } = formatPercentage({ value: cell.getValue() });
  //   return (
  //     <div className="currency-cell">
  //       <span className="currency-symbol">{symbol}</span>
  //       <span className="currency-value">{number}</span>
  //     </div>
  //   );
  // };

  // To define columns names
  const columnDefs = [
    // { headerName: 'combinedString', field: 'combinedString' },
    { headerName: "Order", field: "Order", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Price Comparison", field: "Price Comparison", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Job File No. ", field: "Job File No", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Job → Project Name", field: "Job → Project Name", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Stage", field: "Stage", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Building", field: "Building", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Zone/Area", field: "Zone/Area", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Level", field: "Level", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Plan Name", field: "Plan Name", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Package", field: "Package", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Wall/Ceiling Types", field: "Wall/Ceiling Types", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Notes", field: "Notes", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Bill Ref", field: "Bill Ref", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Ceiling Height", field: "Ceiling Height", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Description", field: "Description", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Primary/ Secondary", field: "PrimarySecondaryMeasurement", headerClass: "calculation-custom-header", resizable: true },
    {
      headerName: "L.Quantity", field: "LQuantity", headerClass: "calculation-custom-header", resizable: true,
      valueFormatter: params => {
        if (typeof params.value === 'number') {
          return params.value.toFixed(2);
        }
        return params.value;
      }
    },
    { headerName: "M.Unit", field: "MUnit", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Prebuild/One Off Set", field: "prebuildOneOffSet", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Primary Secondary", field: "primarySecondary", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Job Cost Center Name", field: "costCenterName", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Cost Center", field: "costCenterSub", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Sub Group1", field: "subGroup1", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Sub Group2", field: "subGroup2", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Prebuild(List)", field: "commonName", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "PreBuild No", field: "PreBuild No", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Comments", field: "comments", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Rate Material", field: "Rate_Material", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Rate Wastage", field: "Rate_Wastage", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Rate Sundries", field: "Rate_Sundries", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Rate Delivery", field: "Rate_Delivery", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Rate MarkUp", field: "Rate_MarkUp", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Cost Material", field: "Cost_Material", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Wastage", field: "Cost_Wastage", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Sundries", field: "Cost_Sundries", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Delivery", field: "Cost_Delivery", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost MarkUp", field: "Cost_MarkUp", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Material Combine", field: "Cost_Material_Combine", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Rate Labour Std", field: "Labour", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Labour Std", field: "Cost_Labour_Std", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Hours", field: "Hours", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Total Hours", field: "Total_Hours", headerClass: "calculation-custom-header", resizable: true },
    { headerName: " Hourly Rate Checker", field: "Hourly_Rate_Checker", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Hourly Rate", field: "Hourly_Rate", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Incolink", field: "incolink", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Super", field: "super", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Colnvest", field: "colnvest", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Payroll Tax", field: "Payroll_Tax", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Work Cover", field: "Work_Cover", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Overheads", field: "overheads", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Scissor | 19ft", field: "scissor", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Shop Steward", field: "Shop_Steward", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Site Foreman", field: "Site_Foreman", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Site Start Up", field: "Site_StartUp", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Travel", field: "travel", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "LAHA", field: "laha", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Site Allowance", field: "Site_Allowance", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Airport Allowance", field: "Airport_Allowance", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    // { headerName: 'Rates Multiplier', field: 'Rates_Multiplier', headerClass: "calculation-custom-header",},
    { headerName: "Height/loading", field: "Height_Loading", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Night Works", field: "Night_Works", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Downtime", field: "downtime", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Combined Rate Per Unit", field: "Combined_Rate", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Cost Labour", field: "Cost_Labour", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Sub Total", field: "Sub_total", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Margin Rate", field: "Margin_Rate", headerClass: "calculation-custom-header", resizable: true },
    { headerName: "Margin Amount", field: "Margin_Amount", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Sales Price ex GST", field: "Sales_Price", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "GST", field: "GST", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "Total GST Inc", field: "Total_GST", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "simpro_Horly_Rate", field: "simpro_Hourly_Rate", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency },
    { headerName: "simPro_MarkUp", field: "simPro_MarkUp", headerClass: "calculation-custom-header", resizable: true, valueFormatter: formatCurrency }
  ];

  const handleViewBreakup = () => {
    setShowModal(true);
  };

  const onCloseProjectClick = () => {
    setShowModal(false);
  };

  // Handle to Open Form
  const handleNewFormClick = () => {
    setShowMenu(null);
    setShowNewForm(true);
  };

  // Handle to Close Form
  const handleCloseForm = () => {
    setShowNewForm(false);
  };

  async function exportToExcel() {
    try {
      setShowMenu(null);
      setExportLoading(true);
      const response = await finalCalculationExport(token, rateInputFormId);
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `calc-${projectName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      Swal.fire({
        icon: 'success',
        title: 'Data Downloaded!',
        text: 'Final Calculation Data Exported to Excel.',
        confirmButtonColor: "#100c08",
      });
    } catch (error) {
      console.error('Failed to export data to Excel:', error);
      Swal.fire({
        icon: 'error',
        title: 'Data Downloaded Failed',
        text: 'Failed to export data to Excel. Please try again later.',
      });
    } finally {
      setExportLoading(false);
    }
  }

  const getRangeText = () => {
    if (totalNumberOfRecords === 0) {
      return `Show 0-0 of 0`;
    } else {
      const startRange = Math.min((currentPage - 1) * pageSize + 1, totalNumberOfRecords);
      const endRange = Math.min(currentPage * pageSize, totalNumberOfRecords);
      return `Show ${startRange}-${endRange} of ${totalNumberOfRecords}`;
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const isActive = (option) => {
    return option === selectedOption;
  };

  const renderModalContent = () => {
    if (selectedOption === 'Package') {
      return (
        <table className="table">
          <tbody>
            {/* {Object.entries(finalData.packageSalesTotal).map(([key, value]) => (
              <tr key={key}>
                <td className="cell"><strong>{key}:</strong></td>
                <td className="cell">{currencyFormatForString(value)}</td>
              </tr>
            ))} */}

            {Object.entries(finalData.packageSalesTotal).map(([key, value]) =>
              key ?
                (<tr key={key}>
                  <td className="cell"><strong>{key}:</strong></td>
                  <td className="cell" style={{ textAlign: 'right' }}>{currencyFormatForString(value)}</td>
                </tr>)
                : null
            )}
          </tbody>
        </table>
      );
    } else if (selectedOption === "Cost Centre") {
      return (
        <table className="table">
          <tbody>
            {Object.entries(finalData.costCenterSubSalesTotal).map(([key, value]) =>
              key ?
                (<tr key={key}>
                  <td className="cell"><strong>{key}:</strong></td>
                  <td className="cell" style={{ textAlign: 'right' }}>{currencyFormatForString(value)}</td>
                </tr>)
                : null
            )}
          </tbody>
        </table>
      )
    } else {
      return (
        <table className="table">
          <tbody>
            {/* {Object.entries(finalData.packageSalesTotal).map(([key, value]) => (
              <tr key={key}>
                <td className="cell"><strong>{key}:</strong></td>
                <td className="cell" style={{ textAlign: 'right' }}>{currencyFormatForString(value)}</td>
              </tr>
            ))} */}
            {Object.entries(finalData.packageSalesTotal).map(([key, value]) =>
              key ?
                (<tr key={key}>
                  <td className="cell"><strong>{key}:</strong></td>
                  <td className="cell" style={{ textAlign: 'right' }}>{currencyFormatForString(value)}</td>
                </tr>)
                : null
            )}
          </tbody>
        </table>
      );
    }
  }

  const handleSubmitNewRateInputForm = async (rateInputFormId) => {
    try {
      const calculationCheckResponse = await checkIfCalculationExist(token, rateInputFormId);
      if (calculationCheckResponse.status) {
        navigate(`/calculationpage/${rateInputFormId}`);
      } else {
        setLoading(true)
        let calculationReponse = await calculateData(token, (projectState.currentCubitId || calculationCheckResponse.data.cubitId), { rateInputFormId: rateInputFormId });
        if (calculationReponse.status) {
          navigate(`/calculationpage/${rateInputFormId}`);
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong while performing.",
            confirmButtonColor: "#100c08"
          });
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('Error', err)
    }
  }

  const handleRecalculate = async () => {
    try {
      setShowMenu(null);
      setLoading(true)
      const isCalculationMaxPrebuildResponse = await checkIfCalculationMaxPrebuild(token, rateInputFormId)
      if (isCalculationMaxPrebuildResponse.status) {
        if (isCalculationMaxPrebuildResponse.data) {
          Toast.fire({
            icon: "error",
            title: "Calculation is already performed as per the latest prebuild values.",
          })
        } else {
          // create a copy of rate input form
          const createRateInputCopyResponse = await createRateInputCopy(token, rateInputFormId);
          if (createRateInputCopyResponse.status) {
            // perform calculation as per the latest prebuild version
            let calculationReponse = await calculateData(token, (createRateInputCopyResponse.data.cubitId || projectState.currentCubitId), { rateInputFormId: createRateInputCopyResponse.data._id, isRecalculate: true });
            if (calculationReponse.status) {
              navigate(`/calculationpage/${createRateInputCopyResponse.data._id}`);
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong while performing.",
                confirmButtonColor: "#100c08"
              });
            }
          } else {
            // eslint-disable-next-line no-throw-literal
            throw {
              message: "Create rate input copy failed in recalculation."
            }
          }
        }
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: "Something went wrong while recalculating."
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log("Err", err)
    }
  }

  const handleQuotegenerate = () => {
    navigate(`/quote/${calculationID}`)
  }

  const handleMenuIconClick = (event) => {
    setShowMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  return (
    <>
      <span style={{}}>
        {loading ?
          <div>
            <LoadingSpinner />
          </div>
          : <></>}
        {exportLoading ?
          <div>
            <LoadingSpinner loadingText={"Please wait while we export your data"} />
          </div>
          : <></>}
      </span>
      <div className="ag-theme-quartz" style={{ width: "auto", height: "78vh" }}>
        {finalData?.result?.length > 0 && (
          <div style={{ display: "flex", justifyContent: 'space-between', minWidth: '1070px' }} className="calculationPage-total">
            <div style={{ display: 'flex' }}>
              {cubitId ? <><button onClick={() => navigate(`/cubit-version-data/${cubitId}`)} className="export-btn">Back</button></> : ""}
              <span>
                <button
                  className="export-btn"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleMenuIconClick}
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
                {finalData?.result?.length > 0 && (
                  <Menu
                    id="basic-menu"
                    anchorEl={showMenu}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    <MenuItem onClick={exportToExcel}>Export To Excel</MenuItem>
                    <MenuItem onClick={handleNewFormClick}>Rate Input Form</MenuItem>
                    <MenuItem onClick={handleRecalculate}>Recalculate for latest prebuild</MenuItem>
                    <MenuItem onClick={handleQuotegenerate}>Generate Quote</MenuItem>
                  </Menu>
                )}
              </span>
            </div>

            <div>
              <label htmlFor="sumSalesPrice">Sales Price Ex GST:
                <input type="text" id="sumSalesPrice" value={currencyFormatForString(finalData.totalSalesPrice)} disabled />
                <button onClick={handleViewBreakup} className="view-breakup-btn">View Breakup</button>
              </label>
            </div>
          </div>
        )}

        {finalData?.result?.length > 0 ? (
          <>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={finalData.result}
              rowSelection="single"
              getRowStyle={getRowStyle}
              overlayNoRowsTemplate="<span></span>"
            />
            <div style={{ marginRight: '30px', marginTop: '10px', display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <span></span>
              </div>
              <div>
                <span style={{ border: '1px solid gray', padding: '5px', margin: "0px 5px 0px 15px", borderRadius: '5px' }}>{getRangeText()}</span>
                <label>Page Size:</label>
                <select value={pageSize} onChange={handleChangePageSize}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <button className="Update-row" onClick={() => goToPage(1)} disabled={currentPage === 1}>First</button>
                <button className="Update-row" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <span style={{ border: '1px solid gray', padding: '5px', margin: "0px 5px 0px 15px", borderRadius: '5px' }}>{`Page ${currentPage} of ${totalNumberOfPages}`}</span>
                <button className="Update-row" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalNumberOfPages}>Next</button>
                <button className="Update-row" onClick={() => goToPage(totalNumberOfPages)} disabled={currentPage === totalNumberOfPages}>Last</button>
              </div>
            </div>
          </>) : (
          <div>
          </div>
        )}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <form className="modal-form">
                <span className="close" onClick={onCloseProjectClick}>&times;</span>
                <div className="model-form-content-style">
                  <h2 className="heading">Sales Price Ex GST </h2>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                    <h3 style={{ cursor: 'pointer', backgroundColor: isActive("Package") ? '#3B3535' : '#C0C0C0', color: isActive("Package") ? '#FFFFFF' : '#000000', padding: '15px 30px', borderRadius: '5px' }} onClick={() => handleOptionClick("Package")}>Package</h3>
                    <h3 style={{ cursor: 'pointer', backgroundColor: isActive("Cost Centre") ? '#3B3535' : '#C0C0C0', color: isActive("Cost Centre") ? '#FFFFFF' : '#000000', padding: '15px 30px', borderRadius: '5px' }} onClick={() => handleOptionClick("Cost Centre")}>Cost Centre</h3>
                  </div>
                  <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    {renderModalContent()}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {showNewForm && (
          <div className="overlay">
            <CalculationForm show={showNewForm} onClose={handleCloseForm} handleSubmitNewRateInputForm={handleSubmitNewRateInputForm} isCalculationAvailable={true} />
          </div>
        )}
      </div >
    </>
  );
}

export default Calculationpage;
import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { deleteoneoffitem, updateOneoffitem } from "../../../utils/services/oneoffitemService";
import { currencyFormatForNumber } from '../../../utils/formatter';

function OneoffDetailsForm({ show, onClose, oneoffData, oneoffList, setoneoffList }) {
    const [formData, setFormData] = useState({ CostCentre1: "", CostCentre2: "", SubGroup1: "", SubGroup2: "", SubGroup3: "", CommonName: "", PB: "", UOM: "", Labour: "", Hours: "", Rate_Material: "", Rate_Wastage: "", Rate_Sundries: "", Rate_Delivery: "", Rate_MarkUp: "" });
    const token = sessionStorage.getItem('token')
    const [loading, setLoading] = useState(false);
    let numericalField = ["Labour", "Rate_Material", "Hours", "Rate_Wastage", "Rate_Sundries", "Rate_Delivery", "Rate_MarkUp", "simProPriceUpload"]


    // setting oneoffData details in a state
    useEffect(() => {
        if (oneoffData) {
            setFormData({
                CostCentre1: oneoffData.CostCentre1 || "",
                CostCentre2: oneoffData.CostCentre2 || "",
                SubGroup1: oneoffData.SubGroup1 || "",
                SubGroup2: oneoffData.SubGroup2 || "",
                SubGroup3: oneoffData.SubGroup3 || "",
                CommonName: oneoffData.CommonName || "",
                PB: oneoffData.PB || "",
                UOM: oneoffData.UOM || "",
                Labour: oneoffData.Labour || "",
                Hours: oneoffData.Hours || "",
                Rate_Material: oneoffData.Rate_Material || "",
                Rate_Wastage: oneoffData.Rate_Wastage || "",
                Rate_Sundries: oneoffData.Rate_Sundries || "",
                Rate_Delivery: oneoffData.Rate_Delivery || "",
                Rate_MarkUp: oneoffData.Rate_MarkUp || "",
            });
        }
    }, [oneoffData]);

    // Handle input chamge
    const handleInputChange = (e) => {
        let { name, value } = e.target;
        let percentageValues = ["Rate_Wastage", "Rate_MarkUp"];
        let currencyValues = ["simProPriceUpload", "Rate_Sundries", "Rate_Delivery", "Labour", "Rate_Material"]
        if (numericalField.includes(name)) {
            if (e.nativeEvent.inputType === "deleteContentBackward") {
                if (percentageValues.includes(name)) {
                    const updatedValue = value.slice(0, -1);
                    let finalValue = updatedValue;
                    finalValue = updatedValue.endsWith("%") ? updatedValue : updatedValue + "%";
                    if (!updatedValue) {
                        finalValue = ""
                    }
                    console.log('filenale', finalValue);
                    setFormData({ ...formData, [name]: finalValue });
                    return;
                }
            }


            const cleanedValue = value.replace(/[$%,]/g, '');
            if (isNaN(cleanedValue)) {
                return;
            }
            let updatedValue = cleanedValue;

            if (percentageValues.includes(name)) {
                if (cleanedValue && !cleanedValue.includes('%')) {
                    updatedValue = cleanedValue + '%';
                } else {
                    updatedValue = 0 + "%";
                }
            } else if (currencyValues.includes(name)) {
                if (cleanedValue && !cleanedValue.includes('$')) {
                    updatedValue = currencyFormatForNumber(cleanedValue)
                }
            } else {
                updatedValue = cleanedValue
            }
            setFormData({ ...formData, [name]: updatedValue });
        } else {
            setFormData({ ...formData, [name]: value, });
        }
    };

    // Handle to update oneoff
    const handleUpdate = async () => {
        try {
            setLoading(true)
            const _id = oneoffData?._id;
            let formattedFormData = { ...formData };
            for (let key in formattedFormData) {
                if (typeof formattedFormData[key] === "string" && (formattedFormData[key].startsWith("$") || formattedFormData[key].endsWith("%"))) {
                    formattedFormData[key] = formattedFormData[key].replace(/[$%,]/g, "");
                }
            }
            
            await updateOneoffitem(token, _id, formattedFormData)
            setLoading(false);
            Swal.fire({
                title: "Updated!",
                text: "Row has been updated.",
                icon: "success",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
            onClose();
        } catch (error) {
            console.error("Error updating row:", error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: "An error occurred while updating the row.",
                icon: "error",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }

    // Handle to delete oneoff
    const handleDelete = async () => {
        try {
            setLoading(true)
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete the selected rows. This action cannot be undone.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#100c08",
                cancelButtonColor: "#D1D1D1",
                confirmButtonText: "Yes, delete them!",
                cancelButtonText: "No, cancel",
                customClass: {
                    container: 'swal-container',
                }
            });
            if (result.isConfirmed) {
                const _id = oneoffData?._id;
                await deleteoneoffitem(token, _id)
                setLoading(false);
                Swal.fire({
                    title: "Deleted!",
                    text: "Selected rows have been deleted.",
                    icon: "success",
                    confirmButtonColor: "#100c08",
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            onClose();
        } catch (error) {
            console.error("Error deleting rows:", error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: "An error occurred while deleting rows.",
                icon: "error",
                confirmButtonColor: "#100c08",
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }

    return (
        <div style={{ zIndex: 13 }}>
            <div className="modal-contents">
                <form className="create-form">
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#3b3535', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
                        <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>Update Oneoff</h2>
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
                        </IconButton>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '10px 0' }}>
                        <Button onClick={handleDelete} variant="outlined" sx={{ color: '#808080', border: '1px solid #3b3535' }}>Delete</Button>
                        <Button onClick={handleUpdate} variant="contained" color="primary" style={{ background: '#3b3535', color: 'c0c0c0' }}>Update</Button>
                    </div>
                    <div className="form-divs">
                        <div className="subDiv">
                            <label style={{ color: !formData.CostCentre1 && "red" }}>Job Cost Centre Name
                                <input type="text" placeholder="Select or Enter Job Cost Centre" name="CostCentre1" value={formData.CostCentre1} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.SubGroup2 && "red" }}>SubGroup2
                                <input type="text" placeholder="Select or Enter SubGroup 2" name="SubGroup2" value={formData.SubGroup2} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.CostCentre2 && "red" }}>Cost Centre
                                <input type="text" placeholder="Select or Enter Cost Centre" name="CostCentre2" value={formData.CostCentre2} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.CommonName && "red" }}>Prebuild(list)
                                <input type="text" placeholder="Select or Enter Common Name" name="CommonName" value={formData.CommonName} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.SubGroup1 && "red" }}>SubGroup1
                                <input type="text" placeholder="Select or Enter SubGroup 1" name="SubGroup1" value={formData.SubGroup1} onChange={handleInputChange} />
                            </label>
                            <label>PB No.
                                <input type="text" name="PB" placeholder="PB No" value={formData.PB} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label>U.O.M
                                <input type="text" name="UOM" placeholder="Enter U.O.M" value={formData.UOM} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.Labour && "red" }}>Rate Labour
                                <input type="text" name="Labour" placeholder="Enter Rate Labour" value={formData.Labour} onChange={handleInputChange} />
                            </label>
                            <label style={{ color: !formData.Hours && "red" }}>Hours
                                <input type="text" name="Hours" placeholder="Enter Hours" value={formData.Hours} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label style={{ color: !formData.Rate_Material && "red" }}>Rate Material
                                <input type="text" name="Rate_Material" placeholder="Enter Rate Material" value={formData.Rate_Material} onChange={handleInputChange} />
                            </label>
                            <label>Rate Wastage
                                <input type="text" name="Rate_Wastage" placeholder="Enter Rate Wastage" value={formData.Rate_Wastage} onChange={handleInputChange} />
                            </label>
                        </div>
                        <div className="subDiv">
                            <label>Rate Sundries
                                <input type="text" name="Rate_Sundries" placeholder="Enter Rate Sundries" value={formData.Rate_Sundries} onChange={handleInputChange} />
                            </label>
                            <label>Rate Delivery
                                <input type="text" name="Rate_Delivery" placeholder="Enter Rate Delivery" value={formData.Rate_Delivery} onChange={handleInputChange} />
                            </label>
                            <label>Rate MarkUp
                                <input type="text" name="Rate_MarkUp" placeholder="Enter Rate MarkUp" value={formData.Rate_MarkUp} onChange={handleInputChange} />
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            {loading && <LoadingSpinner />}
        </div>
    );
}

export default OneoffDetailsForm;

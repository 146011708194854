import React, { useState } from "react";
import "./CreateUser.css";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { useUserContext } from "../../../context/user.context";
import { createUser } from "../../../utils/services/authService";
import { ROLE } from "../../../utils/constant";

const CreateUser = ({ show, onClose, userList, setUserList }) => {
  const [formData, setFormData] = useState({ fullname: "", email: "", password: "", mobile: "", company: "", roles: "", });
  const [loading, setLoading] = useState(false);
  const { userState } = useUserContext()

  // Handle Input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, });
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.fullname || !formData.email || !formData.password || !formData.mobile || !formData.company || !formData.roles) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required details before submitting",
        confirmButtonColor: "#100c08",
      });
      setLoading(false);
      return;
    }
    try {
      const userResponse = await createUser(formData, userState);
      if (userResponse.status) {
        setUserList([...userList, userResponse.user])
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Created ${formData.fullname} Successfully`,
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Something went wrong while creating a user.",
          confirmButtonColor: "#100c08",
        });
      }
    } catch (err) {
      console.log("Error", err);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error submitting data. Please try again.",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div style={{ zIndex: 13 }}>
      <div className="modal-contents2">
        <form className="create-user-form" onSubmit={handleSubmit}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#3b3535', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', textAlign: 'center', borderRadius: '4px' }}>
            <h2 id="modal-modal-title" style={{ margin: 0, color: '#c0c0c0', marginLeft: '15px' }}>Create New User</h2>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: '32px', color: 'white' }} />
            </IconButton>
          </Box>
          <TextField required label="Full Name" name="fullname" value={formData.fullname} onChange={handleChange} fullWidth margin="normal" size='small' />
          <TextField required label="Email" name="email" type="email" value={formData.email} onChange={handleChange} fullWidth margin="normal" size='small' />
          <TextField required label="Password" name="password" type="password" value={formData.password} onChange={handleChange} fullWidth margin="normal" size='small' />
          <TextField required label="Mobile" name="mobile" value={formData.mobile} onChange={handleChange} fullWidth margin="normal" size='small' />
          <FormControl required fullWidth margin="normal" size='small'>
            <InputLabel id="company-label">Company</InputLabel>
            <Select labelId="company-label" id="company" label="Company" value={formData.company} onChange={(e) => setFormData({ ...formData, company: e.target.value })}>
              <MenuItem value="AMC">All Melbourne Constructions</MenuItem>
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="normal" size='small'>
            <InputLabel id="roles-label">Roles</InputLabel>
            <Select labelId="roles-label" id="roles" label="Roles" value={formData.roles} onChange={(e) => setFormData({ ...formData, roles: e.target.value })}>
              <MenuItem value={ROLE.SUPER_ADMIN}>Super Admin</MenuItem>
              <MenuItem value={ROLE.ADMIN}>Admin</MenuItem>
              <MenuItem value={ROLE.USER}>User</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
            <Button variant="outlined" sx={{ color: '#808080', border: '1px solid #3b3535' }} onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit" disabled={loading} style={{ background: '#3b3535', color: 'c0c0c0' }}>Create User</Button>
          </div>
        </form>
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};

export default CreateUser;
